import { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Navbar() {
  const navRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState("home");
  const [pathPage, setPathPage] = useState("");
  useEffect(() => {
    const pathurl = window.location.pathname;
    const path = window.location.hash;
    // console.log("path", !path);
    // console.log(pathurl);
    if (path) {
      const pathPage = path.split("#")[1];
      // console.log("pathPage", pathPage);
      setPathPage(pathPage);
      setSelectedPage(pathPage);
      const section = document.getElementById(pathPage);
      // const offset = 50;
      // const bodyRect = document.body.getBoundingClientRect().top;
      // const sectionRect = section.getBoundingClientRect().top;
      // const sectionPosition = sectionRect - bodyRect;
      // const offsetPosition = sectionPosition - offset;

      window.scrollTo({
        top: section.offsetTop - 50,
        behavior: "smooth",
      });
    } else if (pathurl.split("/")[1] == "privacypolicy") {
      setPathPage("privacypolicy");
      setSelectedPage("privacypolicy");
    }

    // location.pathname.split("/")[1];
  }, [pathPage]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const handlePageSelect = (page) => {
    setSelectedPage(page);
    const section = document.getElementById(page);
    if (page !== "privacypolicy" && page !== "login" && page !== "home") {
      const offset = 50;
      const bodyRect = document.body.getBoundingClientRect().top;
      const sectionRect = section.getBoundingClientRect().top;
      const sectionPosition = sectionRect - bodyRect;
      const offsetPosition = sectionPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    if (page == "home") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleNavLinkClick = (e, page) => {
    e.preventDefault();

    const isPrivacyPolicy =
      window.location.pathname.split("/")[1] === "privacypolicy";
    const isLogin = window.location.pathname.split("/")[1] === "login";
    const isNavigatingToPrivacyPolicy = page.id === "privacypolicy";
    if (isPrivacyPolicy && page.id == "home") {
      console.log("adshioajiosjdsoijadsiosdiaiodasjosad");
      history.push("/");
    }
    if (page.id == "home") {
      history.push("/");
      console.log("here home");
      handlePageSelect(page.id);
    }
    if (page.id == "login") {
      console.log("here login");
      // handlePageSelect(page.id);
      history.push("/login");
    }
    if (isNavigatingToPrivacyPolicy) {
      history.push("/privacypolicy");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      if (isPrivacyPolicy && page.id !== "login" && page.id !== "home") {
        console.log("efsiji");
        setSelectedPage(page.id);
        history.push("/");
        setTimeout(() => {
          setSelectedPage(page.id);
          handlePageSelect(page.id);
          history.push(`#${page.id}`);
        }, 200);
      } else if (isLogin && page.id !== "privacypolicy" && page.id !== "home") {
        setSelectedPage(page.id);
        history.push("/");
        setTimeout(() => {
          setSelectedPage(page.id);
          handlePageSelect(page.id);
          history.push(`#${page.id}`);
        }, 200);
      } else {
        if (page.id !== "login" && page.id !== "home") {
          console.log("dsaaddsads");
          history.push(`#${page.id}`);
          handlePageSelect(page.id);
          showNavbar();
        }
      }
    }
  };

  return (
    <div className="header-div" id="header_id">
      <header className="header">
        <div className="mini-logo-header">
          <a href="/">
            <LazyLoadImage
              // height={100}
              effect="blur"
              alt="logo"
              // className="iconheader"
              // height={image.height}
              src="/images/fulllogo.png"
            />
            {/* <LazyLoadImage
            // height={100}
            effect="blur"
            alt="logo"
            className="nameheader"
            // height={image.height}
            src="/images/name.png"
          /> */}
            {/* <img src="/images/icon.png" alt="Logo" /> */}
            {/* </LazyLoad> */}
          </a>
          {/* <div className="logo-name">Tripoli Fresh Market</div> */}
        </div>
        <nav ref={navRef} className="nav" id="navbar">
          {/* {location.pathname.split("/")[1] !== "privacypolicy" ? (
          <> */}
          {[
            { title: "Home", id: "home" },
            { title: "About Us", id: "aboutus" },
            { title: "Quality Assured", id: "qualityassured" },
            { title: "Ordering", id: "ordering" },
            { title: "Delivery", id: "delivery" },
            { title: "Contact Us", id: "contactus" },
            { title: "Privacy Policy", id: "privacypolicy" },
            { title: "Login", id: "login" },
          ].map((page) => (
            <a
              key={page.id}
              // href={`/${page.id}`}
              onClick={(e) => handleNavLinkClick(e, page)}
              className={selectedPage === page.id ? "" : ""}
            >
              {page.title}
            </a>
          ))}

          {/* </>
          
        ) : (
          <>
            <a
              key="home"
              href={`/`}
              className={selectedPage === "home" ? "selected" : ""}
            >
              Home Page
            </a>
          </>
        )} */}
          <div className="nav-logo-mobile mini-logo-header">
            <a href="/">
              <LazyLoadImage
                // height={100}
                effect="blur"
                alt="logo"
                // className="iconheader"
                // height={image.height}
                src="/images/fulllogo.png"
              />
            </a>
          </div>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
}

export default Navbar;
