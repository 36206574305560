import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AccountContext } from "./contexts/accountContext";
import Cookies from "js-cookie";
// import Cookies from "js-cookie";

// import { useSelector } from "react-redux";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(AccountContext);
  // console.log("here state", state);
  const token = Cookies.get("token");
  // const tokenn = useSelector((state) => state.adminToken);
  // console.log(tokenn, "sdjfdiudsfhdfiu");
  // console.log("state.loged", state.loged);
  // console.log("state.adminToken", state.adminToken);
  return (
    <Route
      {...rest}
      render={(props) => {
        if ((state.adminToken && state.loged) || token) {
          return <Component {...props} />;
        } else {
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
