import React, { useState } from "react";
import CategoriesTable from "./CategoriesTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MainCategories = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryEdit = (editedData) => {
    setSelectedCategory(editedData);
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Categories</h2>
        <div>
          <Link to="/addcategory" className="btn btnhoverdark">
            Create new
          </Link>
        </div>
      </div>
      {/* <div className="card mb-4 shadow-sm"> */}
      <CategoriesTable onCategoryEdit={handleCategoryEdit} />
      {/* </div> */}
    </section>
  );
};

export default MainCategories;
