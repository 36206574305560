import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
// import CustomDots from "../CustomDots";

const images = [
  {
    id: 1,
    name: "Item One",
    image_url: "/images/Banner7.png",
  },
  {
    id: 2,
    name: "Item two",
    image_url: "/images/Banner9.png",
  },
];
export default function QualityAssured() {
  const sliderRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState({});

  useEffect(() => {
    if (images && images.length > 0) {
      const newImageLoadedState = {};
      images?.forEach((image) => {
        console.log("image.id", image.id);
        newImageLoadedState[image.id] = false;
      });
      setImageLoaded(newImageLoadedState);
    }
  }, [images]);

  const handleImageLoad = (itemId) => {
    console.log("itemId", itemId);
    setImageLoaded((prevState) => ({
      ...prevState,
      [itemId]: true,
    }));
    console.log("imageLoaded", imageLoaded);
  };
  return (
    <section className="tripoli-section-quality bgcolor1" id="qualityassured">
      <div className="tripoli-content-quality">
        <h2 className="tripoli-title">Quality Assured</h2>
        <p className="tripoli-text">
          At Tripoli Fresh Market, quality stands at the heart of everything we
          do. We meticulously source the freshest fruits and vegetables from
          trusted growers, ensuring each item is handled with utmost care from
          farm to shelf. Our rigorous quality control processes are designed to
          exceed industry standards, guaranteeing that only the finest produce
          reaches your business.
        </p>
        <p className="tripoli-text">
          We pride ourselves on consistency, delivering superior products that
          enhance your offerings and delight your customers every time. Trust
          Tripoli Fresh Market to uphold the highest standards of freshness and
          taste, backed by our commitment to excellence. Experience the
          difference with Tripoli Fresh Market – where quality and reliability
          meet to elevate your business success.
        </p>
      </div>
      <div className="section-quality-image carousel-div-autoplay">
        <Slider
          ref={sliderRef}
          className={`variable-width`}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={3500}
          speed={1000}
          infinite={true}
          // dots={true}
          fade={true}
          // customPaging={(i) => (
          //   <CustomDots
          //     active={
          //       sliderRef.current &&
          //       sliderRef.current.innerSlider.state.currentSlide === i
          //     }
          //     onClick={() => sliderRef.current.slickGoTo(i)}
          //   />
          // )}
          // appendDots={(dots) => (
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     {dots}
          //   </div>
          // )}
        >
          {images.map((image) => (
            <div key={image.id}>
              {!imageLoaded[image.id] && <div className="bgimage" />}
              <img
                // key={image.id}
                width="100%"
                height="100%"
                // effect="blur"
                alt={image.id}
                src={image.image_url}
                className="bradius"
                onLoad={() => handleImageLoad(image.id)}
              />
            </div>
          ))}
        </Slider>

        {/* <div class="overlay"></div> */}
      </div>

      {/* <div className="tripoli-image-container">
        <LazyLoadImage
          effect="blur"
          alt="Tripoli Fresh Market"
          src="/images/qualityAssured.png"
          className="tripoli-image"
        />
      </div> */}
    </section>
  );
}
