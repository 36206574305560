/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loading from "../LoadingError/Loading";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Cookies from "js-cookie";
import buildLink from "../../urls";
import axios from "axios";
import { handleError, handleFilterChange } from "../../services/functions";
import Snackbar from "../LoadingError/snackbar";

const OrderMain = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState("");
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(queryParams.get("limit") || 10);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [orderId, setOrderId] = useState();
  const [customerName, setCustomerName] = useState();
  const [dateAdded, setDateAdded] = useState();
  const [dateModified, setDateModified] = useState();
  // const [address, setAddress] = useState();
  // const [phoneNumber, setPhoneNumber] = useState();
  const [editableHeader, setEditableHeader] = useState(null);
  const [mssg, setMssg] = useState(false);
  const initialPageNumber = queryParams.get("page")
    ? Number(queryParams.get("page")) - 1
    : 0;
  // console.log(address);
  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    // queryParams.set("page", 1);
    // const newUrl = `${location.pathname}?${queryParams.toString()}`;
    // history.replace(decodeURIComponent(newUrl));
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const statusQueryParam = queryParams.get("filter_order_status_id");
      const limitQueryParam = queryParams.get("limit");
      const pageQueryParam = queryParams.get("page");
      const orderIdQueryParam = queryParams.get("filter_order_id");
      const customerNameQueryParam = queryParams.get("filter_fullname");
      const dateAddedQueryParam = queryParams.get("filter_date_added");
      const dateModifiedQueryParam = queryParams.get("filter_date_modified");
      const phoneNumberQueryParam = queryParams.get("filter_telephone");

      let apiUrl = buildLink("orders");

      const queryParamsArray = [];

      if (statusQueryParam)
        queryParamsArray.push(`filter_order_status_id=${statusQueryParam}`);
      if (limitQueryParam)
        queryParamsArray.push(`limit=${limitQueryParam || 10}`);
      if (pageQueryParam) queryParamsArray.push(`page=${pageQueryParam}`);
      if (orderIdQueryParam)
        queryParamsArray.push(`filter_order_id=${orderIdQueryParam}`);
      if (customerNameQueryParam)
        queryParamsArray.push(`filter_fullname=${customerNameQueryParam}`);
      if (dateAddedQueryParam)
        queryParamsArray.push(`filter_date_added=${dateAddedQueryParam}`);
      if (dateModifiedQueryParam)
        queryParamsArray.push(`filter_date_modified=${dateModifiedQueryParam}`);
      if (phoneNumberQueryParam)
        queryParamsArray.push(`filter_telephone=${phoneNumberQueryParam}`);
      // if (!limitQueryParam && pageQueryParam) {
      // apiUrl += `?limit=10&${queryParamsArray.join("&")}`;
      // } else

      apiUrl += `?${
        limitQueryParam == null && pageQueryParam
          ? "limit=10&"
          : `${limitQueryParam == null ? "limit=10" : ""}`
      }${queryParamsArray.join("&")}`;

      // if (pageQueryParam) {
      const response = await axios.get(apiUrl, {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      if (response.status === 200) {
        // if (response.data.data.pages === 1) {
        //   setPageCount(1);
        // } else if (response.data.data.pages === 2) {
        //   setPageCount(2);
        // }
        setPageCount(response.data.data.pages);
        // console.log("page count", pageCount);
        // console.log("response.data.data.pages", response.data.data.pages);
        // if (typeof pageCount == "undefined") {
        setLoading(true);
        setPageCount(response.data.data.pages);
        // console.log("here");
        // window.location.reload();
        setPageCount(response.data.data.pages);
        // } else {
        // setPageCount(response.data.data.pages);
        setOrders(response.data.data.orders);
        // }
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
      // }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (selected) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", selected.selected + 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  useEffect(() => {
    fetchOrders();
  }, [
    queryParams.get("page"),
    queryParams.get("filter_order_status_id"),
    queryParams.get("limit"),
    queryParams.get("filter_order_id"),
    queryParams.get("filter_fullname"),
    queryParams.get("filter_date_added"),
    queryParams.get("filter_date_modified"),
    queryParams.get("filter_telephone"),
  ]);

  const fetchStatuses = async () => {
    try {
      const response = await axios.get(buildLink("order_statuses"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        setStatuses(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar
        );
      }
    } catch (error) {
      handleError(error, setSnackbarMessage, setSnackbarColor, setShowSnackbar);
    }
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  const filterOrdersByStatus = (e) => {
    const status = e.target.value;
    // console.log("status", status);
    setStatus(status);
    handleFilterChange(
      queryParams,
      location,
      history,
      "order_status_id",
      status
    );
  };

  const filterOrderBylimit = (limit) => {
    // const limit = e.target.value;
    setLimit(limit);
    handleFilterChange(queryParams, location, history, "limit", limit, true);
  };

  const handleHeaderClick = (fieldName) => {
    setEditableHeader(fieldName);
  };

  const handleKeyPress = (e, fieldName) => {
    if (e.key === "Enter") {
      handleFilterChange(
        queryParams,
        location,
        history,
        fieldName,
        e.target.value
      );
    }
  };

  const handleOrderId = (e) => {
    // console.log("here id");
    handleKeyPress(e, "order_id");
  };

  const handleCustomerName = (e) => {
    handleKeyPress(e, "fullname");
  };

  const handleDateAdded = (e) => {
    handleKeyPress(e, "date_added");
  };

  const handleDateModified = (e) => {
    handleKeyPress(e, "date_modified");
  };

  // const handlePhoneNumber = (e) => {
  //   handleKeyPress(e, "telephone");
  // };
  const handleOutsideClick = (e) => {
    if (
      editableHeader &&
      !e.target.closest(".table th") &&
      e.target.tagName !== "INPUT"
    ) {
      setEditableHeader(null);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [editableHeader]);

  const clearFilters = () => {
    // Clear all filter values and update the URL
    setOrderId("");
    setCustomerName("");
    setDateAdded("");
    setDateModified("");
    // setAddress("");
    // setPhoneNumber("");
    setStatus("");
    setLimit("10"); // Reset limit to default
    queryParams.delete("limit");
    queryParams.delete("filter_order_id");
    queryParams.delete("filter_fullname");
    queryParams.delete("filter_date_added");
    queryParams.delete("filter_date_modified");
    queryParams.delete("filter_address");
    queryParams.delete("filter_telephone");
    queryParams.delete("filter_order_status_id");
    queryParams.set("page", 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  const handleClick = (orderId) => {
    history.push(`/order?order_id=${orderId}`);
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Orders</h2>
        <div className="clear-filter-btn">
          <button onClick={clearFilters}>Clear Filters</button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            <>
              <div className="card mb-4 shadow-sm">
                <header
                  className="card-header d-flex justify-content-between align-items-center p-"
                  // style={{ backgroundColor: "#4fa607" }}
                >
                  <div className="flexin align-items-center">
                    <div style={{ minWidth: "13rem", width: "auto" }}>
                      <select
                        className="form-select cursor-pointer"
                        onChange={filterOrdersByStatus}
                        value={status}
                      >
                        <option
                          value={""}
                          key={`status_All`}
                          className="cursor-pointer"
                        >
                          All Statuses
                        </option>
                        {statuses?.map((status) => (
                          <option
                            value={status.order_status_id}
                            className="cursor-pointer"
                            key={`status_${status?.order_status_id}`}
                          >
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <option
                      className="order-btn textcolor"
                      value={1}
                      key={`status_pending`}
                      onClick={filterOrdersByStatus}
                    >
                      Pending
                    </option>
                    <option
                      className="order-btn textcolor"
                      value={6}
                      key={`status_confirmed`}
                      onClick={filterOrdersByStatus}
                    >
                      Confirmed
                    </option>
                  </div>
                  {/* <div
                    className="ms-auto cursor-pointer"
                    style={{ width: "8rem" }}
                  >
                    <select
                      className="form-select cursor-pointer"
                      onChange={filterOrderBylimit}
                      value={limit}
                    >
                      <option value={"10"}>Show 10</option>
                      <option value={"20"}>Show 20</option>
                      <option value={"30"}>Show 30</option>
                    </select>
                  </div> */}
                </header>

                <div className="card-body" style={{ minHeight: "100px" }}>
                  <div className="table-responsive">
                    <>
                      {orders?.length > 0 ? (
                        <table className="table tabletd">
                          <thead className="cursorpointer">
                            <tr>
                              <th
                                scope="col"
                                onClick={() => handleHeaderClick("ID")}
                                style={{ width: "4rem" }}
                              >
                                {editableHeader === "ID" ? (
                                  <input
                                    type="text"
                                    value={orderId}
                                    placeholder="ID"
                                    onChange={(e) => setOrderId(e.target.value)}
                                    onKeyPress={(e) => handleOrderId(e)}
                                    style={{ width: "4rem" }}
                                  />
                                ) : (
                                  "ID"
                                )}
                              </th>
                              <th
                                scope="col"
                                onClick={() =>
                                  handleHeaderClick("Customer name")
                                }
                                style={{ width: "18rem" }}
                              >
                                {editableHeader === "Customer name" ? (
                                  <input
                                    type="text"
                                    value={customerName}
                                    placeholder="John Doe"
                                    onChange={(e) =>
                                      setCustomerName(e.target.value)
                                    }
                                    onKeyPress={(e) => handleCustomerName(e)}
                                    style={{ width: "10rem" }}
                                  />
                                ) : (
                                  "Customer name"
                                )}
                              </th>
                              <th
                                className="cursordefault"
                                style={{ width: "9rem" }}
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                onClick={() => handleHeaderClick("Date Added")}
                                style={{ width: "6.8rem" }}
                              >
                                {editableHeader === "Date Added" ? (
                                  <input
                                    type="date"
                                    value={dateAdded}
                                    onChange={(e) =>
                                      setDateAdded(e.target.value)
                                    }
                                    onKeyPress={(e) => handleDateAdded(e)}
                                  />
                                ) : (
                                  "Date Added"
                                )}
                              </th>
                              <th
                                scope="col"
                                onClick={() =>
                                  handleHeaderClick("Date Modified")
                                }
                                style={{ width: "6.8rem" }}
                              >
                                {editableHeader === "Date Modified" ? (
                                  <input
                                    type="date"
                                    value={dateModified}
                                    onChange={(e) =>
                                      setDateModified(e.target.value)
                                    }
                                    onKeyPress={(e) => handleDateModified(e)}
                                  />
                                ) : (
                                  "Date Modified"
                                )}
                              </th>
                              <th
                                className="cursordefault"
                                style={{ width: "9rem" }}
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" cursorpointer">
                            {orders?.map((order) => (
                              <tr
                                key={order?.order_id}
                                className="order-row"
                                onClick={() => handleClick(order?.order_id)}
                              >
                                <td className="text-center">
                                  <div className="flex ">
                                    <div style={{ width: "4rem" }}>
                                      {order?.order_id
                                        .toString()
                                        .padStart(4, "0")}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="flex ">
                                    <div
                                      className="text_overflow"
                                      style={{ width: "18rem" }}
                                    >
                                      {order?.fullname}
                                    </div>
                                  </div>
                                </td>

                                <td className="text-center">
                                  <div className="flex ">
                                    <div style={{ width: "9rem" }}>
                                      {order?.net_total} $
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="flex ">
                                    <div style={{ width: "6.8rem" }}>
                                      {order?.date_added.split(" ")[0]}
                                      <br />
                                      {order?.date_added.split(" ")[1]}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="flex ">
                                    <div style={{ width: "6.8rem" }}>
                                      {order?.date_modified.split(" ")[0]}{" "}
                                      <br />
                                      {order?.date_modified.split(" ")[1]}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="flex ">
                                    <div style={{ width: "9rem" }}>
                                      {order?.description}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="nofound">No Orders Found</div>
                      )}
                    </>
                    {orders?.length > 0 && (
                      <div className="flex-container">
                        <div className="pagination-container">
                          <ReactPaginate
                            pageCount={pageCount}
                            forcePage={initialPageNumber}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            breakClassName={`...`}
                            activeClassName="active-page"
                            previousLabel="< "
                            nextLabel=" >"
                            pageRangeDisplayed={3}
                            hrefAllControls={true}
                          />
                        </div>
                        <div className="">
                          {/* <div>Show</div> */}
                          <div className="limit-container">
                            <div
                              className={`box-limit ${
                                limit == 10 ? "selected" : ""
                              }`}
                              onClick={() => filterOrderBylimit("10")}
                            >
                              10
                            </div>
                            <div
                              className={`box-limit ${
                                limit == 20 ? "selected" : ""
                              }`}
                              onClick={() => filterOrderBylimit("20")}
                            >
                              20
                            </div>
                            <div
                              className={`box-limit ${
                                limit == 30 ? "selected" : ""
                              }`}
                              onClick={() => filterOrderBylimit("30")}
                            >
                              30
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {showSnackbar && (
                    <Snackbar message={snackbarMessage} style={snackbarColor} />
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default OrderMain;
