// Snackbar.js
import React from 'react';

const Snackbar = ({ message, style }) => {
    // console.log("bgColor",bgColor);
  return (
    <div
    //   className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red text-white py-2 px-4 rounded shadow-lg`}
    className='snackbar'
      style={style}
    >
      {message}
    </div>
  );
};

export default Snackbar;
