import AutoplayCarousel from "../carousel/autoplay";

export default function HomeSection() {
  return (
    <section className="" id="home">
      <AutoplayCarousel />
      {/* <div className="promo-container">
      <div className="grid-container">
        <div className="fruit-column">
          <img
            alt="sidefruits"
            className="fruit-img"
            src="/images/sidefruits.png"
            width="150"
            height="150"
          />
         
        </div>
        <div className="text-container">
          <h2>Fresh Veggies Delivered to your door.</h2>
          <p>Get the best organic produce delivered straight to your home.</p>
        </div>
      </div>
    </div> */}
      {/* <div className="bg-overlay">
        <img src="/images/home3.png" alt="home" />
        <div className="container text-center">
          <h1 className="hero-title">Fresh Veggies Delivered to Your Door</h1>
          <p className="hero-subtitle">
            Get the best organic produce delivered straight to your home.
          </p>
          <a className="order-button" href="/">
            Get App Now
          </a>
        </div>
      </div> */}
    </section>
  );
}
