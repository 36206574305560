export default function AccountReducer(state, action) {
  switch (action.type) {
    case "setLoged":
      return { ...state, loged: action.payload };
    case "setLoading":
      return { ...state, loading: action.payload };
    case "setFirstname":
      return { ...state, firstname: action.payload };
    case "setUserId":
      return { ...state, userId: action.payload };
    case "setAdmin":
      return { ...state, admin: action.payload };
    case "setAdminToken":
      return { ...state, adminToken: action.payload };
    case "setAdminLoading":
      return { ...state, adminLoading: action.payload };
    default:
      return state;
  }
}
export const initialState = {
  loged: false,
  loading: false,
  username: "",
  userId: "",
  admin: false,
  adminToken: "",
  adminLoading: true,
};
