import React from "react";
import "./invoice.css";

const Invoice = ({ order }) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="invoice">
      <div className="invoice-content">
        <div className="invoice-header">
          <h1>Invoice #{order.order_id}</h1>
          {order.date_added.split(" ")[0]}
        </div>
        <div className="invoice-details">
          <p>
            {order.fullname} | {order.telephone}
          </p>
          <p>{order.address}</p>
          <p>{order.comment}</p>
          <p className="underline"></p>
          <table className="invoice-table">
            <thead className="underline">
              <tr>
                <th>Description</th>
                <th className="price_td">Price</th>
              </tr>
            </thead>
            <tbody>
              {order.products.map((item, index) => (
                <tr key={`product_${index}`}>
                  <td>
                    {item.name} <br />
                    <span className="unit-price">
                      {item.new_quantity != null
                        ? item.new_quantity
                        : item.quantity}
                      {item.unit} x{" "}
                      {item.new_price != null
                        ? item.net_new_price
                        : item.net_price}
                    </span>
                  </td>
                  <td className="price">{item.net_total}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <p className="underline"></p>
          <div class="row">
            <div class="column">
              <div>Delivery fees</div>
            </div>
            <div class="column">
              <p>{order.net_delivery_fees}</p>
            </div>
          </div>

          <div class="row">
            <div class="column">
              <div>Total USD</div>
            </div>
            <div class="column">
              <p>{order.net_total_USD}</p>
            </div>
          </div>

          <div class="row">
            <div class="column">
              <div>Total LBP</div>
            </div>
            <div class="column">
              <p>
                {new Intl.NumberFormat("en-US").format(order?.net_total_LBP) ||
                  "-"}
              </p>
            </div>
          </div>

          <p className="underline"></p>
          <div class="row">
            <div class="column">
              <div>Rate</div>
            </div>
            <div class="column">
              <p>
                {" "}
                {new Intl.NumberFormat("en-US").format(order?.rate) || "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <button onClick={handlePrint}>Print Invoice</button>
    </div>
  );
};

export default Invoice;
