import { useState } from "react";
import Snackbar from "../LoadingError/snackbar";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const res = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (res.ok) {
        setFormData({ name: "", email: "", message: "" });
        setSuccess("Your message has been sent successfully!");
        setShowSnackbar(true);
      } else {
        const data = await res.json();
        setError(data.message || "Something went wrong. Please try again.");
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="mb-4 tripoli-section-contact bgcolor1" id="contactus">
      <div className="tripoli-content-contact">
        <h2 className="tripoli-title">Contact Us</h2>
        {/* <div className="about-grid">
          <div> */}
        <p className="tripoli-text">
          We’re here to help! If you have any questions or need assistance,
          please don’t hesitate to reach out to us.
        </p>
        {/* Contact Information: */}
        <div className="contact-info-item">
          <PhoneIcon />
          <span className="text-gray">
            <a href="tel:+96171144332" className="telephone">
              +961 71 144 332
            </a>
          </span>
        </div>
        <div className="contact-info-item ">
          <a href="mailto:info@tripolifreshmarket.com" className="mail-link">
            <MailIcon />
            <span className="text-gray">info@tripolifreshmarket.com</span>
          </a>
        </div>
        <div className="contact-info-item ">
          <MapPinIcon />
          <span className="text-gray">
            Municipal Fresh Market, Tripoli, Lebanon
          </span>
        </div>
        {/* <a
              href="mailto:info@tripolifreshmarket.com"
              // className="flex items-center hover:text-gray-400"
            >
              <button
                className="send-message-btn"
                // onClick={sendMessage}
              >
                Contact Us
              </button>
            </a>  */}
      </div>{" "}
      <div className="form-container tripoli-text">
        <form
          className="rounded-lg shadow-md p-3  modern-form"
          onSubmit={handleSubmit}
        >
          <div className="p-2">
            <label for="name" className="mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="input-field"
              value={formData.name}
              onChange={handleChange}
              placeholder="John Doe"
              required
            />
          </div>
          <div className="p-2">
            <label for="email" className="mb-2">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="input-field"
              value={formData.email}
              onChange={handleChange}
              placeholder="john@example.com"
              required
            />
          </div>
          <div className="p-2">
            <label for="message" className="mb-2">
              Message
            </label>
            <textarea
              type="text"
              id="message"
              className="input-field"
              value={formData.message}
              onChange={handleChange}
              placeholder="How can we help you?"
              rows={3}
              maxLength={1500}
              style={{ minHeight: "5rem", maxHeight: "8rem" }}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="send-message-btn "
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
          {error && <p className="error mt-2 mb-2 ">{error}</p>}
          {success && <p className="success mt-2 mb-2">{success}</p>}
        </form>
      </div>
      {/* <div className="form-container"></div> */}
      {/* <div className="form-container "> </div> */}
      {/* <div className="form-container ">
            <div className="mb-3">
              Alternatively, you can fill out our contact form and we will get
              back to you as soon as possible.
            </div>
            <form className="rounded-lg shadow-md p-6">
              {/* <div className="mb-4">
                <label htmlFor="name" className="mb-1">
                  Name
                </label>
                <input id="name" placeholder="Enter your name" type="text" />
              </div> */}
      {/* <div className="mb-4">
                <label htmlFor="email" className="mb-1">
                  Email
                </label>
                <input id="email" placeholder="Enter your email" type="email" />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="mb-1">
                  Message
                </label>
                <textarea id="message" placeholder="Enter your message" />
              </div>
              <button type="submit" className="send-message-btn ">
                Send Message
              </button> */}
      {/* </form>
          </div> */}
      {/* </div> */}
      {showSnackbar && (
        <Snackbar
          message="Your message has been sent successfully!"
          style={{ backgroundColor: "#4caf50" }}
        />
      )}
      {/* </div> */}
    </section>
  );
}
function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon mb-1"
    >
      <rect width="20" height="16" x="2" y="4" rx="2" />
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    </svg>
  );
}
function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon"
    >
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </svg>
  );
}
function MapPinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon"
    >
      <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
      <circle cx="12" cy="10" r="3" />
    </svg>
  );
}
