import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
const Sidebar = () => {
  const sidebarRef = useRef(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(
    localStorage.getItem("isSubMenuOpen") === "true"
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // event.stopPropagation();
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !$(".btn-aside-minimize").is(event.target)
      ) {
        // Close sidebar if clicked outside of it
        if (window.innerWidth < 768) {
          $("body").removeClass("aside-mini");
          $(".navbar-aside").removeClass("show");
        }
      }
    };

    // Add event listener for clicks on the document
    document.addEventListener("click", handleOutsideClick);
    if (window.innerWidth < 768) {
      setIsSubMenuOpen(true);
    }
    return () => {
      // Cleanup by removing event listener
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleSubMenu = () => {
    const newSubMenuState = !isSubMenuOpen;
    setIsSubMenuOpen(newSubMenuState);
    localStorage.setItem("isSubMenuOpen", newSubMenuState);
  };

  return (
    <div ref={sidebarRef}>
      <aside className={`navbar-aside`} id="offcanvas_aside">
        <div className="aside-top">
          <Link to="/" className="brand-wrap ">
            <div className="brand-content">
              {/* <img
                src="/images/fulllogo.png"
                className="side-image"
                alt="Tripoli Fresh Market"
              /> */}
              {/* <div>
                <span className="brand-name">Tripoli Fresh</span>
                <br />
                <span className="brand-market">Market</span>
              </div> */}
            </div>
          </Link>
          <div className="btn-wrapper">
            {/* <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted fas fa-stream"></i>
            </button> */}
          </div>
        </div>

        <nav>
          <ul className="menu-aside">
            {/* <div className="menu-heading">Pages</div> */}

            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link-header"
                to="/orders"
              >
                {/* <i className="icon fas fa-file-alt"></i> */}
                <span className="menu-heading">Orders</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link-header"
                to="/pricelist"
              >
                {/* <i className="icon fas fa-dollar-sign"></i> */}
                <span className="menu-heading">Price List</span>
              </NavLink>
            </li>
            <div
              className="menu-heading"
              onClick={toggleSubMenu}
              style={{ cursor: "pointer", textOverflow: "ellipsis" }}
            >
              {/* <i className="icon fas fa-cog"></i> */}
              Configurations
              <i
                className={`iconfig fas ${
                  isSubMenuOpen ? "fa-chevron-up" : "fa-chevron-down"
                } submenu-toggle`}
                style={{ float: "right", marginTop: "0.4rem" }}
              ></i>
            </div>
            {isSubMenuOpen && (
              <ul className="menu-aside">
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/products"
                  >
                    <i
                      className="icon fas fa-shopping-bag"
                      style={{ width: "1.3rem" }}
                    ></i>
                    <span className="text">Products</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/category"
                  >
                    <i
                      className="icon fas fa-layer-group"
                      style={{
                        width: "1.3rem",
                        // backgroundColor: "black",
                        height: "1.15rem",
                      }}
                    ></i>
                    <span className="text">Categories</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/customers"
                  >
                    <i
                      className="icon fas fa-address-book"
                      style={{ width: "1.3rem" }}
                    ></i>
                    <span className="text">Customers</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/users"
                  >
                    <i
                      className="icon fas fa-user"
                      style={{ width: "1.3rem" }}
                    ></i>
                    <span className="text">Users</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/units"
                  >
                    <i
                      className="icon fas fa-box"
                      style={{ width: "1.3rem" }}
                    ></i>
                    <span className="text">Units</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/orderstatus"
                  >
                    <i
                      className="icon fas fa-shipping-fast"
                      style={{ width: "1.3rem" }}
                    ></i>
                    <span className="text">Order Status</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/banners"
                  >
                    <i
                      className="icon fas fa-images"
                      style={{ width: "1.3rem" }}
                    ></i>
                    <span className="text">Banners</span>
                  </NavLink>
                </li>
              </ul>
            )}
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
