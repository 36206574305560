import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import buildLink from "../../urls";
import Cookies from "js-cookie";
import Loading from "../LoadingError/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Snackbar from "../LoadingError/snackbar";
import DeleteConfirmationDialog from "../dialogue";
import { handleError } from "../../services/functions";

const MainUser = () => {
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [mssg, setMssg] = useState(false);
  const history = useHistory();
  // const token = Cookiea.get('token')
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(buildLink("users"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        // console.log(response, "response");
        setUsers(response.data.data);
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const deleteUser = async (user) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("user") + `?user_id=${user.user_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      // console.log(response.data.success, "dsads");
      if (response.status === 200) {
        setLoading(false);
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 1200);

        const filteredUsers = users.filter((us) => us.user_id !== user.user_id);
        setUsers(filteredUsers);
        // console.log(response);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (user) => {
    history.push(`/adduser?user_id=${user.user_id}`);
  };

  const handleDeleteClick = (e, user) => {
    e.stopPropagation();
    setUserToDelete(user);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (userToDelete) {
      await deleteUser(userToDelete);
    }

    setShowDeleteConfirmation(false);
    setUserToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setUserToDelete(null);
  };
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Users</h2>
        <div>
          <Link to="/adduser" className="btn btnhoverdark">
            Create new
          </Link>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            <>
              <div className="table-responsive mb-4">
                <table className="table tabletd">
                  <thead>
                    <tr>
                      <th style={{ width: "3rem" }}>ID</th>
                      <th style={{ width: "19rem" }}>Username</th>
                      <th style={{ width: "9rem" }}>Permission Count</th>
                      {/* <th style={{ width: "9rem" }}>Date Added</th> */}
                      {/* <th style={{ width: "9rem" }}>Date Modified</th> */}
                      <th style={{ width: "9rem" }}>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((user) => (
                      <tr
                        key={`user${user.user_id}`}
                        className="cursorpointer"
                        onClick={() => handleEdit(user)}
                      >
                        <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "3rem" }}>
                              {user.user_id.toString().padStart(4, "0")}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex ">
                            <div
                              className="text_overflow"
                              style={{
                                width: "19rem",
                              }}
                            >
                              {user.username}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "9rem" }}>
                              {user.permission_count}
                            </div>
                          </div>
                        </td>
                        {/* <td>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div style={{ width: "9rem" }}>
                              {user?.date_added?.split(" ")[0]}
                              <br />
                              {user?.date_added?.split(" ")[1]}
                            </div>
                          </div>
                        </td> */}
                        {/* <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "9rem" }}>
                              {user?.date_modified?.split(" ")[0]} <br />
                              {user?.date_modified?.split(" ")[1]}
                            </div>
                          </div>
                        </td> */}
                        <td
                          className="text-center cursorhand"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="flex ">
                            {/* <button
                              className="action-btn edit-btn"
                              onClick={() => handleEdit(user)}
                            >
                              <i className="far fa-pen"></i>
                            </button>
                            | */}
                            <button
                              className="action-btn delete-btn"
                              onClick={(e) => handleDeleteClick(e, user)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {showSnackbar && (
                <Snackbar message={snackbarMessage} style={snackbarColor} />
              )}
              {showDeleteConfirmation && (
                <DeleteConfirmationDialog
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  name={"user"}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default MainUser;
