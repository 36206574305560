export default function Ordering() {
  return (
    <section className="tripoli-section  bg" id="ordering">
      <div className="tripoli-content-ordering">
        <h2 className="tripoli-title">Ordering</h2>
        {/* <div className="about-grid"> */}
        <p className="tripoli-text">
          At Tripoli Fresh Market Wholesale, we understand the importance of
          timely delivery in the wholesale produce business. Once your order is
          approved, our dedicated delivery team ensures that your fresh produce
          arrives at your business swiftly and in perfect condition. We offer
          cash on delivery for your convenience, ensuring a smooth and
          hassle-free transaction.
        </p>
        {/* <p className="text-color ordering-p">Ordering Options</p> */}
        <div className="cards-order">
          <div className="card-order">
            <p className="ordering-p">Mobile App</p>
            <p>
              Download our custom app to easily place orders, manage your
              pantry, and get support from our team.
            </p>
            <a href="/" className="button-order primary">
              Order via App{" "}
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1.5em"
                className="card-icon-order"
                width="1.5em"
              >
                <path d="M12.71 16.29l-.15-.12a.76.76 0 00-.18-.09L12.2 16a1 1 0 00-.91.27 1.15 1.15 0 00-.21.33 1 1 0 001.3 1.31 1.46 1.46 0 00.33-.22 1 1 0 00.21-1.09 1 1 0 00-.21-.31zM16 2H8a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V5a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H8a1 1 0 01-1-1V5a1 1 0 011-1h8a1 1 0 011 1z" />
              </svg>
            </a>
          </div>
          <div className="card-order">
            {/* <FontAwesomeIcon icon={faEnvelopeOpen} className="card-icon-order" /> */}
            <p className="ordering-p">Email </p>
            <p className="ordering-description">
              Send us your order details via email and our team will process it
              promptly.
              <p className="text-color mt-1">info@tripolifreshmarket.com</p>
            </p>

            <a
              href="mailto:info@tripolifreshmarket.com"
              className="button-order primary"
            >
              Order via Email{" "}
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1.5em"
                width="1.5em"
                className="card-icon-order m-1"
              >
                <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6m-2 0l-8 5-8-5h16m0 12H4V8l8 5 8-5v10z" />
              </svg>
            </a>
          </div>
          <div className="card-order">
            {/* <FontAwesomeIcon icon={faPhone} className="card-icon-order" /> */}
            <p className="ordering-p">Phone </p>
            <p>
              Call our dedicated ordering hotline and one of our team members
              will assist you.
              <p className="text-color mt-1">+961 71 144 332</p>
            
            </p>
            <a href="tel:+96171144332" className="button-order primary">
              Order via Phone{" "}
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1.5em"
                width="1.5em"
                className="card-icon-order m-1"
              >
                <path d="M17.707 12.293a.999.999 0 00-1.414 0l-1.594 1.594c-.739-.22-2.118-.72-2.992-1.594s-1.374-2.253-1.594-2.992l1.594-1.594a.999.999 0 000-1.414l-4-4a.999.999 0 00-1.414 0L3.581 5.005c-.38.38-.594.902-.586 1.435.023 1.424.4 6.37 4.298 10.268s8.844 4.274 10.269 4.298h.028c.528 0 1.027-.208 1.405-.586l2.712-2.712a.999.999 0 000-1.414l-4-4.001zm-.127 6.712c-1.248-.021-5.518-.356-8.873-3.712-3.366-3.366-3.692-7.651-3.712-8.874L7 4.414 9.586 7 8.293 8.293a1 1 0 00-.272.912c.024.115.611 2.842 2.271 4.502s4.387 2.247 4.502 2.271a.991.991 0 00.912-.271L17 14.414 19.586 17l-2.006 2.005z" />
              </svg>
            </a>
          </div>
        </div>
        {/* <p className="text-color ordering-p">Ordering Options</p>
        <p className="tripoli-text">
          The availability and prices of fruits and vegetables change daily at
          the market. The best way to order your daily or weekly supplies is via
          the{" "}
          <a href="/" className="link">
            Tripoli Fresh Market App
          </a>{" "}
          or by emailing{" "}
          <a href="mailto:info@tripolifreshmarket.com" className="link">
            info@tripolifreshmarket.com
          </a>
          . You can also place orders via phone at{" "}
          <a href="tel:+96171144332" className="link">
            +961 71 144 332
          </a>
          .
        </p>{" "} */}
        {/* <p className="text-color">Contact Information</p>
            <p className="contact-info">
              If for any reason you are not satisfied with our service, please
              do not hesitate to contact our Managing Director, Ziad El Sayegh,
              directly at{" "}
              <a href="tel:+96171144332" className="link">
                +961 71 144 332
              </a>
              .
            </p> */}
        {/* <p className="text-color ordering-p">Mobile Ordering Made Easy</p>
        <p className="tripoli-text">
          Our custom app enables you to create your own pantry list for regular
          orders or create a new order each time. The app is supported on all
          Android and iOS devices. The staff at Tripoli Fresh Market is
          available to support your kitchens with any assistance you may need in
          setting up and using this preferred ordering procedure.
        </p> */}
        {/* <p className="text-color">Mobile Ordering Made Easy</p>
        <p className="delivery-info">
          Our user-friendly mobile app makes ordering fresh produce a breeze.
        </p> */}
        {/* <p className="text-color ordering-p">Contact Information</p>
        <p className="tripoli-text">
          If for any reason you are not satisfied with our service, please do
          not hesitate to contact our Managing Director, Ziad El Sayegh,
          directly at{" "}
          <a href="tel:+96171144332" className="link">
            +961 71 144 332
          </a>
          .
        </p> */}
        {/* </div> */}
        {/* <div className="side">
            <p>
              Our user-friendly mobile app makes ordering fresh produce a
              breeze. Here’s how it works:
            </p>
            <div className="order-section-box">
              <div className="order-section-timeline">
                <div className="order-section-grid">
                  {[
                    {
                      title: "Login",
                      description:
                        "Focal persons in businesses such as chefs or purchasing managers log into the app.",
                    },
                    {
                      title: "Select Products",
                      description:
                        "Browse our wide range of fruits and vegetables, see prices, and select the quantities you need.",
                    },
                    {
                      title: "Place Order",
                      description: "Submit your order with just a few clicks.",
                    },
                    {
                      title: "Review",
                      description:
                        "The admin reviews the order, makes necessary adjustments if needed, and sends a revised request for approval.",
                    },
                    {
                      title: "Approval",
                      description: "Approve the final order for processing.",
                    },
                    {
                      title: "Delivery",
                      description:
                        "Once approved, the order is sent to our delivery department for prompt and reliable delivery.",
                    },
                  ].map((item, index) => (
                    <div key={index} className="order-section-item">
                      <div className="order-section-marker" />
                      <div className="order-section-item-title">
                        {item.title}
                      </div>
                      <div className="order-section-item-description">
                        {item.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </section>
  );
}
