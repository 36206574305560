import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function AboutUs() {
  return (
    <section className="tripoli-section bgcolor1" id="aboutus">
      <div className="tripoli-content">
        <p className="tripoli-title">About Tripoli Fresh Market</p>
        <p className="tripoli-text">
          Tripoli Fresh Market is a pioneering wholesale business based in the
          heart of Tripoli’s municipal fresh market. Our mission is to
          revolutionize the traditional wholesale vegetable market by providing
          an advanced online shopping experience. We cater to businesses such as
          restaurants, small vegetable shops, and hotels, offering them an
          efficient way to order fresh produce online.
        </p>
        <p className="tripoli-text">
          Our business is built on a foundation of deep market research and a
          thorough understanding of wholesale ordering mechanisms. This ensures
          that our platform is not only cutting-edge but also tailored to the
          needs of our customers. Tripoli Fresh Market was developed by Arco
          Energies LLC, a business solutions and software development company,
          ensuring that our technology is both innovative and reliable.
        </p>
      </div>
      <div className="tripoli-image-container">
        <LazyLoadImage
          effect="blur"
          alt="Tripoli Fresh Market Aboutus"
          src="/images/aboutusv1.png"
          className="tripoli-image"
        />
      </div>
    </section>
  );
}
