import { createContext, useEffect, useReducer } from "react";
import AccountReducer, { initialState } from "../Redux/userReducers";
import Cookies from "js-cookie";

export const AccountContext = createContext({
  state: initialState,
  dispatch: () => null,
});

export default function AccountProvider(props) {
  const [state, dispatch] = useReducer(AccountReducer, initialState);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      dispatch({ type: "setLoged", payload: true });
      dispatch({ type: "setAdmin", payload: true });
      dispatch({ type: "setAdminToken", payload: token });
    }
  }, []);
  return (
    <AccountContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AccountContext.Provider>
  );
}
