import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
  {
    id: 1,
    name: "Item One",
    image_url: "/images/1.png",
  },
  {
    id: 2,
    name: "Item two",
    image_url: "/images/2.png",
  },
  {
    id: 3,
    name: "Item three",
    image_url: "/images/3.png",
  },
  {
    id: 4,
    name: "Item four",
    image_url: "/images/4.png",
  },
  {
    id: 5,
    name: "Item Five",
    image_url: "/images/5.png",
  },
  {
    id: 6,
    name: "Item Six",
    image_url: "/images/6.png",
  },
];

export default function BestSelling() {
  const sliderRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState({});

  useEffect(() => {
    if (images && images.length > 0) {
      const newImageLoadedState = {};
      images?.forEach((image) => {
        console.log("image.id", image.id);
        newImageLoadedState[image.id] = false;
      });
      setImageLoaded(newImageLoadedState);
    }
  }, [images]);

  const handleImageLoad = (itemId) => {
    console.log("itemId", itemId);
    setImageLoaded((prevState) => ({
      ...prevState,
      [itemId]: true,
    }));
    console.log("imageLoaded", imageLoaded);
  };
  return (
    <section className="bestselling bg" id="bestselling">
      <div className="product-title">
        <h2 className="tripoli-title">Best Selling</h2>
        <div className="product-arrows">
          <button
            className="prev-arrow-product"
            onClick={() => sliderRef.current.slickPrev()}
          >
            <svg viewBox="0 0 16 16" height="1em" width="1em">
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 01-.5.5H5.707l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5a.5.5 0 01.5.5z"
              />
            </svg>
          </button>
          <button
            className="next-arrow-product"
            onClick={() => sliderRef.current.slickNext()}
          >
            <svg viewBox="0 0 16 16" height="1em" width="1em">
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="">
        <div className="slider">
          <Slider
            ref={sliderRef}
            className="variable-width"
            slidesToShow={5}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={3500}
            speed={700}
            infinite={true}
          >
            {images.map((image) => (
              <div key={image.id}>
                {!imageLoaded[image.id] && (
                  <div className="bgimagebestselling product-box-placeholder" />
                )}
                <img
                  // width="100%"
                  key={image.id}
                  height={344}
                  // effect="blur"
                  alt="img"
                  src={image.image_url}
                  className="product-box"
                  onLoad={() => handleImageLoad(image.id)}
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="overflow-x-product">
          {images.map((image) => (
            <div key={image.id} className="product-size">
              <LazyLoadImage
                height={320}
                width={200}
                effect="blur"
                alt="img"
                src={image.image_url}
                className="product-box"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
