import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import buildLink from "../../urls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";

// import { login } from "../../Redux/Actions/userActions";
const AddOrderStatusMain = () => {
  const [orderStatusData, setOrderStatusData] = useState([]);

  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [englishDescription, setEnglishDescription] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarColor, setSnackbarColor] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const orderStatusId = queryParams?.get("order_status_id");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("order_status") + `?order_status_id=${orderStatusId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.status === 200) {
        // console.log(response.data.data);
        setOrderStatusData(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderStatusId) {
      fetchData();
    }
  }, [orderStatusId]);

  useEffect(() => {
    if (orderStatusData) {
      setEnglishName(orderStatusData[0]?.name || "");
      setArabicName(orderStatusData[1]?.name || "");
      setEnglishDescription(orderStatusData[0]?.description || "");
      setArabicDescription(orderStatusData[1]?.description || "");
    }
  }, [orderStatusData]);

  const addOrderStatus = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const requestData = {
      order_status_details: [
        {
          name: englishName,
          description: englishDescription,
          language: "en",
        },
        {
          name: arabicName,
          description: arabicDescription,
          language: "ar",
        },
      ],
    };
    if (englishName !== "" && arabicName !== "") {
      try {
        setLoadingCreate(true);

        const apiUrl = orderStatusId
          ? buildLink("order_status") + `?order_status_id=${orderStatusId}`
          : buildLink("order_status");

        const response = await axios({
          method: orderStatusId ? "put" : "post",
          url: apiUrl,
          data: requestData,
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });

        if (response.status === 200 || response.status === 201) {
          const action = orderStatusId ? "updated" : "added";
          setSnackbarMessage(`Order status ${action} successfully!`);
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          setShowSnackbar(true);
          setLoadingCreate(false);
          setSubmitting(false);
          setTimeout(() => {
            setShowSnackbar(false);
            history.goBack();
          }, 1200);
        } else {
          setSubmitting(false);
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading,
            setLoadingCreate
          );
        }
      } catch (error) {
        setSubmitting(false);
        handleError(
          error,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={addOrderStatus}>
          <div className="flex-container col-xl-8 col-lg-8">
            <div>
              <div
                onClick={() => history.goBack()}
                className="btn btn-danger text-white"
              >
                Back
              </div>
            </div>
            <div className="col-md-4 text-center" style={{ width: "20rem" }}>
              <h2 className="content-title">
                {orderStatusId ? "Edit" : "Add"} Order status
              </h2>
            </div>
            <div className="save-btn" onClick={addOrderStatus} type="submit">
              <div className="btn btnhoverdark text-white">
                {loadingCreate ? (
                  <ButtonLoader />
                ) : (
                  <>{orderStatusId ? "Update" : "Add"}</>
                )}
              </div>
            </div>
          </div>
          {loading && orderStatusId ? (
            <Loading />
          ) : (
            <>
              <div className="row mb-4 ">
                <div className="col-xl-8 col-lg-8">
                  <div className="card mb-4 shadow-sm">
                    <div className="card-body">
                      <div className="mb-4 row">
                        <div className="col-md-6 ">
                          <label htmlFor="product_title" className="form-label">
                            Order Status Name (English)
                          </label>
                          {submitting &&
                          (englishName == "" || englishName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="English Name"
                            className={
                              submitting &&
                              (englishName == "" || englishName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="product_title"
                            maxLength={20}
                            required
                            value={englishName}
                            onChange={(e) => setEnglishName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="arabic_title" className="form-label">
                            Order Status Name (Arabic)
                          </label>
                          {submitting &&
                          (arabicName == "" || arabicName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Arabic Name"
                            className={
                              submitting &&
                              (arabicName == "" || arabicName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="arabic_title"
                            maxLength={20}
                            required
                            value={arabicName}
                            onChange={(e) => setArabicName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-4 row">
                        <div className="col-md-6">
                          <label htmlFor="eng_desc" className="form-label">
                            Description (English)
                          </label>

                          <input
                            type="text"
                            placeholder="English Description"
                            className={"form-control"}
                            id="english_desc"
                            maxLength={40}
                            // required
                            value={englishDescription}
                            onChange={(e) =>
                              setEnglishDescription(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="arabic_desc" className="form-label">
                            Description (Arabic)
                          </label>

                          <input
                            type="text"
                            placeholder="Arabic Description"
                            className={"form-control"}
                            id="arabic_desc"
                            maxLength={40}
                            // required
                            value={arabicDescription}
                            onChange={(e) =>
                              setArabicDescription(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      {/* <div className="mb-4 row"> */}

                      {/* </div> */}
                      {/* <div className="d-grid">
                        <button
                          type="submit"
                          className="btn btnhoverdark py-2"
                          disabled={loadingCreate}
                        >
                          {loadingCreate ? (
                            <ButtonLoader />
                          ) : (
                            <>
                              {orderStatusId
                                ? "Update order status"
                                : "Add order status"}
                            </>
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default AddOrderStatusMain;
