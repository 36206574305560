import React from "react";
import Sidebar from "../components/sidebar";
import Header from "./../components/Header";
import AddBannerMain from "../components/banners/AddBannerMain";

const AddBanner = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddBannerMain />
      </main>
    </>
  );
};

export default AddBanner;
