import React, { useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import buildLink from "../../urls";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";
import Loading from "../LoadingError/Loading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";

const AddCustomerMain = () => {
  const [customerData, setProductData] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [telephone, setTelephone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [deliveryFees, setDeliveryFees] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState({});
  const [validationErrors, setValidationErrors] = useState();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const customerId = queryParams?.get("customer_id");

  const addCustomer = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const body = {
      firstname: firstName,
      lastname: " ",
      password: password,
      confirm_password: confirmPassword,
      telephone: telephone?.startsWith("+961")
        ? `+961${telephone.slice(4)}`
        : `+961${telephone}`,
      company_name: companyName,
      email: email,
      delivery_fees: deliveryFees ? parseFloat(deliveryFees) : 0,
    };

    if (deliveryFees == 0) {
      setSnackbarMessage(`Delivery fees should be greater than 0`);
      setSnackbarColor({ backgroundColor: "#FF0000" });
      setShowSnackbar(true);
      setLoadingCreate(false);
      setSubmitting(false);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 1200);
    }

    if (
      firstName !== "" &&
      (!customerId ? password !== "" : true) &&
      (!customerId ? confirmPassword !== "" : true) &&
      telephone !== "" &&
      companyName !== "" &&
      email !== "" &&
      deliveryFees > 0
    ) {
      try {
        setLoadingCreate(true);

        const apiUrl = customerData
          ? buildLink("customer") + `?customer_id=${customerData.customer_id}`
          : buildLink("customer");

        const response = await axios({
          method: customerData ? "put" : "post",
          url: apiUrl,
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
            "Content-Type": "application/json",
          },
          data: body,
        });

        if (response.status === 201 || response.status === 200) {
          const action = customerData ? "updated" : "added";
          setSnackbarMessage(`Customer ${action} successfully!`);
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          setShowSnackbar(true);
          setLoadingCreate(false);
          setSubmitting(false);
          setTimeout(() => {
            setShowSnackbar(false);
            history.goBack();
          }, 1200);
        } else {
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading,
            setLoadingCreate
          );
        }
      } catch (error) {
        handleError(
          error,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("customer") + `?customer_id=${customerId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 200) {
        setProductData(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchData();
    }
  }, [customerId]);

  useEffect(() => {
    if (!customerId) {
      // fetchData();
      resetFormFields();
    }
  }, [customerId]);

  useEffect(() => {
    // console.log(customerData);
    if (customerData) {
      setCompanyName(customerData.company_name);
      setFirstName(customerData.firstname);
      setLastName(customerData.lastname);
      setEmail(customerData.email);
      setTelephone(`${customerData.telephone}`);
      setDeliveryFees(
        customerData.delivery_fees === "" ? 0 : customerData?.delivery_fees
      );
    }
  }, [customerData]);

  const resetFormFields = () => {
    setCompanyName("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setTelephone("");
    setDeliveryFees("");
    setSubmitting(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value !== confirmPassword) {
      setValidationErrors("Password does not match.");
    } else {
      setValidationErrors("");
    }
    if (confirmPassword === "") {
      setValidationErrors("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (password !== e.target.value) {
      setValidationErrors("Password does not match.");
    } else {
      setValidationErrors("");
    }
  };

  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={addCustomer}>
          <div className="flex-container col-xl-8 col-lg-8">
            <div>
              <div
                onClick={() => history.goBack()}
                className="btn btn-danger text-white"
              >
                Back
              </div>
            </div>
            <div className="col-md-4 text-center" style={{ width: "20rem" }}>
              <h2 className="content-title">
                {customerData ? "Edit" : "Add"} Customer
              </h2>
            </div>
            <div className="save-btn" onClick={addCustomer} type="submit">
              <div className="btn btnhoverdark text-white">
                {loadingCreate ? (
                  <ButtonLoader />
                ) : (
                  <>{customerId ? "Update" : "Add"}</>
                )}
              </div>
            </div>
          </div>
          {loading && customerId ? (
            <Loading />
          ) : (
            <>
              <div className="row mb-4">
                <div className="col-xl-8 col-lg-8">
                  <div className="card mb-4 shadow-sm">
                    <div className="card-body">
                      <div className="mb-4 row">
                        <div className="col-md-6">
                          <label htmlFor="company_name" className="form-label">
                            Company Name
                          </label>
                          {submitting &&
                          (companyName == "" || companyName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            className={
                              submitting &&
                              (companyName == "" || companyName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="company_name"
                            placeholder="Company Name"
                            required
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="first_name" className="form-label">
                            Contact Name
                          </label>
                          {submitting &&
                          (firstName == "" || firstName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            className={
                              submitting &&
                              (firstName == "" || firstName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="first_name"
                            placeholder="John Doe"
                            required
                            value={firstName}
                            maxLength="50"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="mb-4 row">
                        <div className="col-md-6">
                          <label htmlFor="first_name" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            placeholder="John"
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="last_name" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            placeholder="Doe"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div> */}
                      {!customerData && (
                        <div className="mb-4 row">
                          <>
                            <div className="col-md-6">
                              <label htmlFor="password" className="form-label">
                                Password
                              </label>
                              {submitting &&
                              (password == "" || password == null) ? (
                                <span className="required-content"></span>
                              ) : null}
                              <input
                                type="password"
                                className={
                                  submitting &&
                                  (password == "" || password == null)
                                    ? "required-field form-control"
                                    : "form-control"
                                }
                                id="password"
                                placeholder="********"
                                required
                                value={password}
                                onChange={handlePasswordChange}
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="confirm_password"
                                className="form-label"
                              >
                                Confirm Password
                              </label>
                              {submitting &&
                              (confirmPassword == "" ||
                                confirmPassword == null) ? (
                                <span className="required-content"></span>
                              ) : null}
                              <input
                                type="password"
                                className={
                                  submitting &&
                                  (confirmPassword == "" ||
                                    confirmPassword == null ||
                                    validationErrors)
                                    ? "required-field form-control"
                                    : "form-control"
                                }
                                id="confirm_password"
                                placeholder="********"
                                required
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                              />
                              {validationErrors && (
                                <div className="text-danger">
                                  {validationErrors}
                                </div>
                              )}
                            </div>
                          </>
                        </div>
                      )}
                      <div className="mb-4 row">
                        <div className="col-md-6">
                          <label htmlFor="telephone" className="form-label">
                            Phone Number
                          </label>
                          {submitting &&
                          (telephone == "" || telephone == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            className={
                              submitting &&
                              (telephone == "" || telephone == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            placeholder="8 digits only xxxxxxxx"
                            id="telephone"
                            pattern="[0-9]{8}"
                            title="Please enter 8 digits"
                            required
                            value={telephone}
                            onChange={(e) => {
                              const input = e.target.value;
                              if (/^\d{0,8}$/.test(input)) {
                                setTelephone(input);
                              }
                            }}
                            disabled={customerData ? true : false}
                          />
                        </div>
                      </div>
                      <div className="mb-4 row">
                        <div className="col-md-6">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          {submitting && (email == "" || email == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="email"
                            className={
                              submitting && (email == "" || email == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            placeholder="johndoe@gmail.com"
                            id="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={customerData ? true : false}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="delivery_fees" className="form-label">
                            Delivery Fees ($)
                          </label>
                          {submitting &&
                          (deliveryFees < 0 || deliveryFees == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            className={
                              submitting &&
                              (deliveryFees <= 0 || deliveryFees == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="delivery_fees"
                            placeholder="xxx"
                            required
                            type="number"
                            pattern="[0-9]{3}"
                            value={deliveryFees}
                            onChange={(e) => {
                              const input = e.target.value;
                              if (/^\d{0,3}$/.test(input)) {
                                setDeliveryFees(input);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "," ||
                                e.key === "-" ||
                                e.key === "e" ||
                                e.key === "+"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(event) => event.currentTarget.blur()}
                            min={0}
                            max={999}
                            maxLength={3}
                            step={1}
                          />
                        </div>
                      </div>
                      {/* <div className="d-grid">
                        <button
                          type="submit"
                          className="btn btnhoverdark py-2"
                          disabled={loadingCreate}
                        >
                          {loadingCreate ? (
                            <ButtonLoader />
                          ) : (
                            <>
                              {customerData
                                ? "Update customer"
                                : "Add customer"}
                            </>
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default AddCustomerMain;
