import React, { useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import buildLink from "../../urls";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";
import Loading from "../LoadingError/Loading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";

const AddBannerMain = () => {
  const [bannerData, setBannerData] = useState();
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState({});
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const bannerId = queryParams?.get("banner_id");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log("wtf");
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        // console.log("imagePreview", imagePreview);
      };
      reader.readAsDataURL(file);
      // console.log("file", file);
      setImage(file);
      // console.log(image);
    }
  };
  const addBanner = async (e) => {
    e.preventDefault();
    // console.log("image", image);
    const body = bannerData
      ? {
          image: image,
          title: title,
          width: width,
          height: height,
        }
      : { image: image, title: title };

    try {
      setLoadingCreate(true);

      const apiUrl = bannerData
        ? buildLink("banner") + `?banner_id=${bannerData.banner_id}`
        : buildLink("banner");

      const response = await axios({
        method: bannerData ? "put" : "post",
        url: apiUrl,
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
          "Content-Type": "multipart/form-data",
        },
        data: body,
      });

      if (response.status === 201 || response.status === 200) {
        const action = bannerData ? "updated" : "added";
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setLoadingCreate(false);

        setTimeout(() => {
          setShowSnackbar(false);
          history.goBack();
        }, 1200);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading,
        setLoadingCreate
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("banner") + `?banner_id=${bannerId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 200) {
        setBannerData(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bannerId) {
      fetchData();
    }
  }, [bannerId]);

  useEffect(() => {
    if (!bannerId) {
      // fetchData();
      resetFormFields();
    }
  }, [bannerId]);

  useEffect(() => {
    // console.log(bannerData);
    if (bannerData) {
      setImage(bannerData.image);
      setTitle(bannerData.title);
      setWidth(bannerData.width);
      setHeight(bannerData.height);
    }
  }, [bannerData]);

  const resetFormFields = () => {
    setImage("");
    setTitle("");
    setWidth("");
    setHeight("");
  };

  const handleDeleteImage = () => {
    setImage(null);
    setImagePreview(null);
  };
  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={addBanner}>
          <div className="flex-container col-xl-8 col-lg-8">
            <div>
              <div
                onClick={() => history.goBack()}
                className="btn btn-danger text-white"
              >
                Back
              </div>
            </div>
            <div className="col-md-4 text-center" style={{ width: "20rem" }}>
              <h2 className="content-title">
                {bannerId ? "Edit" : "Add"} Banner
              </h2>
            </div>
            <div>
              <div></div>
            </div>
            {/* <div className="save-btn" onClick={addBanner} type="submit">
              <div className="btn btnhoverdark text-white">
                {loadingCreate ? (
                  <ButtonLoader />
                ) : (
                  <>{bannerId ? "Update" : "Add"}</>
                )}
              </div>
            </div> */}
          </div>
          {loading && bannerId ? (
            <Loading />
          ) : (
            <>
              <div className="row mb-4">
                <div className="col-xl-8 col-lg-8">
                  <div className="card mb-4 shadow-sm">
                    <div className="card-body">
                      <div className="mb-4 row">
                        <div className="col-md-6">
                          <label htmlFor="image" className="form-label">
                            Image
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            onChange={handleImageChange}
                            accept="image/*"
                            {...(image ? {} : { required: true })}
                          />
                          {image && (
                            <div
                              style={{
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              <img
                                src={imagePreview ? imagePreview : image}
                                alt="Selected"
                                style={{
                                  marginTop: "10px",
                                  maxWidth: "300px",
                                  maxHeight: "300px",
                                  marginBottom: "10px",
                                }}
                              />
                              <button
                                className="btn custom-btn delete-image-btn"
                                onClick={handleDeleteImage}
                              >
                                X
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      {bannerData && (
                        <div className="mb-4 row">
                          <>
                            <div className="col-md-6">
                              <label htmlFor="width" className="form-label">
                                Width
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="width"
                                required
                                value={width}
                                onChange={(e) => setWidth(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="height" className="form-label">
                                Height
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="height"
                                required
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                              />
                            </div>
                          </>
                        </div>
                      )}
                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn btnhoverdark py-2"
                          disabled={loadingCreate}
                        >
                          {loadingCreate ? (
                            <ButtonLoader />
                          ) : (
                            <>{bannerData ? "Update banner" : "Add banner"}</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default AddBannerMain;
