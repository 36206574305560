import React, { useState, useEffect } from "react";
import axios from "axios";
import buildLink from "../../urls";
import { Link, useHistory, useLocation } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";

const CreateCategory = () => {
  const [categoryData, setCategoryData] = useState();
  const [imagePreview, setImagePreview] = useState("");
  const [dropdownCategories, setDropdownCategories] = useState([]);
  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [image, setImage] = useState();
  const [englishDescription, setEnglishDescription] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");
  const [parentCategory, setParentCategory] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const queryParams = new URLSearchParams(location.search);

  const categoryId = queryParams?.get("category_id");

  useEffect(() => {
    if (categoryId) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            buildLink("category") + `?category_id=${categoryId}`,
            {
              headers: {
                source: "2",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }
          );
          if (response.status === 200) {
            // console.log(response);
            setCategoryData(response.data.data);
            // console.log("Category ID from URL:", categoryId);
          } else {
            // console.error("Error:", response);
            handleError(
              response,
              setSnackbarMessage,
              setSnackbarColor,
              setShowSnackbar
            );
          }
        } catch (error) {
          handleError(
            error,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [categoryId]);

  const getCategoryDropdown = async () => {
    setLoadingCategories(true);
    try {
      const response = await axios.get(buildLink("getCategoryDropdown"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      if (response.status === 200) {
        setDropdownCategories(response.data.data);
        setLoadingCategories(false);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar
        );
        setLoadingCategories(false);
      }
    } catch (error) {
      setLoadingCategories(false);
      handleError(error, setSnackbarMessage, setSnackbarColor, setShowSnackbar);
    }
  };

  useEffect(() => {
    getCategoryDropdown();
  }, []);

  useEffect(() => {
    // console.log("edit");
    // console.log("wth", categoryData);
    if (categoryData) {
      setCategoryData(categoryData);
      // console.log("wth", categoryData);
      // console.log(categoryData);
      categoryData.description.forEach((description) => {
        if (description.language === "en") {
          setEnglishName(description.name || "");
          setEnglishDescription(description.description || "");
        } else if (description.language === "ar") {
          setArabicName(description.name || "");
          setArabicDescription(description.description || "");
        }
      });
      setParentCategory(categoryData?.parent_id || "0");
      setImage(categoryData?.image);
      setImagePreview(categoryData?.image);
    }
  }, [categoryData]);

  // const resetFormFields = () => {
  //   setEnglishName("");
  //   setArabicName("");
  //   setImage("");
  //   setEnglishDescription("");
  //   setArabicDescription("");
  //   setParentCategory("0");
  //   setCategoryData();
  // };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        // console.log("imagePreview", imagePreview);
      };
      reader.readAsDataURL(file);
      setImage(file);
      // console.log(file);
    }
  };
  // const handleAddCategory = async (e) => {
  //   await addCategory(e, arabicName, arabicDescription, englishName, englishDescription, parentCategory, image, categoryData, loadingCreate, snackbarMessage, snackbarColor, showSnackbar, history);
  // };
  const addCategory = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    // const formData = new FormData();
    // formData.append("category_description[0][name]", arabicName);
    // formData.append("category_description[0][description]", arabicDescription);
    // formData.append("category_description[0][language]", "ar");

    // formData.append("category_description[1][name]", englishName);
    // formData.append("category_description[1][description]", englishDescription);
    // formData.append("category_description[1][language]", "en");

    // formData.append("parent_id", parentCategory);
    // formData.append("image", image);
    // Initialize an object to store the data
    let requestData = {};
    requestData.category_description = [
      {
        name: arabicName,
        description: arabicDescription,
        language: "ar",
      },
      {
        name: englishName,
        description: englishDescription,
        language: "en",
      },
    ];
    requestData.parent_id = parseFloat(parentCategory);
    requestData.image = image;
    if (
      englishName !== "" &&
      arabicName !== "" &&
      image !== null &&
      image !== "" &&
      imagePreview !== "" &&
      imagePreview !== null &&
      englishDescription !== "" &&
      arabicDescription !== ""
    ) {
      try {
        setLoadingCreate(true);

        const apiUrl = categoryData
          ? buildLink("category") + `?category_id=${categoryData.category_id}`
          : buildLink("category");

        const response = await axios({
          method: categoryData ? "put" : "post",
          url: apiUrl,
          data: requestData,
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          const action = categoryData ? "updated" : "added";
          setSnackbarMessage(response.data.data.message);
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          setShowSnackbar(true);
          setLoadingCreate(false);

          setTimeout(() => {
            setShowSnackbar(false);
            history.goBack();
          }, 1200);
        } else {
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading,
            setLoadingCreate
          );
        }
      } catch (error) {
        handleError(
          error,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      } finally {
        setLoading(false);
      }
    }
  };

  // const handleErrorResponse = (error) => {
  //   let errorMessage = "Error handling the request";

  //   if (error.response) {
  //     errorMessage = error.response.data.message || "Server error";
  //   } else if (error.request) {
  //     errorMessage = "No response received from the server";
  //   }

  //   setSnackbarMessage(errorMessage);
  //   setSnackbarColor({ backgroundColor: "#FF0000" });
  //   setShowSnackbar(true);
  //   setLoadingCreate(false);

  //   setTimeout(() => {
  //     setShowSnackbar(false);
  //   }, 1500);
  // };

  const resetFormFields = () => {
    setEnglishName("");
    setArabicName("");
    setEnglishDescription("");
    setArabicDescription("");
    setImage("");
    setParentCategory("0");
  };

  useEffect(() => {
    if (!categoryId) {
      // fetchData();
      resetFormFields();
    }
  }, [categoryId]);

  const handleDeleteImage = () => {
    setImage(null);
    setImagePreview(null);
  };

  return (
    <section className="content-main" style={{ maxWidth: "1200px" }}>
      <form onSubmit={addCategory}>
        <div className="flex-container col-xl-8 col-lg-8">
          <div>
            <div
              onClick={() => history.goBack()}
              className="btn btn-danger text-white"
            >
              Back
            </div>
          </div>
          <div className="col-md-4 text-center" style={{ width: "20rem" }}>
            <h2 className="content-title">
              {categoryId ? "Edit" : "Add"} Category
            </h2>
          </div>
          <div className="save-btn" onClick={addCategory} type="submit">
            <div className="btn btnhoverdark text-white">
              {loadingCreate ? (
                <ButtonLoader />
              ) : (
                <>{categoryId ? "Update" : "Add"}</>
              )}
            </div>
          </div>
        </div>
        {loading || loadingCategories ? (
          <Loading />
        ) : (
          <>
            <div className="row mb-4 ">
              <div className="col-xl-8 col-lg-8">
                <div className="card mb-4 shadow-sm">
                  <div className="card-body">
                    <div>
                      <div className="mb-4 row">
                        <div className="col-md-6">
                          <label htmlFor="english_name" className="form-label">
                            Category Title (English)
                          </label>
                          {submitting &&
                          (englishName == "" || englishName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="English Title"
                            className={
                              submitting &&
                              (englishName == "" || englishName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="english_name"
                            name="english_name"
                            maxLength={20}
                            value={englishName}
                            onChange={(event) =>
                              setEnglishName(event.target.value)
                            }
                            required
                          />
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="arabic_name" className="form-label">
                            Arabic Title
                          </label>
                          {submitting &&
                          (arabicName == "" || arabicName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Arabic Title"
                            className={
                              submitting &&
                              (arabicName == "" || arabicName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="arabic_name"
                            name="arabic_name"
                            maxLength={20}
                            value={arabicName}
                            onChange={(event) =>
                              setArabicName(event.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-6 mb-9">
                        <label className="form-label">
                          English Description
                        </label>
                        {submitting &&
                        (englishDescription == "" ||
                          englishDescription == null) ? (
                          <span className="required-content"></span>
                        ) : null}
                        <textarea
                          placeholder="English Description"
                          className={
                            submitting &&
                            (englishDescription == "" ||
                              englishDescription == null)
                              ? "required-field form-control"
                              : "form-control"
                          }
                          rows="4"
                          name="description"
                          maxLength={150}
                          value={englishDescription}
                          onChange={(event) =>
                            setEnglishDescription(event.target.value)
                          }
                          required
                        ></textarea>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="form-label">Arabic Description</label>
                        {submitting &&
                        (arabicDescription == "" ||
                          arabicDescription == null) ? (
                          <span className="required-content"></span>
                        ) : null}
                        <textarea
                          placeholder="Arabic Description"
                          className={
                            submitting &&
                            (arabicDescription == "" ||
                              arabicDescription == null)
                              ? "required-field form-control"
                              : "form-control"
                          }
                          rows="4"
                          name="description"
                          maxLength={150}
                          value={arabicDescription}
                          onChange={(event) =>
                            setArabicDescription(event.target.value)
                          }
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="parentCategory" className="form-label">
                        Parent Category
                      </label>
                      <select
                        className="form-select py-2"
                        id="parentCategory"
                        value={parentCategory}
                        onChange={(event) =>
                          setParentCategory(event.target.value)
                        }
                      >
                        <option key={"0"} value="0">
                          Select a parent category (optional)
                        </option>
                        {dropdownCategories?.map((category) => (
                          <option
                            key={`category_${category.category_id}`}
                            value={category.category_id}
                          >
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4 ">
                      <label className="form-label">Image</label>
                      {submitting && (image == "" || image == null) ? (
                        <span className="required-content"></span>
                      ) : null}
                      <input
                        className={
                          submitting && (image == "" || image == null)
                            ? "required-field form-control"
                            : "form-control"
                        }
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        {...(image ? {} : { required: true })}
                      />
                    </div>

                    {image && (
                      <div
                        style={{ marginBottom: "20px", position: "relative" }}
                      >
                        <img
                          src={imagePreview ? imagePreview : image}
                          alt="Selected"
                          style={{
                            marginTop: "10px",
                            maxWidth: "300px",
                            maxHeight: "300px",
                            marginBottom: "10px",
                          }}
                        />
                        <button
                          className="btn custom-btn delete-image-btn"
                          onClick={handleDeleteImage}
                        >
                          X
                        </button>
                      </div>
                    )}
                    {/* <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btnhoverdark py-2"
                        disabled={loadingCreate}
                      >
                        {loadingCreate ? (
                          <ButtonLoader />
                        ) : (
                          <>
                            {categoryData ? "Update category" : "Add category"}
                          </>
                        )}
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              {showSnackbar && (
                <Snackbar message={snackbarMessage} style={snackbarColor} />
              )}
            </div>
          </>
        )}
      </form>
    </section>
  );
};

export default CreateCategory;
