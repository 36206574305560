import Footer2 from "./Footer2";

export default function PrivacyPolicy() {
  return (
    <div className="container-width">
      <section className="tripoli-section" id="privacypolicy">
        <div className="tripoli-text ">
          <h2 className="text-color" id="privacy-title">Privacy Policy</h2>

          <h3 className="text-color">Information Collection</h3>
          <p>
            <strong>1.1 Personal Information:</strong> When you download and use
            the Tripoli Fresh Market App, we may collect certain personal
            information that you voluntarily provide, such as your name, contact
            details, delivery address, and payment information. We only gather
            the information necessary to provide you with our services
            efficiently.
          </p>
          <p>
            <strong>1.2 Usage Information:</strong> Besides personal
            information, we may automatically collect certain data about your
            use of the App, including your device information, IP address,
            browsing activities, and app usage patterns. This data helps us
            enhance the App’s performance and offer you a more personalized
            experience.
          </p>

          <h3 className="text-color">Information Use</h3>
          <p>
            <strong>2.1 Service Provision:</strong> We use the personal
            information you provide to process your orders, fulfill your
            requests, and deliver optimal service. This includes managing your
            account, processing payments, and communicating with you regarding
            your orders or any changes to our services.
          </p>
          <p>
            <strong>2.2 App Improvement:</strong> We may analyze the usage
            information collected from the App to improve its functionality,
            troubleshoot technical issues, and optimize the user experience.
            This analysis is performed in an aggregated and anonymized manner to
            ensure individual identification is not possible.
          </p>
          <p>
            <strong>2.3 Marketing Communication:</strong> With your consent, we
            may send you promotional emails or notifications about our products,
            services, offers, or relevant updates. You can unsubscribe from such
            communications at any time by following the instructions provided in
            the email or contacting our customer support.
          </p>

          <h3 className="text-color">Information Sharing</h3>
          <p>
            <strong>3.1 Third-Party Service Providers:</strong> We may engage
            trusted third-party service providers to assist us in delivering our
            services. These providers may access your personal information
            strictly on a need-to-know basis, and they are obligated to maintain
            its confidentiality and use it solely for the purposes of assisting
            us in delivering our services.
          </p>
          <p>
            <strong>3.2 Legal Requirements:</strong> We may disclose your
            personal information if required by law or in response to valid
            requests from public authorities (e.g., a court or government
            agency) to comply with legal obligations or defend our legal rights.
          </p>

          <h3 className="text-color">Data Security</h3>
          <p>
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access, loss,
            alteration, or disclosure. We use industry-standard security
            protocols and regularly review and update our security practices to
            ensure the ongoing integrity and confidentiality of your data.
          </p>

          <h3 className="text-color">Data Retention</h3>
          <p>
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy unless a longer
            retention period is required or permitted by law. Once your data is
            no longer needed, we will securely dispose of it in accordance with
            our data retention practices.
          </p>

          <h3 className="text-color">Your Rights</h3>
          <p>
            You have the right to access, update, correct, or delete your
            personal information held by us. If you wish to exercise any of
            these rights or have any concerns about your privacy, please contact
            us using the information provided at the end of this Privacy Policy.
          </p>

          <h3 className="text-color">Amendments to the Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices, legal requirements, or as necessary for
            the protection of your privacy. We will notify you of any material
            changes by posting the updated policy on the App or by other
            reasonable means.
          </p>

          <h3 className="text-color">Contact Us</h3>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our privacy practices, please contact us at:
          </p>
          <ul>
            <li>
              Email:{" "}
              <a
                href="mailto:info@tripolifreshmarket.com"
                className="mail-link"
              >
                info@tripolifreshmarket.com
              </a>
            </li>
            <li>
              Phone:{" "}
              <a href="tel:+96171144332" className="telephone">
                +961 71 144 332
              </a>
            </li>
          </ul>

          <p>
            Effective Date: <span className="text-color">10/06/2024</span>
          </p>
        </div>
      </section>
      <Footer2 />
    </div>
  );
}
