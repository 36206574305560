import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import buildLink from "../../urls";
import Select from "react-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import Loading from "../LoadingError/Loading";
import { handleError } from "../../services/functions";
import DeleteConfirmationDialog from "../dialogue";

const AddProductMain = () => {
  const [productData, setProductData] = useState();
  const [imagePreview, setImagePreview] = useState("");
  const [categories, setCategories] = useState([]);
  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [image, setImage] = useState();
  const [englishDescription, setEnglishDescription] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [priceToDelete, setPriceToDelete] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [unitsDropdown, setUnitsDropdown] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [pricePerUnit, setPricePerUnit] = useState(0);
  const [minQuantity, setMinQty] = useState(1);
  const [maxQuantity, setMaxQty] = useState(300);
  const [additionalPrices, setAdditionalPrices] = useState([]);
  const [minQuantites, setMinQuantities] = useState([]);
  const [maxQuantities, setMaxQuantities] = useState([]);
  const [showPrice, setShowPrice] = useState(0);
  const [selectedParentCategory, setSelectedParentCategory] = useState();
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loadingSubCategories, setloadingSubCategories] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [unitsLoading, setUnitsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [parentCategories, setParentCategories] = useState([]);
  const [loadingParentCategories, setloadingParentCategories] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState("");
  const [combinedCategories, setCombinedCategories] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const productId = queryParams?.get("product_id");
  const [showAdditionalPrices, setShowAdditionalPrices] = useState(
    productData?.prices_per_unit.length > 1
  );
  const [specialPrices, setSpecialPrices] = useState({
    price: 0,
    unit_id: selectedUnit?.value,
    // show_price: 0,
    primary: 1,
    special: 0,
  });

  useEffect(() => {
    if (unitsDropdown) {
      const mainUnit = unitsDropdown.find((unit) => unit.unit_id === 3);
      setSelectedUnit(
        mainUnit ? { value: mainUnit.unit_id, label: mainUnit.name } : {}
      );
    }
  }, [unitsDropdown]);

  useEffect(() => {
    if (unitsDropdown.length === 0) {
      getUnitsDropdown();
    }
  }, [unitsDropdown]);

  const getUnitsDropdown = async () => {
    setUnitsLoading(true);
    try {
      const response = await axios.get(buildLink("units"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      // console.log("response", response);
      if (response.status === 200) {
        setUnitsDropdown(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setUnitsLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setUnitsLoading
      );
    } finally {
      setUnitsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("product") + `?product_id=${productId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      // console.log(response);

      if (response.status === 200) {
        setProductData(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchData();
    }
  }, [productId]);

  useEffect(() => {
    if (!productId) {
      // fetchData();
      resetFormFields();
    }
  }, [productId]);

  useEffect(() => {
    if (productData) {
      console.log("productData", productData);
      productData.product_description.forEach((description) => {
        if (description.language === "en") {
          setEnglishName(description.name || "");
          setEnglishDescription(description.description || "");
        } else if (description.language === "ar") {
          setArabicName(description.name || "");
          setArabicDescription(description.description || "");
        }
      });
      setShowAdditionalPrices(
        productData?.prices_per_unit && productData.prices_per_unit.length > 1
      );

      // const selectedCategoryOptions = productData?.categories?.map(
      //   (categoryId) => {
      //     const foundCategory = parentCategories?.find(
      //       (category) => category.category_id === categoryId
      //     );
      //     return foundCategory
      //       ? { value: foundCategory.category_id, label: foundCategory.name }
      //       : null;
      //   }
      // );

      // setParentCategoryId(selectedCategoryOptions[0]?.value);
      // setSelectedParentCategory(selectedCategoryOptions[0]);

      // const combined = selectedCategoryOptions
      //   .slice(1)
      //   .map((option) => option.value);

      // const selectedSubCategoryOptions = productData.categories
      //   ?.slice(1)
      //   .map((categoryId) => {
      //     console.log(categoryId, "categoryId");
      //     console.log(subCategories, "subCategories");
      //     const foundCategory = subCategories?.find(
      //       (category) => category.category_id === categoryId
      //     );
      //     console.log(foundCategory, "foundCategory");
      //     return foundCategory
      //       ? { value: foundCategory.category_id, label: foundCategory.name }
      //       : null;
      //   });

      // setSelectedSubCategories(selectedSubCategoryOptions);
      // console.log(selectedSubCategoryOptions, "subCategories");

      // setCombinedCategories(combined);

      setImage(productData?.image);
      setImagePreview(productData?.image);

      const mainUnit = unitsDropdown?.find(
        (unit) => unit?.unit_id === productData?.prices_per_unit[0]?.unit_id
      );

      setSelectedUnit(
        mainUnit
          ? {
              value: mainUnit.unit_id,
              label: unitsDropdown.find(
                (unit) => unit.unit_id === mainUnit.unit_id
              )?.name,
            }
          : {}
      );

      setPricePerUnit(productData?.prices_per_unit[0]?.price || 0);
      // console.log(pricePerUnit, "pricePerUnit");

      setSpecialPrices(productData?.prices_per_unit[0]);
      // console.log(specialPrices, "specialPrices");

      setMinQty(productData?.prices_per_unit[0]?.min_quantity || 0);
      setMaxQty(productData?.prices_per_unit[0]?.max_quantity || 0);
      setShowPrice(productData?.prices_per_unit[0]?.show_price || 0);
      const additionalPricesData = productData.prices_per_unit.slice(1);

      setAdditionalPrices(
        additionalPricesData.map((priceData) => ({
          unit: {
            value: priceData.unit_id,
            label: unitsDropdown.find(
              (unit) => unit.unit_id === priceData.unit_id
            )?.name,
          },
          price: priceData.price,
          special: priceData.special,
          show_price: priceData.show_price,
          min_quantity: priceData.min_quantity,
          max_quantity: priceData.max_quantity,
        }))
      );
    }
    // console.log(pricePerUnit, "pricePerUnit");
    // console.log(specialPrices, "specialPrices");
  }, [productData, unitsDropdown]);

  const resetFormFields = () => {
    setEnglishName("");
    setArabicName("");
    setEnglishDescription("");
    setArabicDescription("");
    setImage("");
    setPricePerUnit(0);
    setAdditionalPrices([]);
    setSelectedCategories([]);
    setSpecialPrices({
      price: 0,
      unit_id: selectedUnit?.value || 3,
      special: 0,
      min_quantity: 0,
      max_quantity: 0,
    });
    setMinQty(1);
    setMaxQty(300);
    setMinQuantities([]);
    setMaxQuantities([]);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  // useEffect(() => {
  //   getCategories();
  // }, []);

  // const getCategories = async () => {
  //   setCatLoading(true);
  //   try {
  //     const response = await axios.get(buildLink("getCategoryDropdown"), {
  //       headers: {
  //         source: "2",
  //         Authorization: "Bearer " + Cookies.get("token"),
  //       },
  //     });
  //     if (response.status === 200) {
  //       setCategories(response.data.data);
  //     } else {
  //       console.error("Error:", response);
  //       handleError(
  //         response,
  //         setSnackbarMessage,
  //         setSnackbarColor,
  //         setShowSnackbar,
  //         setCatLoading
  //       );
  //     }
  //   } catch (error) {
  //     handleError(
  //       error,
  //       setSnackbarMessage,
  //       setSnackbarColor,
  //       setShowSnackbar,
  //       setCatLoading
  //     );
  //   } finally {
  //     setCatLoading(false);
  //   }
  // };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  const checkQuantities = () => {
    let hasValidQuantities = true;

    additionalPrices.forEach((additionalPrice) => {
      if (
        additionalPrice.min_quantity !== "" &&
        additionalPrice.max_quantity !== "" &&
        additionalPrice.min_quantity !== null &&
        additionalPrice.max_quantity !== null
      ) {
        const min = parseFloat(additionalPrice.min_quantity);
        const max = parseFloat(additionalPrice.max_quantity);
        // console.log("min", min);
        // console.log("max", max);
        if (min > max) {
          setSnackbarMessage(
            "Minimum quantity should be less than max quantity"
          );
          setSnackbarColor({ backgroundColor: "#FF0000" });
          setShowSnackbar(true);
          setSubmitting(false);
          hasValidQuantities = false;
        }
      } else {
        setSnackbarMessage("Quantities cannot be null or empty");
        setSnackbarColor({ backgroundColor: "#FF0000" });
        setShowSnackbar(true);
        setSubmitting(false);
        hasValidQuantities = false;
      }
    });

    if (
      minQuantity !== "" &&
      maxQuantity !== "" &&
      minQuantity !== null &&
      maxQuantity !== null
    ) {
      const min = parseFloat(minQuantity);
      const max = parseFloat(maxQuantity);

      if (min > max) {
        setSnackbarMessage("Minimum quantity should be less than max quantity");
        setSnackbarColor({ backgroundColor: "#FF0000" });
        setShowSnackbar(true);
        setSubmitting(false);
        hasValidQuantities = false;
      }
    } else {
      setSnackbarMessage("Min and max quantities cannot be null or empty");
      setSnackbarColor({ backgroundColor: "#FF0000" });
      setShowSnackbar(true);
      setSubmitting(false);
      hasValidQuantities = false;
    }

    setTimeout(() => {
      setShowSnackbar(false);
    }, 1200);

    console.log(hasValidQuantities, "hasValidQuantities");
    return hasValidQuantities;
  };

  const addProduct = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    // console.log(specialPrices);
    let requestData = {};

    requestData.product_description = [
      {
        name: englishName,
        description: englishDescription,
        language: "en",
      },
      {
        name: arabicName,
        description: arabicDescription,
        language: "ar",
      },
    ];

    requestData.image = image;

    requestData.status = productData ? productData.status : 0;

    // requestData.combinedCategories
    combinedCategories.forEach((category, index) => {
      requestData[`categories[${index}]`] = category.value;
    });

    // console.log(
    //   "requestData",
    //   combinedCategories.forEach((category, index) => {
    //     requestData[`categories[${index}]`] = category.value;
    //   })
    // );

    const additional_prices = additionalPrices
      .filter((additionalPrice) => additionalPrice.special)
      .map((additionalPrice) => ({
        price: parseFloat(additionalPrice.special),
        unit_id: additionalPrice.unit.value,
        show_price: additionalPrice.show_price,
        min_quantity: parseFloat(additionalPrice.min_quantity),
        max_quantity: parseFloat(additionalPrice.max_quantity),
        // primary: parseFloat(0),
      }));
    const combined_prices_per_unit =
      parseFloat(specialPrices?.special) !== 0 &&
      specialPrices?.special !== undefined
        ? [
            {
              price: parseFloat(specialPrices.special),
              unit_id: selectedUnit.value,
            },
            ...(additional_prices.price !== 0 ? additional_prices : []),
          ]
        : additional_prices.price !== 0
        ? additional_prices
        : [];

    let hasNonNullValues = false;

    for (const item of combined_prices_per_unit) {
      if (item !== null) {
        hasNonNullValues = true;
        break;
      }
    }

    requestData.special_prices_per_unit = hasNonNullValues
      ? combined_prices_per_unit
      : "";

    {
      productData
        ? (requestData.prices_per_unit = [
            {
              price: pricePerUnit,
              unit_id: selectedUnit.value,
              primary: 1,
              show_price: showPrice,
              min_quantity: minQuantity,
              max_quantity: maxQuantity,
            },
            ...additionalPrices
              .filter((additionalPrice) => additionalPrice.unit)
              .map((additionalPrice, index) => ({
                price: additionalPrice.price || 0,
                unit_id: additionalPrice.unit.value,
                primary: 0,
                show_price: additionalPrice.show_price,
                min_quantity: parseFloat(additionalPrice.min_quantity),
                max_quantity: parseFloat(additionalPrice.max_quantity),
              })),
          ])
        : (requestData.prices_per_unit = [
            {
              price: 0,
              unit_id: selectedUnit.value,
              primary: 1,
              show_price: showPrice,
              min_quantity: minQuantity,
              max_quantity: maxQuantity,
            },
            ...additionalPrices
              .filter((additionalPrice) => additionalPrice.unit)
              .map((additionalPrice, index) => ({
                price: 0,
                unit_id: additionalPrice.unit.value,
                primary: 0,
                show_price: additionalPrice.show_price,
                min_quantity: parseFloat(additionalPrice.min_quantity),
                max_quantity: parseFloat(additionalPrice.max_quantity),
              })),
          ]);
    }
    // console.log(requestData);
    const hasValidUnits = additionalPrices.every((additionalPrice) => {
      return additionalPrice.unit && additionalPrice.unit.value;
    });

    // console.log(hasValidUnits, "hasValidUnits");

    const hasValidQuantities = checkQuantities();

    // console.log(hasValidQuantities, "hasValidQuantities");

    const units = [selectedUnit.value];
    additionalPrices.forEach((price) => {
      if (price.unit && price.unit.value) {
        units.push(price.unit.value);
      }
    });

    const hasDuplicates = units.some(
      (unit, index) => units.indexOf(unit) !== index
    );

    if (hasDuplicates) {
      setSnackbarMessage(
        "Duplicate units detected. Please select unique units."
      );
      setSnackbarColor({ backgroundColor: "#FF0000" });
      setShowSnackbar(true);
      setSubmitting(false);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 1200);
      return;
    }

    if (
      selectedUnit !== {} &&
      englishName !== "" &&
      englishDescription !== "" &&
      arabicName !== "" &&
      arabicDescription !== "" &&
      image !== "" &&
      image !== null &&
      imagePreview !== "" &&
      imagePreview !== null &&
      imagePreview !== undefined &&
      combinedCategories.length !== 0 &&
      selectedParentCategory !== null &&
      selectedSubCategories.length !== 0 &&
      selectedUnit !== undefined &&
      hasValidUnits &&
      hasValidQuantities
    ) {
      try {
        // console.log(requestData);
        setLoadingCreate(true);

        const apiUrl = productData
          ? buildLink("product") + `?product_id=${productData.product_id}`
          : buildLink("product");

        const response = await axios({
          method: productData ? "put" : "post",
          url: apiUrl,
          data: requestData,
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 201 || response.status === 200) {
          setSnackbarMessage(response.data.data.message);
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          setShowSnackbar(true);
          setLoadingCreate(false);
          setTimeout(() => {
            setShowSnackbar(false);
            history.goBack();
          }, 1500);
        } else {
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading,
            setLoadingCreate
          );
        }
      } catch (error) {
        handleError(
          error,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const addPriceField = () => {
    if (additionalPrices.length < 2) {
      setAdditionalPrices([
        ...additionalPrices,
        {
          unit: null,
          price: "",
          special: 0,
          show_price: 0,
          max_quantity: 300,
          min_quantity: 1,
        },
      ]);
      setMaxQuantities([...maxQuantities, 300]);
      setMinQuantities([...minQuantites, 0]);
      setShowAdditionalPrices(true);
      setSubmitting(false);
    }
  };

  const removePriceField = (index) => {
    const updatedPrices = [...additionalPrices];
    updatedPrices.splice(index, 1);
    maxQuantities.splice(index, 1);
    minQuantites.splice(index, 1);
    setAdditionalPrices(updatedPrices);
    setSubmitting(false);
  };

  const handleDeleteClick = (index) => {
    setPriceToDelete(index);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    // console.log("priceToDelete", priceToDelete);
    removePriceField(priceToDelete);
    setSubmitting(false);
    setShowDeleteConfirmation(false);
    setPriceToDelete();
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setPriceToDelete();
  };

  const handleMinQtyChange = (e) => {
    const input = e.target.value;
    if (/^[1-9][0-9]{0,2}$/.test(input) || input === "") {
      setMinQty(input);
    }
  };

  const handleMaxQtyChange = (e) => {
    const input = e.target.value;
    if (/^[1-9][0-9]{0,2}$/.test(input) || input === "") {
      setMaxQty(input);
    }
  };

  const fetchParentCategories = async () => {
    try {
      setloadingParentCategories(true);
      const response = await axios({
        method: "get",
        url: buildLink("categories") + "?filter_is_parent=1&hasLimit=0",
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        setParentCategories(response.data.data.categories);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading,
        setLoadingCreate
      );
    } finally {
      setloadingParentCategories(false);
    }
  };

  useEffect(() => {
    // if(selectedParentCategory){}
    fetchParentCategories();
  }, []);

  // set selected parent category  (edit product)
  useEffect(() => {
    if (productId) {
      // Mapping through product categories to find their corresponding parent category
      const selectedCategoryOptions = productData?.categories?.map(
        (categoryId) => {
          // Find the parent category matching the current category ID
          const foundCategory = parentCategories?.find(
            (category) => category.category_id === categoryId
          );
          return foundCategory
            ? { value: foundCategory.category_id, label: foundCategory.name }
            : null;
        }
      );
      // Get the first non-null option from the mapped categories
      const nonNullOprion = selectedCategoryOptions?.find(
        (option) => option !== null
      );

      // set the parent category id when found
      if (nonNullOprion) {
        setParentCategoryId(nonNullOprion.value);
        setSelectedParentCategory(nonNullOprion);
        setSelectedSubCategories([]);
      }
    }
  }, [parentCategories, productId, productData]);

  // fetching subcategories based on parent category id (edit product)
  useEffect(() => {
    if (productId && selectedParentCategory && parentCategoryId) {
      fetchSubCategories(parentCategoryId);
    }
  }, [selectedParentCategory, productId]);

  // set subcategories (edit product)
  useEffect(() => {
    console.log(productId, "productId");
    if (productId) {
      // handling subcategories (edit product)
      if (subCategories.length > 0) {
        // Map through product categories to find their corresponding subcategories
        const selectedSubCategoryOptions = productData?.categories
          ?.map((categoryId) => {
            // Find the subcategory matching the current categoryId
            const foundCategory = subCategories?.find(
              (category) => category.category_id === categoryId
            );
            return foundCategory
              ? { value: foundCategory.category_id, label: foundCategory.name }
              : null;
          }) // Filter out null values from the resulting array
          .filter((option) => option !== null);
        // Update state with the selected subcategories
        setSelectedSubCategories(selectedSubCategoryOptions);
      }
    }
  }, [productData, parentCategoryId, productId, subCategories]);

  const fetchSubCategories = async (parentId) => {
    // console.log("parentId", parentId);
    if (parentId !== "" && parentId !== null && parentId !== undefined) {
      try {
        setloadingSubCategories(true);
        const response = await axios({
          method: "get",
          url:
            buildLink("categories") +
            `?filter_parent_id=${
              productData ? parentCategoryId : parentId
            }&hasLimit=0`,
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });
        if (response.status === 200) {
          setSubCategories(response.data.data.categories);
        } else {
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading,
            setLoadingCreate
          );
        }
      } catch (error) {
        handleError(
          error,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      } finally {
        setloadingSubCategories(false);
      }
    }
  };

  // Handle parent category change
  const handleParentCategoryChange = (selectedOption) => {
    setSubmitting(false);
    setParentCategoryId(selectedOption.value);
    setSelectedParentCategory(selectedOption);
    // Clear selected subcategories when parent category changes
    setSelectedSubCategories([]);
    if (selectedOption && !productData && !productId) {
      fetchSubCategories(selectedOption.value);
      setSelectedSubCategories([]);
    } else if (selectedOption) {
      setSelectedSubCategories([]);
    } else {
      setSubCategories([]);
    }
  };

  const handleSubCategoryChange = (selectedOptions) => {
    setSubmitting(false);
    setSelectedSubCategories(selectedOptions);
  };

  // combining all the selected categories parent and sub for the API (check add product func)
  useEffect(() => {
    let combined = [];
    if (selectedParentCategory) {
      combined.push(selectedParentCategory);
    }
    if (selectedSubCategories.length > 0) {
      combined.push(...selectedSubCategories);
    }
    setCombinedCategories(combined);
  }, [parentCategoryId, selectedSubCategories]);

  const handleDeleteImage = () => {
    setImage(null);
    setImagePreview(null);
  };

  // console.log("combinedCategories", combinedCategories);
  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={addProduct}>
          <div className="flex-container col-xl-8 col-lg-8">
            <div>
              <div
                className="btn btn-danger text-white"
                onClick={() => history.goBack()}
              >
                Back
              </div>
            </div>
            <div>
              <h2 className="content-title">
                {productData ? "Edit" : "Add"} Product
              </h2>
            </div>
            <div className="save-btn" onClick={addProduct} type="submit">
              <div className="btn btnhoverdark text-white">
                {loadingCreate ? (
                  <ButtonLoader />
                ) : (
                  <>{productData ? "Update" : "Add"}</>
                )}
              </div>
            </div>
          </div>
          {loading || catLoading ? (
            <Loading />
          ) : (
            <>
              <div className="row mb-4 ">
                <div className="col-xl-8 col-lg-8">
                  <div className="card mb-4 shadow-sm">
                    <div className="card-body">
                      <div className="mb-4 row">
                        <div className="col-md-6 ">
                          <label htmlFor="product_title" className="form-label">
                            Product Title (English)
                          </label>
                          {submitting &&
                          (englishName == "" || englishName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="English Title"
                            className={
                              submitting &&
                              (englishName == "" || englishName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            // className="form-control required-field"
                            id="product_title"
                            maxLength={20}
                            required
                            value={englishName}
                            onChange={(e) => setEnglishName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="arabic_title" className="form-label">
                            Product Title (Arabic)
                          </label>
                          {submitting &&
                          (arabicName == "" || arabicName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Arabic Title"
                            className={
                              submitting &&
                              (arabicName == "" || arabicName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="arabic_title"
                            maxLength={20}
                            required
                            value={arabicName}
                            onChange={(e) => setArabicName(e.target.value)}
                          />
                        </div>
                      </div>
                      <span
                        className="inline-block "
                        style={{
                          fontWeight: "500",
                          color: "green",
                          display: "block",
                        }}
                      >
                        Primary
                      </span>
                      <div className="mb-4 flexin-product">
                        <div className="item-unit">
                          <label className="form-label">Unit</label>
                          {submitting &&
                          (selectedUnit == {} ||
                            selectedUnit == undefined ||
                            selectedUnit == "" ||
                            selectedUnit == undefined) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <Select
                            required
                            // className={formSubmitted && !selectedUnit ? 'required-field' : ''}
                            className={
                              submitting &&
                              (selectedUnit == {} ||
                                selectedUnit == undefined ||
                                selectedUnit == "")
                                ? "required-field"
                                : ""
                            }
                            options={unitsDropdown?.map((unit) => ({
                              value: unit.unit_id,
                              label: unit.name,
                            }))}
                            isDisabled={unitsLoading}
                            isSearchable
                            value={selectedUnit}
                            onChange={(selectedOption) =>
                              setSelectedUnit(selectedOption)
                            }
                          />
                        </div>
                        <div className="item-unit-qty">
                          <label className="form-label">Min Qty</label>
                          {submitting &&
                          (minQuantity == 0 || minQuantity == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="number"
                            placeholder="1-999"
                            // className="form-control"
                            id="min_quantity"
                            pattern="[0-9]{3}"
                            min={1}
                            max={999}
                            className={
                              submitting &&
                              (minQuantity == 0 || minQuantity == null)
                                ? "required-field form-control required-content"
                                : "form-control"
                            }
                            onWheel={(event) => event.currentTarget.blur()}
                            defaultValue={1}
                            // maxLength={20}
                            required
                            value={minQuantity}
                            onChange={handleMinQtyChange}
                          />
                        </div>
                        <div className="item-unit-qty ">
                          <label className="form-label">Max Qty</label>
                          {submitting &&
                          (maxQuantity == 0 || maxQuantity == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="number"
                            placeholder="1-999"
                            // className="form-control"
                            className={
                              submitting &&
                              (maxQuantity == 0 || maxQuantity == null)
                                ? "required-field form-control required-content"
                                : "form-control"
                            }
                            onWheel={(event) => event.currentTarget.blur()}
                            id="max_quantity"
                            // maxLength={20}
                            defaultValue={300}
                            pattern="[0-9]{3}"
                            required
                            value={maxQuantity}
                            onChange={handleMaxQtyChange}
                          />
                        </div>
                        <div className="item-unit-checkbox">
                          <label className="form-label">Show Price</label>
                          <input
                            type="checkbox"
                            className="custom-checkbox-input"
                          />
                        </div>
                        <div className="item-unit-checkbox margindelete ">
                          <label className="form-label"></label>
                          <button
                            type="button"
                            className="btn btn--danger delete-unit-btn"
                          ></button>
                        </div>
                      </div>
                      <p />
                      {showAdditionalPrices &&
                        additionalPrices?.map((additionalPrice, index) => (
                          <>
                            {" "}
                            <div
                              className="mb-4 flexin-product"
                              key={`addition_${index}`}
                            >
                              <div className="item-unit">
                                {index === 0 && (
                                  <label className="form-label">
                                    Other Unit
                                  </label>
                                )}
                                {submitting &&
                                  (!additionalPrice.unit ||
                                    additionalPrice.unit.value === "") && (
                                    <span className="required-content"></span>
                                  )}
                                <Select
                                  required
                                  options={unitsDropdown?.map((unit) => ({
                                    value: unit.unit_id,
                                    label: unit.name,
                                  }))}
                                  className={`${
                                    submitting &&
                                    (!additionalPrice.unit ||
                                      additionalPrice.unit.value === "")
                                      ? "required-field"
                                      : ""
                                  }`}
                                  isSearchable
                                  isDisabled={unitsLoading}
                                  value={additionalPrice.unit}
                                  onChange={(selectedOption) => {
                                    const updatedPrices = [...additionalPrices];
                                    updatedPrices[index].unit = selectedOption;
                                    setAdditionalPrices(updatedPrices);
                                  }}
                                />
                              </div>
                              <div className="item-unit-qty">
                                {index === 0 && (
                                  <label className="form-label">Min Qty</label>
                                )}
                                {submitting &&
                                  (additionalPrice.min_quantity === "" ||
                                    additionalPrice.min_quantity == 0) && (
                                    <span className="required-content"></span>
                                  )}
                                <input
                                  type="number"
                                  placeholder="1-999"
                                  className={`${
                                    submitting &&
                                    (additionalPrice.min_quantity === "" ||
                                      additionalPrice.min_quantity == 0)
                                      ? "required-field form-control"
                                      : "form-control"
                                  }`}
                                  id={`min_quantity_${index}`}
                                  // defaultValue={1}
                                  required
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  pattern="[0-9]{3}"
                                  value={additionalPrice.min_quantity}
                                  onChange={
                                    (e) =>
                                      // const input = e.target.value;
                                      {
                                        if (
                                          /^[1-9][0-9]{0,2}$/.test(
                                            e.target.value
                                          ) ||
                                          e.target.value === ""
                                        ) {
                                          const updatedPrices = [
                                            ...additionalPrices,
                                          ];
                                          updatedPrices[index].min_quantity =
                                            e.target.value;
                                          setAdditionalPrices(updatedPrices);
                                        }
                                      }

                                    // handleAdditionalMinQtyChange(
                                    //   index,
                                    //   e.target.value
                                    // )
                                  }
                                />
                              </div>
                              <div className="item-unit-qty">
                                {index === 0 && (
                                  <label className="form-label">Max Qty</label>
                                )}
                                {submitting &&
                                  (additionalPrice.max_quantity === "" ||
                                    additionalPrice.max_quantity == 0) && (
                                    <span className="required-content"></span>
                                  )}
                                {/* <div className="input-group"> */}
                                <input
                                  type="number"
                                  placeholder="1-999"
                                  className={`${
                                    submitting &&
                                    (additionalPrice.max_quantity === "" ||
                                      additionalPrice.max_quantity == 0)
                                      ? "required-field form-control"
                                      : "form-control"
                                  }`}
                                  id={`max_quantity_${index}`}
                                  maxLength={20}
                                  required
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  pattern="[0-9]{3}"
                                  // defaultValue={300}
                                  value={additionalPrice.max_quantity}
                                  onChange={
                                    (e) => {
                                      if (
                                        /^[1-9][0-9]{0,2}$/.test(
                                          e.target.value
                                        ) ||
                                        e.target.value === ""
                                      ) {
                                        const updatedPrices = [
                                          ...additionalPrices,
                                        ];
                                        updatedPrices[index].max_quantity =
                                          e.target.value;
                                        setAdditionalPrices(updatedPrices);
                                      }
                                    }
                                    // handleAdditionalMaxQtyChange(
                                    //   index,
                                    //   e.target.value
                                    // )
                                  }
                                />

                                {/* </div> */}
                              </div>{" "}
                              <div className="item-unit-checkbox">
                                {index === 0 && (
                                  <label className="form-label">
                                    Show Price
                                  </label>
                                )}
                                <input
                                  type="checkbox"
                                  className="custom-checkbox-input"
                                  checked={additionalPrice.show_price === 1}
                                  onChange={(e) => {
                                    const updatedPrices = [...additionalPrices];
                                    updatedPrices[index].show_price = e.target
                                      .checked
                                      ? 1
                                      : 0;
                                    setAdditionalPrices(updatedPrices);
                                  }}
                                />
                              </div>
                              <div className="item-unit-checkbox margindelete">
                                {index === 0 && (
                                  <label className="form-label">
                                    Delete Unit
                                  </label>
                                )}
                                <button
                                  type="button"
                                  className="btn btn-outline-danger delete-unit-btn"
                                  onClick={() => handleDeleteClick(index)}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                            <p />
                          </>
                        ))}
                      {additionalPrices.length < 2 && (
                        <div
                          style={{
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            // marginLeft: "0.5rem",
                            width: "8.1rem",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={addPriceField}
                          >
                            + Add Unit
                          </button>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label className="form-label">
                            Description (English)
                          </label>
                          {submitting &&
                          (englishDescription == "" ||
                            englishDescription == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <textarea
                            placeholder="English Description"
                            className={
                              submitting &&
                              (englishDescription == "" ||
                                englishDescription == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            rows="4"
                            maxLength={150}
                            required
                            value={englishDescription}
                            onChange={(e) =>
                              setEnglishDescription(e.target.value)
                            }
                          ></textarea>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Description (Arabic)
                          </label>
                          {submitting &&
                          (arabicDescription == "" ||
                            arabicDescription == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <textarea
                            placeholder="Arabic Description"
                            className={
                              submitting &&
                              (arabicDescription == "" ||
                                arabicDescription == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            rows="4"
                            maxLength={150}
                            required
                            value={arabicDescription}
                            onChange={(e) =>
                              setArabicDescription(e.target.value)
                            }
                          ></textarea>
                        </div>
                      </div>{" "}
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label className="form-label">Parent Category</label>
                          {submitting &&
                          (selectedParentCategory == "" ||
                            selectedParentCategory == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <Select
                            placeholder="Select"
                            options={parentCategories?.map((category) => ({
                              value: category.category_id,
                              label: category.name,
                            }))}
                            isDisabled={loadingParentCategories}
                            isLoading={loadingParentCategories}
                            value={selectedParentCategory}
                            onChange={handleParentCategoryChange}
                            className={
                              submitting &&
                              (selectedParentCategory == "" ||
                                selectedParentCategory == null)
                                ? "required-field"
                                : ""
                            }
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="form-label">Subcategories</label>
                          {submitting && selectedSubCategories.length == 0 ? (
                            <span className="required-content"></span>
                          ) : null}
                          <Select
                            placeholder="Select"
                            options={subCategories?.map((category) => ({
                              value: category.category_id,
                              label: category.name,
                            }))}
                            isDisabled={loadingSubCategories}
                            isLoading={loadingSubCategories}
                            isMulti
                            value={selectedSubCategories}
                            onChange={handleSubCategoryChange}
                            className={
                              submitting && selectedSubCategories.length == 0
                                ? "required-field"
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-4 col-md-6">
                        <label className="form-label">Image</label>
                        {submitting && (image == "" || image == null) ? (
                          <span className="required-content"></span>
                        ) : null}
                        <input
                          type="file"
                          onChange={handleImageChange}
                          accept="image/*"
                          className={
                            submitting && (image == "" || image == null)
                              ? "required-field form-control"
                              : "form-control"
                          }
                        />
                      </div>
                      {image && (
                        <div
                          style={{ marginBottom: "20px", position: "relative" }}
                        >
                          <img
                            src={imagePreview ? imagePreview : image}
                            alt="Selected"
                            style={{
                              marginTop: "10px",
                              maxWidth: "300px",
                              maxHeight: "300px",
                              marginBottom: "10px",
                            }}
                          />
                          <button
                            className="btn custom-btn delete-image-btn"
                            onClick={handleDeleteImage}
                          >
                            X
                          </button>
                        </div>
                      )}
                      {/* <div className="d-grid">
                        <button
                          type="submit"
                          className="btn btnhoverdark py-2"
                          disabled={loadingCreate}
                        >
                          {loadingCreate ? (
                            <ButtonLoader />
                          ) : (
                            <>
                              {productData ? "Update product" : "Add product"}
                            </>
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
                {showDeleteConfirmation && (
                  <DeleteConfirmationDialog
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                    name={"price"}
                  />
                )}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
