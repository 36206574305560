import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import buildLink from "../../urls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { headers } from "../../urls";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";
// import { login } from "../../Redux/Actions/userActions";
const AddUnitMain = () => {
  const [unitData, setUnitData] = useState([]);

  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarColor, setSnackbarColor] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const unitId = queryParams?.get("unit_id");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("unit") + `?unit_id=${unitId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.status === 200) {
        // console.log(response.data.data);
        setUnitData(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (unitId) {
      fetchData();
    }
  }, [unitId]);

  useEffect(() => {
    if (unitData) {
      setEnglishName(unitData[0]?.name || "");
      setArabicName(unitData[1]?.name || "");
    }
  }, [unitData]);

  const addUnit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const requestData = {
      unit_details: [
        {
          name: englishName,
          language: "en",
        },
        {
          name: arabicName,
          language: "ar",
        },
      ],
    };
    if (englishName !== "" && arabicName !== "") {
      try {
        setLoadingCreate(true);

        const apiUrl = unitId
          ? buildLink("unit") + `?unit_id=${unitId}`
          : buildLink("unit");

        const response = await axios({
          method: unitId ? "put" : "post",
          url: apiUrl,
          data: requestData,
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });

        if (response.status === 200 || response.status === 201) {
          const action = unitId ? "updated" : "added";
          setSnackbarMessage(response.data.data.message);
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          setShowSnackbar(true);
          setLoadingCreate(false);
          setSubmitting(false);
          setTimeout(() => {
            setShowSnackbar(false);
            history.goBack();
          }, 1500);
        } else {
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading,
            setLoadingCreate
          );
          setSubmitting(false);
        }
      } catch (error) {
        handleError(
          error,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
        setSubmitting(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={addUnit}>
          <div className="flex-container col-xl-8 col-lg-8">
            <div>
              <div
                onClick={() => history.goBack()}
                className="btn btn-danger text-white"
              >
                Back
              </div>
            </div>
            <div className="col-md-4 text-center" style={{ width: "20rem" }}>
              <h2 className="content-title">{unitId ? "Edit" : "Add"} Unit</h2>
            </div>
            <div className="save-btn" onClick={addUnit} type="submit">
              <div className="btn btnhoverdark text-white">
                {loadingCreate ? (
                  <ButtonLoader />
                ) : (
                  <>{unitId ? "Update" : "Add"}</>
                )}
              </div>
            </div>
          </div>
          {loading && unitId ? (
            <Loading />
          ) : (
            <>
              <div className="row mb-4 ">
                <div className="col-xl-8 col-lg-8">
                  <div className="card mb-4 shadow-sm">
                    <div className="card-body">
                      <div className="mb-4 row">
                        <div className="col-md-6 ">
                          <label htmlFor="product_title" className="form-label">
                            Unit Name (English)
                          </label>
                          {submitting &&
                          (englishName == "" || englishName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="English Name"
                            className={
                              submitting &&
                              (englishName == "" || englishName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="product_title"
                            maxLength={20}
                            required
                            value={englishName}
                            onChange={(e) => setEnglishName(e.target.value)}
                          />
                        </div>
                        {/* <div className="mb-4 row"> */}
                        <div className="col-md-6">
                          <label htmlFor="arabic_title" className="form-label">
                            Unit Name (Arabic)
                          </label>
                          {submitting &&
                          (arabicName == "" || arabicName == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Arabic Name"
                            className={
                              submitting &&
                              (arabicName == "" || arabicName == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="arabic_title"
                            maxLength={20}
                            required
                            value={arabicName}
                            onChange={(e) => setArabicName(e.target.value)}
                          />
                        </div>
                        {/* </div> */}
                      </div>

                      {/* <div className="d-grid">
                        <button
                          type="submit"
                          className="btn btnhoverdark py-2"
                          disabled={loadingCreate}
                        >
                          {loadingCreate ? (
                            <ButtonLoader />
                          ) : (
                            <>{unitId ? "Update unit" : "Add unit"}</>
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default AddUnitMain;
