import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import OrderStatusTable from "./OrderStatusTable";

const MainOrderStatus = () => {
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Order Statuses</h2>
        <div>
          <Link to="/addorderstatus" className="btn btnhoverdark">
            Create new
          </Link>
        </div>
      </div>

      <div className="bg-white ">
        <div className="row">

          <OrderStatusTable />
        </div>
      </div>
    </section>
  );
};

export default MainOrderStatus;
