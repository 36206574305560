import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import buildLink from "../../urls";
import { useHistory } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import DeleteConfirmationDialog from "../dialogue";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";
const UnitsTable = () => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState(null);
  const [mssg, setMssg] = useState(false);
  const history = useHistory();
  // const token = Cookiea.get('token')
  const fetchUnits = async () => {
    // console.log("headers", headers);
    try {
      setLoading(true);
      const response = await axios.get(buildLink("units"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      if (response.status === 200) {
        // console.log(response, "response");
        setUnits(response.data.data);
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log();
    fetchUnits();
  }, []);

  const deleteUnit = async (unit) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("unit") + `?unit_id=${unit.unit_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      // console.log(response.data.success, "dsads");
      if (response.status === 200) {
        setLoading(false);
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 1200);

        const filteredUnits = units.filter((un) => un.unit_id !== unit.unit_id);
        setUnits(filteredUnits);
        // console.log(response);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (unit) => {
    history.push(`/addunit?unit_id=${unit.unit_id}`);
  };

  const handleDeleteClick = (e, unit) => {
    e.stopPropagation();
    setUnitToDelete(unit);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (unitToDelete) {
      await deleteUnit(unitToDelete);
    }

    setShowDeleteConfirmation(false);
    setUnitToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setUnitToDelete(null);
  };

  return (
    <>
      <div className="">
        {loading ? (
          <Loading />
        ) : (
          <>
            {mssg ? (
              <div className="red-snackbar">{snackbarMessage}</div>
            ) : (
              <div className="table-responsive">
                <table className="table tabletd">
                  <thead>
                    <tr>
                      <th style={{ width: "3rem" }}>ID</th>
                      <th style={{ width: "20rem" }}>Name</th>
                      {/* <th>Date Added</th>
                      <th>Date Modified</th> */}
                      <th style={{ width: "5rem" }}>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {units?.map((unit) => (
                      <tr
                        key={`unit_${unit.unit_id}`}
                        onClick={() => handleEdit(unit)}
                        className="cursorpointer"
                      >
                        <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "3rem" }}>
                              {unit.unit_id.toString().padStart(4, "0")}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex ">
                            <div
                              className="text_overflow"
                              style={{
                                width: "20rem",
                              }}
                            >
                              {unit.name}
                            </div>
                          </div>
                        </td>
                        <td
                          className="text-center cursorhand"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="flex">
                            {/* <button
                              className="action-btn edit-btn"
                              onClick={() => handleEdit(unit)}
                            >
                              <i className="far fa-pen"></i>
                            </button>
                            | */}
                            <button
                              className="action-btn delete-btn"
                              onClick={(e) => handleDeleteClick(e, unit)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
                {showDeleteConfirmation && (
                  <DeleteConfirmationDialog
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                    name={"unit"}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UnitsTable;
