import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import buildLink from "../../urls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";

const AddSettingMain = () => {
  const [settingData, setSettingData] = useState([]);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const settingId = queryParams?.get("setting_id");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("settings") + `?setting_id=${settingId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.status === 200) {
        setSettingData(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (settingId) {
      fetchData();
    }
  }, [settingId]);

  useEffect(() => {
    if (settingData) {
      // console.log("settingdata", settingData);
      const setting = settingData.find((item) => item.setting_id == settingId);
      if (setting) {
        setKey(setting.key || "");
        setValue(setting.value || "");
      } else {
        setKey("");
        setValue("");
      }
    }
  }, [settingData]);

  const addSetting = async (e) => {
    e.preventDefault();
    const settingIndex = settingData.findIndex(
      (item) => item.setting_id == settingId
    );
    const updatedSettingData = [...settingData];
    updatedSettingData[settingIndex] = {
      ...updatedSettingData[settingIndex],
      value: value,
    };
    // console.log("updatedSettingData", updatedSettingData);

    const requestData = {
      data: updatedSettingData,
    };

    try {
      setLoadingCreate(true);

      // console.log("requestData", requestData);
      const apiUrl = buildLink("settings");

      const response = await axios({
        method: "post",
        url: apiUrl,
        data: requestData,
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      if (response.status === 201) {
        setSnackbarMessage(`setting updated successfully!`);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setLoadingCreate(false);

        setTimeout(() => {
          setShowSnackbar(false);
          history.push("/settings");
        }, 1500);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading,
        setLoadingCreate
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={addSetting}>
          <div className="row">
            <div className="col-md-2" style={{ width: "12rem" }}>
              <Link to="/settings" className="btn btn-danger text-white">
                Back to settings
              </Link>
            </div>
            <div className="col-md-4 text-center" style={{ width: "20rem" }}>
              <h2 className="content-title">
                {settingId ? "Edit" : "Add"} Setting
              </h2>
            </div>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="row mb-4 ">
                <div className="col-xl-8 col-lg-8">
                  <div className="card mb-4 shadow-sm">
                    <div className="card-body">
                      <div className="mb-4 row">
                        <div className="col-md-6 ">
                          <label htmlFor="key" className="form-label">
                            Key
                          </label>
                          <input
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id="key"
                            maxLength={20}
                            required
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                            disabled
                          />
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="value" className="form-label">
                            Value
                          </label>
                          <input
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id="value"
                            maxLength={20}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="d-grid mt-3">
                        <button
                          type="submit"
                          className="btn btnhoverdark py-2"
                          disabled={loadingCreate}
                        >
                          {loadingCreate ? (
                            <ButtonLoader />
                          ) : (
                            <>{settingId ? "Update setting" : "Add setting"}</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default AddSettingMain;
