/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import buildLink from "../../urls";
import { useHistory } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import DeleteConfirmationDialog from "../dialogue";
import Cookies from "js-cookie";
import { handleError, handleFilterChange } from "../../services/functions";

const CategoriesTable = () => {
  const [categories, setCategories] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [parentCategories, setParentCategoies] = useState();
  const [loading, setLoading] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [parent, setParent] = useState(null);
  const [dateAdded, setDateAdded] = useState("");
  const [dateModified, setDateModified] = useState("");
  const [mssg, setMssg] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(queryParams.get("limit") || 10);
  // const page = queryParams?.get("page");

  const initialPageNumber = queryParams?.get("page")
    ? Number(queryParams?.get("page")) - 1
    : 0;

  // console.log(queryParams?.get("page"));

  const fetchCategories = async () => {
    try {
      setLoading(true);

      const queryParams = new URLSearchParams(location.search);
      const pageQueryParam = queryParams.get("page");
      const dateAddedQueryParam = queryParams.get("filter_date_added");
      const dateModifiedQueryParam = queryParams.get("filter_date_modified");
      const parentQueryParam = queryParams.get("filter_parent_id");
      const isparentQueryParam = queryParams.get("filter_is_parent");
      const limitQueryParam = queryParams.get("limit");

      const apiUrl = buildLink("categories");

      const queryParamsArray = [];

      if (limitQueryParam)
        queryParamsArray.push(`limit=${limitQueryParam || 10}`);
      if (pageQueryParam) queryParamsArray.push(`page=${pageQueryParam}`);
      if (parentQueryParam)
        queryParamsArray.push(`filter_parent_id=${parentQueryParam}`);
      if (isparentQueryParam) queryParamsArray.push(`filter_is_parent=1`);
      if (dateAddedQueryParam)
        queryParamsArray.push(`filter_date_added=${dateAddedQueryParam}`);
      if (dateModifiedQueryParam)
        queryParamsArray.push(`filter_date_modified=${dateModifiedQueryParam}`);

      const apiUrlWithParams = `${apiUrl}?${
        limitQueryParam == null && pageQueryParam
          ? "limit=10&hasLimit=1&"
          : `${limitQueryParam == null ? "limit=10&hasLimit=1" : "hasLimit=1&"}`
      }${queryParamsArray.join("&")}`;
      
      // apiUrl += `?${
      //   limitQueryParam == null && pageQueryParam
      //     ? "limit=10&"
      //     : `${limitQueryParam == null ? "limit=10" : ""}`
      // }${queryParamsArray.join("&")}`;

      // console.log(limitQueryParam, "limit");
      // apiUrl += `?${
      //   limitQueryParam == null ? "limit=10&" : ""
      // }${queryParamsArray.join("&")}`;

      // if (pageQueryParam) {
        const response = await axios.get(apiUrlWithParams, {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });

        if (response.status === 200) {
          setCategories(response.data.data.categories);
          setPageCount(response.data.data.pages);
          const allCategories = response.data.data.categories;

          const parentCategories = allCategories?.filter(
            (category) => category.parent_id === 0
          );
          setParentCategoies(parentCategories);
        } else {
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading
          );
        }
      // }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [
    queryParams?.get("page"),
    queryParams?.get("limit"),
    queryParams?.get("filter_date_added"),
    queryParams?.get("filter_date_modified"),
    queryParams?.get("filter_parent_id"),
    queryParams?.get("filter_is_parent"),
  ]);

  // console.log("pageCount", pageCount);

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    // queryParams.set("page", 1);

    // const newUrl = `${location.pathname}?${queryParams.toString()}`;
    // history.replace(decodeURIComponent(newUrl));
  }, []);

  const deleteCategory = async (category) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("category") + `?category_id=${category.category_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      // console.log(response.data.success, "dsads");

      if (response.status === 200) {
        setLoading(false);
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 1200);
        const filteredCategories = categories?.filter(
          (cat) => cat.category_id !== category.category_id
        );
        setCategories(filteredCategories);
        // console.log(response);
      } else {
        console.error("Error:", response);
        // setMssg(true)
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      // setMssg(true)
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (category) => {
    history.push(`/addcategory?category_id=${category.category_id}`);
  };
  // filter_date_added=2024-01-06

  const handlePageChange = (selected) => {
    const queryParams = new URLSearchParams(location.search);
    setSelectedPage(selected.selected);
    queryParams.set("page", selected.selected + 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  const handleDeleteClick = (e, category) => {
    e.stopPropagation();
    setCategoryToDelete(category);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (categoryToDelete) {
      await deleteCategory(categoryToDelete);
    }

    setShowDeleteConfirmation(false);
    setCategoryToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setCategoryToDelete(null);
  };

  const handleParentChange = (e) => {
    const parent = e.target.value;
    setParent(parent);
    queryParams.set("page", 1);

    if (
      queryParams.get("filter_parent_id") &&
      queryParams.get("filter_is_parent")
    ) {
      queryParams.delete("filter_parent_id");
      queryParams.delete("filter_is_parent");
    } else if (
      queryParams.get("filter_parent_id") ||
      queryParams.get("filter_is_parent")
    ) {
      queryParams.delete("filter_parent_id");
      queryParams.delete("filter_is_parent");
    }
    handleFilterChange(
      queryParams,
      location,
      history,
      parent !== "0" ? "parent_id" : "is_parent",
      parent
    );
  };

  const filterDateAdded = async (e) => {
    const dateAdded = e;

    setDateAdded(dateAdded);
    handleFilterChange(queryParams, location, history, "date_added", dateAdded);
  };

  const filterDateModified = async (e) => {
    const dateModified = e;

    setDateModified(dateModified);
    handleFilterChange(
      queryParams,
      location,
      history,
      "date_modified",
      dateModified
    );
  };
  const filterOrderBylimit = (limit) => {
    // const limit = e.target.value;
    setLimit(limit);
    handleFilterChange(queryParams, location, history, "limit", limit, true);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            // <div className="">
            // <div className="card mb-4 shadow-sm">
            // {/* <header
            //   className="card-header text-white p-3"
            //   style={{ backgroundColor: "#4fa607" }}
            // >
            //   <div className="d-flex justify-content-between align-items-center">
            //     <div style={{ width: "250px" }}>
            //       <select
            //         className="form-select mt-"
            //         onChange={handleParentChange}
            //         value={parent}
            //       >
            //         <option value={""}>All</option>
            //         <option value={"0"}>Parent Categories</option>
            //         {parentCategories &&
            //           parentCategories?.map((cat) => (
            //             <option key={cat.category_id} value={cat.category_id}>
            //               {cat.name}
            //             </option>
            //           ))}
            //       </select>
            //     </div>
            //      <div className="text-white">
            //       <div className="container">
            //         <div className="row align-items-center">
            //           <div className="col-auto">
            //             <label
            //               htmlFor="dateAdded"
            //               className="me-2 mb-0 d-block"
            //             >
            //               Date Added:
            //             </label>
            //             <input
            //               type="date"
            //               id="dateAdded"
            //               value={dateAdded}
            //               onChange={(e) => filterDateAdded(e.target.value)}
            //               style={{ width: "150px" }}
            //             />
            //           </div>
            //           <div className="col-auto">
            //             <label
            //               htmlFor="dateModified"
            //               className="me-2 mb-0 d-block"
            //             >
            //               Date Modified:
            //             </label>
            //             <input
            //               type="date"
            //               id="dateModified"
            //               value={dateModified}
            //               onChange={(e) => filterDateModified(e.target.value)}
            //               style={{ width: "150px" }}
            //             />
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </header> */}

            <>
              <div className="table-responsive">
                <>
                  <table className="table tabletd">
                    <thead>
                      <tr>
                        <th style={{ width: "3rem" }}>ID</th>
                        {/* <th>Image</th> */}
                        <th style={{ width: "17rem" }}>Name</th>
                        {/* <th>Description</th> */}
                        <th style={{ width: "17rem" }}>Parent Category</th>
                        <th style={{ width: "6.8rem" }}>Products Count</th>
                        {/* <th style={{ width: "6.8rem" }}>Date Modified</th> */}
                        <th style={{ width: "9rem" }}>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories?.map((category) => (
                        <tr
                          key={`category_${category.category_id}`}
                          className="cursorpointer"
                          onClick={() => handleEdit(category)}
                        >
                          <td className="text-center">
                            <div className="flex ">
                              <div style={{ width: "3rem" }}>
                                {category.category_id
                                  .toString()
                                  .padStart(4, "0")}
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <img src={category.image} alt={category.name} />
                          </td> */}
                          <td className="text-center">
                            <div className="flex ">
                              <div
                                className="text_overflow"
                                style={{
                                  width: "17rem",
                                }}
                              >
                                {category.name || "-"}
                              </div>
                            </div>
                          </td>
                          {/* <td>{category.description || "-"}</td> */}
                          <td className="text-center">
                            <div className="flex ">
                              <div
                                className="text_overflow"
                                style={{
                                  width: "17rem",
                                }}
                              >
                                {category.parent_name || "-"}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "6.8rem" }}>
                                {category?.products_count}
                              </div>
                            </div>
                          </td>
                          {/* <td className="text-center">
                            <div className="flex ">
                              <div style={{ width: "6.8rem" }}>
                                {category?.date_modified.split(" ")[0]} <br />
                                {category?.date_modified.split(" ")[1]}
                              </div>
                            </div>
                          </td> */}
                          <td
                            className="text-center cursorhand"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div className="flex ">
                              {/* <button
                                className="action-btn edit-btn"
                                onClick={() => handleEdit(category)}
                              >
                                <i className="far fa-pen"></i>
                              </button>
                              | */}
                              <button
                                className="action-btn delete-btn"
                                onClick={(e) => handleDeleteClick(e, category)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>{" "}
                <div className="flex-container">
                  <div className="pagination-container">
                    <ReactPaginate
                      pageCount={pageCount}
                      forcePage={initialPageNumber}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      breakClassName={`...`}
                      activeClassName="active-page"
                      previousLabel="< "
                      nextLabel=" >"
                      pageRangeDisplayed={3}
                      hrefAllControls={true}
                    />
                  </div>
                  <div className="">
                    <div className="limit-container">
                      <div
                        className={`box-limit ${limit == 10 ? "selected" : ""}`}
                        onClick={() => filterOrderBylimit("10")}
                      >
                        10
                      </div>
                      <div
                        className={`box-limit ${limit == 20 ? "selected" : ""}`}
                        onClick={() => filterOrderBylimit("20")}
                      >
                        20
                      </div>
                      <div
                        className={`box-limit ${limit == 30 ? "selected" : ""}`}
                        onClick={() => filterOrderBylimit("30")}
                      >
                        30
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showSnackbar && (
                <Snackbar message={snackbarMessage} style={snackbarColor} />
              )}
              {showDeleteConfirmation && (
                <DeleteConfirmationDialog
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  name={"category"}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CategoriesTable;
