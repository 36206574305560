import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import MainOrderStatus from "../components/order status/MainOrderStatus";

const OrderStatusScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainOrderStatus />
      </main>
    </>
  );
};

export default OrderStatusScreen;
