import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import buildLink from "../../urls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { headers } from "../../urls";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";
// import { login } from "../../Redux/Actions/userActions";
const AddUserMain = () => {
  const [userData, setUserData] = useState([]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams?.get("user_id");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("user") + `?user_id=${userId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.status === 200) {
        // console.log(response.data.data);
        setUserData(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPermissions = async () => {
    // console.log(buildLink("permissions"));
    try {
      setLoading(true);
      const response = await axios.get(buildLink("permissions"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        // console.log(response.data.data);
        setPermissions(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);
  useEffect(() => {
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (userData) {
      setUsername(userData?.username || "");
      setPassword(userData?.password || "");
      setPermissions(userData?.user_permission || []);
      setSelectedPermissions(
        userData?.user_permission
          ?.filter((permission) => permission.checked === true)
          .map((permission) => permission.permission_id)
      );

      // console.log(selectedPermissions, "selected");
    }
    // {
    //   value: permission.permission_id,
    //   label: permission.name,
    // }
  }, [userData]);

  // const handlePermissionChange = (selectedOptions) => {
  //   setSelectedPermissions(selectedOptions);
  // };
  const handlePermissionChange = (permissionId) => {
    setSelectedPermissions((prevPermissions) => {
      if (!prevPermissions) {
        return [permissionId];
      }
      if (prevPermissions?.includes(permissionId)) {
        return prevPermissions?.filter((id) => id !== permissionId);
      } else {
        return [...prevPermissions, permissionId];
      }
    });
  };

  const addUser = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const requestData = {
      username: username,
      password: password,
      permissions: selectedPermissions,
    };
    if (
      username !== "" &&
      (!userId ? password !== "" : true) &&
      selectedPermissions?.length > 0
    ) {
      try {
        setLoadingCreate(true);

        const apiUrl = userId
          ? buildLink("user") + `?user_id=${userId}`
          : buildLink("user");

        const response = await axios({
          method: userId ? "put" : "post",
          url: apiUrl,
          data: requestData,
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });

        if (response.status === 200 || response.status === 201) {
          setSubmitting(false);
          const action = userId ? "updated" : "added";
          setSnackbarMessage(response.data.data.message);
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          setShowSnackbar(true);
          setLoadingCreate(false);

          setTimeout(() => {
            setShowSnackbar(false);
            history.goBack();
          }, 1500);
        } else {
          console.error("Error:", response);
          handleError(
            response,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            setLoading,
            setLoadingCreate
          );
        }
      } catch (error) {
        handleError(
          error,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading,
          setLoadingCreate
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={addUser}>
          <div className="flex-container col-xl-8 col-lg-8">
            <div>
              <div
                onClick={() => history.goBack()}
                className="btn btn-danger text-white"
              >
                Back
              </div>
            </div>
            <div className="col-md-4 text-center" style={{ width: "20rem" }}>
              <h2 className="content-title">{userId ? "Edit" : "Add"} User</h2>
            </div>
            <div className="save-btn" onClick={addUser} type="submit">
              <div className="btn btnhoverdark text-white">
                {loadingCreate ? (
                  <ButtonLoader />
                ) : (
                  <>{userId ? "Update" : "Add"}</>
                )}
              </div>
            </div>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="row mb-4 ">
                <div className="col-xl-8 col-lg-8">
                  <div className="card mb-4 shadow-sm">
                    <div className="card-body">
                      <div className="mb-4 row">
                        <div className="col-md-6 ">
                          <label htmlFor="product_title" className="form-label">
                            Username
                          </label>
                          {submitting &&
                          (username == "" || username == null) ? (
                            <span className="required-content"></span>
                          ) : null}
                          <input
                            type="text"
                            placeholder="Type here"
                            className={
                              submitting && (username == "" || username == null)
                                ? "required-field form-control"
                                : "form-control"
                            }
                            id="product_title"
                            maxLength={20}
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                        {/* <div className="mb-4 row"> */}
                        {!userId && (
                          <div className="col-md-6">
                            <label
                              htmlFor="arabic_title"
                              className="form-label"
                            >
                              Password
                            </label>
                            {submitting &&
                            (password == "" || password == null) ? (
                              <span className="required-content"></span>
                            ) : null}
                            <input
                              type="text"
                              placeholder="Type here"
                              className={
                                submitting &&
                                (password == "" || password == null)
                                  ? "required-field form-control"
                                  : "form-control"
                              }
                              id="arabic_title"
                              maxLength={20}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                      {/* {
                        <div className="col-md-6">
                          <label htmlFor="permissions" className="form-label">
                            Permissions
                          </label>
                          <div>
                            <Select
                              required
                              options={userData?.user_permission?.map(
                                (permission) => ({
                                  value: permission.permission_id,
                                  label: permission.name,
                                })
                              )}
                              isMulti
                              value={selectedPermissions}
                              onChange={handlePermissionChange}
                            />
                          </div>
                        </div>
                      } */}
                      <div className="col-md-15">
                        <label htmlFor="permissions" className="form-label">
                          Permissions
                        </label>
                        {submitting &&
                        (selectedPermissions?.length == 0 ||
                          selectedPermissions == null) ? (
                          <span className="required-content"></span>
                        ) : null}
                        <div className="permissions-container">
                          {permissions?.map((permission) => (
                            <div
                              className="permission-item"
                              title={permission.name}
                              key={permission.permission_id}
                            >
                              <input
                                type="checkbox"
                                className="m"
                                id={permission.permission_id}
                                value={permission.permission_id}
                                checked={selectedPermissions?.includes(
                                  permission.permission_id
                                )} // Check if permission_id is included in selectedPermissions
                                onChange={() =>
                                  handlePermissionChange(
                                    permission.permission_id
                                  )
                                }
                              />
                              <label
                                htmlFor={permission.permission_id}
                                className="m-1"
                              >
                                {permission.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <div className="d-grid mt-3">
                        <button
                          type="submit"
                          className="btn btnhoverdark py-2"
                          disabled={loadingCreate}
                        >
                          {loadingCreate ? (
                            <ButtonLoader />
                          ) : (
                            <>{userId ? "Update user" : "Add user"}</>
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default AddUserMain;
