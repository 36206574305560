import React from "react";
import Navbar from "../components/home/Navbar";
import AboutUs from "../components/home/AboutUs";
import HomeSection from "../components/home/HomeSection";
import QualityAssured from "../components/home/QualityAssured";
import Footer2 from "../components/home/Footer2";
import Ordering from "../components/home/Ordering";
import Delivery from "../components/home/Delivery";
import ContactUs from "../components/home/ContactUs";
import HowItWorks from "../components/home/Steps";
import BestSelling from "../components/home/BestSelling";
export default function Home() {
  return (
    <>
      <Navbar />
      <div className="container-width">
        <HomeSection />
        <AboutUs />
        <BestSelling />
        <QualityAssured />
        <Ordering />
        <HowItWorks />
        <Delivery />
        <ContactUs />
        <Footer2 />
      </div>
    </>
  );
}
