import React from "react";

const HowItWorks = () => {
  return (
    <div className="container-step bgcolor1">
      <header className="header-step">
        <h2 className="tripoli-title">How To Order</h2>
        <p className="tripoli-text">
          Follow these simple steps to place your order on our application.
        </p>
      </header>
      <div className="steps">
        <div className="line" />
        <div className="step">
          <span>1</span>
        </div>
        <div className="step">
          <span>2</span>
        </div>
        <div className="step">
          <span>3</span>
        </div>
        <div className="step">
          <span>4</span>
        </div>
        <div className="step">
          <span>5</span>
        </div>
        <div className="step">
          <span>6</span>
        </div>
      </div>
      <div className="cards-step">
        <Card
          icon={<LogInIcon />}
          step="STEP 1"
          title="Login"
          description="Focal persons in businesses such as chefs or purchasing managers log into the app."
        />
        <Card
          icon={<BoxSelectIcon />}
          step="STEP 2"
          title="Select Products"
          description="Browse our wide range of fruits and vegetables, see prices, and select the quantities you need."
        />
        <Card
          icon={<ShoppingBasketIcon />}
          step="STEP 3"
          title="Place Order"
          description="Submit your order with just a few clicks."
        />
        <Card
          icon={<ReviewIcon />}
          step="STEP 4"
          title="Review"
          description="The admin reviews the order, makes necessary adjustments if needed, and sends a revised request for approval."
        />
        <Card
          icon={<CheckIcon />}
          step="STEP 5"
          title="Approval"
          description="Approve the final order for processing."
        />
        <Card
          icon={<DeliveryIcon />}
          step="STEP 6"
          title="Delivery"
          description="Once approved, the order is sent to our delivery department for prompt and reliable delivery."
        />
      </div>
    </div>
  );
};

const Card = ({ icon, step, title, description }) => (
  <div className="card-step">
    <div className="card-header-step">
      <div className="icon-step">{icon}</div>
      <h3 className="card-title-step">{step}</h3>
      <p className="card-description-step">{title}</p>
    </div>
    <CardContent description={description} />
  </div>
);

const CardContent = ({ description }) => (
  <div className="card-content-step">
    <p>{description}</p>
  </div>
);

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12l2 2 4-4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const LogInIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polyline
      points="10 17 15 12 10 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="15"
      y1="12"
      x2="3"
      y2="12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BoxSelectIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 16V8a2 2 0 0 0-1.08-1.78l-7-3.5a2 2 0 0 0-1.84 0l-7 3.5A2 2 0 0 0 3 8v8a2 2 0 0 0 1.08 1.78l7 3.5a2 2 0 0 0 1.84 0l7-3.5A2 2 0 0 0 21 16z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polyline
      points="3.27 6.96 12 12.01 20.73 6.96"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="12"
      y1="22.08"
      x2="12"
      y2="12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ShoppingBasketIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" height="1.75em" width="1.75em">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 2a6 6 0 016 6v1h4v2h-1.167l-.757 9.083a1 1 0 01-.996.917H4.92a1 1 0 01-.996-.917L3.166 11H2V9h4V8a6 6 0 016-6zm6.826 9H5.173l.667 8h12.319l.667-8zM13 13v4h-2v-4h2zm-4 0v4H7v-4h2zm8 0v4h-2v-4h2zm-5-9a4 4 0 00-3.995 3.8L8 8v1h8V8a4 4 0 00-3.8-3.995L12 4z" />
  </svg>
);

const DeliveryIcon = () => (
  <svg
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    height="1.75em"
    width="1.75em"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M9 17 A2 2 0 0 1 7 19 A2 2 0 0 1 5 17 A2 2 0 0 1 9 17 z" />
    <path d="M19 17 A2 2 0 0 1 17 19 A2 2 0 0 1 15 17 A2 2 0 0 1 19 17 z" />
    <path d="M5 17H3v-4M2 5h11v12m-4 0h6m4 0h2v-6h-8m0-5h5l3 5M3 9h4" />
  </svg>
);
const ReviewIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" height="1.75em" width="1.75em">
    <path d="M12 20H5a1 1 0 01-1-1V5a1 1 0 011-1h5v3a3 3 0 003 3h3v1a1 1 0 002 0V9v-.06a1.31 1.31 0 00-.06-.27v-.09a1.07 1.07 0 00-.19-.28l-6-6a1.07 1.07 0 00-.28-.19.32.32 0 00-.09 0 .88.88 0 00-.33-.11H5a3 3 0 00-3 3v14a3 3 0 003 3h7a1 1 0 000-2zm0-14.59L14.59 8H13a1 1 0 01-1-1zM7 8a1 1 0 000 2h1a1 1 0 000-2zm14.71 12.29l-1.17-1.16A3.44 3.44 0 0020 15a3.49 3.49 0 00-6 2.49 3.46 3.46 0 005.13 3.05l1.16 1.17a1 1 0 001.42 0 1 1 0 000-1.42zm-3.17-1.75a1.54 1.54 0 01-2.11 0 1.5 1.5 0 01-.43-1.05 1.46 1.46 0 01.44-1.06 1.48 1.48 0 011-.43A1.47 1.47 0 0119 17.49a1.5 1.5 0 01-.46 1.05zM13 12H7a1 1 0 000 2h6a1 1 0 000-2zm-2 6a1 1 0 000-2H7a1 1 0 000 2z" />
  </svg>
);
export default HowItWorks;
