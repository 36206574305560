import React from "react";
import UnitsTable from "./UnitsTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MainUnits = () => {
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Units</h2>
        <div>
          <Link to="/addunit" className="btn btnhoverdark">
            Create new
          </Link>
        </div>
      </div>

      <div className="bg-white ">
        <div className="row">
          {/* Units table */}
          <UnitsTable />
        </div>
      </div>
    </section>
  );
};

export default MainUnits;
