import React from "react";
import Sidebar from "../components/sidebar";
import Header from "./../components/Header";
import AddUnitMain from "../components/units/AddUnit";

const AddUnit = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddUnitMain />
      </main>
    </>
  );
};

export default AddUnit;
