import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

const Header = () => {
  useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  const logoutHandler = async () => {
    try {
      await Cookies.remove("token");
      // console.log(Cookies.remove("token"));
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <header className="main-header ">
      {/* <div className="col-nav "> */}

      <button
        className="btn btn-icon btn-mobile me-auto"
        data-trigger="#offcanvas_aside"
      >
        <i className="md-28 fas fa-bars"></i>
      </button>
      {/* <div className=""></div> */}
      {/* <div className="content-header"> */}
      <div className="header-image ">
        <a href="/home">
          <img
            src="/images/fulllogo.png"
            alt="Tripoli Fresh Market"
            className="img-icon"
          />
        </a>
        {/* <img src="/images/name.png" alt="Tripoli Fresh Market" className="img-name"/> */}
      </div>

      {/* <h2 className="name content-title">Tripoli Fresh Market</h2> */}
      {/* </div> */}
      <div className="dropdown nav-item">
        <Link className="" data-bs-toggle="dropdown" to="#">
          <img
            // className="img-xs"
            src="/images/user.png"
            alt="User"
            width={30}
            height={30}
          />
          <span className=" custom-dropdown-arrow"></span>
        </Link>
        <div className="dropdown-menu dropdown-menu-end">
          <Link className="dropdown-item" to="/settings">
            Settings
          </Link>
          <Link
            onClick={logoutHandler}
            className="dropdown-item text-danger"
            to="/login"
          >
            Logout
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
