// export default function Delivery() {
//   return (
//     <section className="" id="delivery">
//       <div className="delivery">
//         {" "}
//         <h2 className="section-title">Delivery</h2>
//         <div className="about-grid">
//           <div>
//             {" "}
//             <h4 className="text-color">Tripoli Fresh Market Wholesale</h4>
//             <p className="delivery-info">
//               At Tripoli Fresh Market Wholesale, we understand the importance of
//               timely delivery in the wholesale produce business. Once your order
//               is approved, our dedicated delivery team ensures that your fresh
//               produce arrives at your business swiftly and in perfect condition.
//               We offer cash on delivery for your convenience, ensuring a smooth
//               and hassle-free transaction.
//             </p>
//             <h4 className="text-color">Our Delivery Commitment</h4>
//             <p className="delivery-info">
//               What sets us apart from other fruit and vegetable suppliers is our
//               unique ability to deliver at your convenience, ensuring that you
//               receive your order at the most suitable time of day.
//             </p>
//             <p className="delivery-info">
//               Our delivery service covers the greater Tripoli area, extending
//               from Kalamoun in the south to Menniye in the north. If you are
//               outside our current delivery zones and wish to receive our fresh
//               produce, please contact us to discuss potential arrangements.
//             </p>
//             <h4 className="text-color">Our Dedicated Team</h4>
//             <p className="delivery-info">
//               We know that the delivery of your fruits and vegetables is crucial
//               for your business. Our commitment is to ensure your complete
//               satisfaction with the condition and timing of your stock's
//               arrival. Our drivers are honest, hardworking, and well-trained,
//               striving to be punctual and efficient with every delivery.
//             </p>
//             <h4 className="text-color ">Contact Information</h4>
//             <p className="contact-info">
//               If for any reason you are not satisfied with our service, please
//               do not hesitate to contact our Managing Director, Ziad El Sayegh,
//               directly at{" "}
//               <a href="tel:+96171144332" className="link">
//                 +961 71 144 332
//               </a>
//               .
//             </p>
//           </div>
//           <div>

//           </div>
//           {/* <div className="delivery-img">
//             <img alt="delivery" src="/images/delivery2.png" />
//           </div> */}
//         </div>
//       </div>
//     </section>
//   );
// }
import React from "react";

export default function Delivery() {
  return (
    <section className="tripoli-section bg" id="delivery">
      <div className="">
        <h2 className="section-title">Delivery</h2>
        <div className="feature-grid">
          <div className="feature-item">
            <div className="feature-icon">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1em"
                width="1em"
                className="mt-2"
              >
                <path d="M.75 7.5h9.75l.75 1.5H1.5L.75 7.5m1 3h9.75l.75 1.5H2.5l-.75-1.5m16.25 8c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5m1.5-9H17V12h4.46L19.5 9.5M8 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3h-4c0 1.66-1.35 3-3 3-1.66 0-3-1.34-3-3H3v-3.5h2V15h.76c.55-.61 1.35-1 2.24-1 .89 0 1.69.39 2.24 1H15V6H3c0-1.11.89-2 2-2h12v4h3z" />
              </svg>
            </div>
            <h3 className="feature-title">Efficiency</h3>
            <p className="feature-description">
              At Tripoli Fresh Market Wholesale, we prioritize fast, reliable
              delivery and customer convenience. Once your order is approved,
              our team ensures your fresh produce arrives promptly and in
              perfect condition. Enjoy hassle-free cash on delivery and flexible
              delivery times that suit your schedule.
            </p>
          </div>
          <div className="feature-item">
            <div className="feature-icon">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1em"
                width="1em"
              >
                <path d="M11 11.9V17a1 1 0 002 0v-5.1a5 5 0 10-2 0zM12 4a3 3 0 11-3 3 3 3 0 013-3zm4.21 10.42a1 1 0 10-.42 2C18.06 16.87 19 17.68 19 18c0 .58-2.45 2-7 2s-7-1.42-7-2c0-.32.94-1.13 3.21-1.62a1 1 0 10-.42-2C4.75 15.08 3 16.39 3 18c0 2.63 4.53 4 9 4s9-1.37 9-4c0-1.61-1.75-2.92-4.79-3.58z" />
              </svg>
            </div>
            <h3 className="feature-title">Coverage</h3>
            <p className="feature-description">
              Our delivery service covers the greater Tripoli area, extending
              from Kalamoun in the south to Menniye in the north. If you are
              outside our current delivery zones and wish to receive our fresh
              produce, please contact us to discuss potential arrangements.
            </p>
          </div>
          <div className="feature-item">
            <div className="feature-icon">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1em"
                width="1em"
              >
                <path d="M21.71 8.71c1.25-1.25.68-2.71 0-3.42l-3-3c-1.26-1.25-2.71-.68-3.42 0L13.59 4H11C9.1 4 8 5 7.44 6.15L3 10.59v4l-.71.7c-1.25 1.26-.68 2.71 0 3.42l3 3c.54.54 1.12.74 1.67.74.71 0 1.36-.35 1.75-.74l2.7-2.71H15c1.7 0 2.56-1.06 2.87-2.1 1.13-.3 1.75-1.16 2-2C21.42 14.5 22 13.03 22 12V9h-.59l.3-.29M20 12c0 .45-.19 1-1 1h-1v1c0 .45-.19 1-1 1h-1v1c0 .45-.19 1-1 1h-4.41l-3.28 3.28c-.31.29-.49.12-.6.01l-2.99-2.98c-.29-.31-.12-.49-.01-.6L5 15.41v-4l2-2V11c0 1.21.8 3 3 3s3-1.79 3-3h7v1m.29-4.71L18.59 9H11v2c0 .45-.19 1-1 1s-1-.55-1-1V8c0-.46.17-2 2-2h3.41l2.28-2.28c.31-.29.49-.12.6-.01l2.99 2.98c.29.31.12.49.01.6z" />
              </svg>
            </div>
            <h3 className="feature-title">Commitment</h3>
            <p className="feature-description">
              We understand the importance of timely fruit and vegetable
              deliveries to your business. Our promise is to ensure your
              complete satisfaction with the condition and timing of your
              stock's arrival. Our drivers are honest, hardworking, and
              well-trained, dedicated to punctuality and efficiency with every
              delivery.
            </p>
          </div>
          {/* <div className="feature-item">
          <div className="feature-icon">
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm1.31-7.71L12 12v5h-2v-5l-1.31-1.71A.996.996 0 0110 10h4c.55 0 1 .45 1 1s-.45 1-1 1h-1v1.28z" />
            </svg>
          </div>
          <h3 className="feature-title">Contact Us</h3>
          <p className="feature-description">
            For any issues, contact our Managing Director, Ziad El Sayegh, at{" "}
            <a href="tel:+96171144332" className="link">
              +961 71 144 332
            </a>
            .
          </p>
        </div> */}
        </div>
      </div>
    </section>
  );
}
