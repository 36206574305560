import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import buildLink from "../../urls";
import Cookies from "js-cookie";
import Loading from "../LoadingError/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Snackbar from "../LoadingError/snackbar";
import { handleError } from "../../services/functions";

const MainSetting = () => {
  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [mssg, setMssg] = useState(false);
  const history = useHistory();

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await axios.get(buildLink("settings"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        // console.log(response, "response");
        setSettings(response.data.data);
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleEdit = async (setting) => {
    history.push(`/addsetting?setting_id=${setting.setting_id}`);
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Settings</h2>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            <>
              <div className=" mb-4">
                <table className="table tabletd">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Key</th>
                      <th>Value</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {settings?.map((setting) => (
                      <tr className="cursorpointer"
                        key={`setting${setting.setting_id}`}
                        onClick={() => handleEdit(setting)}
                      >
                        <td>
                          {setting.setting_id.toString().padStart(4, "0")}
                        </td>
                        <td>
                          <b>{setting.key}</b>
                        </td>
                        <td>{setting.value}</td>
                        {/* <td className="text-center">
                          <div className="dropdown">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              className="btn btn-light"
                            >
                              <i className="fas fa-ellipsis-h"></i>
                            </Link>
                            <div className="dropdown-menu">
                              <button
                                className="btn action-btn edit-btn"
                                onClick={() => handleEdit(setting)}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {showSnackbar && (
                <Snackbar message={snackbarMessage} style={snackbarColor} />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default MainSetting;
