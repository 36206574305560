import React from "react";
import Sidebar from "../components/sidebar";
import Header from "./../components/Header";
import AddSettingMain from "../components/settings/AddSetting";

const AddSetting = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddSettingMain />
      </main>
    </>
  );
};

export default AddSetting;
