/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loading from "../LoadingError/Loading";
import ReactPaginate from "react-paginate";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import Cookies from "js-cookie";
import buildLink from "../../urls";
import axios from "axios";
import { handleError, handleFilterChange } from "../../services/functions";
import Snackbar from "../LoadingError/snackbar";
import DeleteConfirmationDialog from "../dialogue";

const MainCustomers = () => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [pageCount, setPageCount] = useState();

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(queryParams.get("limit") || 10);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [companyName, setCompanyName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [dateAdded, setDateAdded] = useState();
  const [dateModified, setDateModified] = useState();
  // const [address, setAddress] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);

  const [editableHeader, setEditableHeader] = useState(null);
  const [mssg, setMssg] = useState(false);
  const initialPageNumber = queryParams.get("page")
    ? Number(queryParams.get("page")) - 1
    : 0;
  // console.log(address);
  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    // queryParams.set("page", 1);
    // // queryParams.set("limit", limit);
    // const newUrl = `${location.pathname}?${queryParams.toString()}`;
    // history.replace(decodeURIComponent(newUrl));
  }, []);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const companyNameQueryParam = queryParams.get("filter_company_name");
      const limitQueryParam = queryParams.get("limit");
      const pageQueryParam = queryParams.get("page");
      const customerIdQueryParam = queryParams.get("filter_customer_id");
      const customerNameQueryParam = queryParams.get("filter_name");
      const dateAddedQueryParam = queryParams.get("filter_date_added");
      const dateModifiedQueryParam = queryParams.get("filter_date_modified");
      const phoneNumberQueryParam = queryParams.get("filter_telephone");
      const emailQueryParam = queryParams.get("filter_email");

      let apiUrl = buildLink("customers");

      const queryParamsArray = [];

      if (companyNameQueryParam)
        queryParamsArray.push(`filter_company_name=${companyNameQueryParam}`);
      if (limitQueryParam)
        queryParamsArray.push(`limit=${limitQueryParam || 10}`);
      if (pageQueryParam) queryParamsArray.push(`page=${pageQueryParam}`);
      if (customerIdQueryParam)
        queryParamsArray.push(`filter_customer_id=${customerIdQueryParam}`);
      if (customerNameQueryParam)
        queryParamsArray.push(`filter_name=${customerNameQueryParam}`);
      if (dateAddedQueryParam)
        queryParamsArray.push(`filter_date_added=${dateAddedQueryParam}`);
      if (dateModifiedQueryParam)
        queryParamsArray.push(`filter_date_modified=${dateModifiedQueryParam}`);
      if (phoneNumberQueryParam)
        queryParamsArray.push(`filter_telephone=${phoneNumberQueryParam}`);
      if (emailQueryParam)
        queryParamsArray.push(`filter_email=${emailQueryParam}`);
      // if (!limitQueryParam && pageQueryParam) {
      // apiUrl += `?limit=10&${queryParamsArray.join("&")}`;
      // } else
      // console.log(limitQueryParam, "limitQueryParam");

      apiUrl += `?${
        limitQueryParam == null && pageQueryParam
          ? "limit=10&"
          : `${limitQueryParam == null ? "limit=10" : ""}`
      }${queryParamsArray.join("&")}`;

      // if (pageQueryParam) {
      const response = await axios.get(apiUrl, {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      if (response.status === 200) {
        // if (response.data.data.pages === 1) {
        //   setPageCount(1);
        // } else if (response.data.data.pages === 2) {
        //   setPageCount(2);
        // }
        setPageCount(response.data.data.pages);
        // console.log("page count", pageCount);
        // console.log("response.data.data.pages", response.data.data.pages);
        // if (typeof pageCount == "undefined") {
        setLoading(true);
        setPageCount(response.data.data.pages);
        // console.log("here");
        // window.location.reload();
        setPageCount(response.data.data.pages);
        // } else {
        // setPageCount(response.data.data.pages);
        setCustomers(response.data.data.customers);
        // }
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
      // }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (selected) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", selected.selected + 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  useEffect(() => {
    fetchCustomers();
  }, [
    queryParams.get("page"),
    queryParams.get("filter_company_name"),
    queryParams.get("limit"),
    queryParams.get("filter_customer_id"),
    queryParams.get("filter_name"),
    queryParams.get("filter_date_added"),
    queryParams.get("filter_date_modified"),
    queryParams.get("filter_telephone"),
    queryParams.get("filter_email"),
  ]);

  const filterCustomerByLimit = (limit) => {
    // const limit = e.target.value;
    setLimit(limit);
    handleFilterChange(queryParams, location, history, "limit", limit, true);
  };

  const handleHeaderClick = (fieldName) => {
    setEditableHeader(fieldName);
  };
  const handleKeyPress = (e, fieldName) => {
    if (e.key === "Enter") {
      handleFilterChange(
        queryParams,
        location,
        history,
        fieldName,
        e.target.value
      );
    }
  };

  const handleCustomerId = (e) => {
    // console.log("here id");
    handleKeyPress(e, "customer_id");
  };
  const handleCompanyName = (e) => {
    handleKeyPress(e, "company_name");
  };
  const handleCustomerName = (e) => {
    handleKeyPress(e, "name");
  };

  const handleDateAdded = (e) => {
    handleKeyPress(e, "date_added");
  };

  const handleDateModified = (e) => {
    handleKeyPress(e, "date_modified");
  };

  const handlePhoneNumber = (e) => {
    handleKeyPress(e, "telephone");
  };

  const handleEmail = (e) => {
    handleKeyPress(e, "email");
  };

  const handleOutsideClick = (e) => {
    if (
      editableHeader &&
      !e.target.closest(".table th") &&
      e.target.tagName !== "INPUT"
    ) {
      setEditableHeader(null);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [editableHeader]);

  const clearFilters = () => {
    setCustomerId("");
    setCustomerName("");
    setDateAdded("");
    setDateModified("");
    setEmail("");
    setCompanyName("");
    setPhoneNumber("");
    setLimit("10");

    queryParams.delete("limit");
    queryParams.delete("filter_customer_id");
    queryParams.delete("filter_company_name");
    queryParams.delete("filter_name");
    queryParams.delete("filter_date_added");
    queryParams.delete("filter_date_modified");
    queryParams.delete("filter_email");
    queryParams.delete("filter_telephone");
    queryParams.set("page", 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  const handleEdit = async (customer) => {
    history.push(`/addcustomer?customer_id=${customer.customer_id}`);
  };

  const deleteCustomer = async (customer) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("customer") + `?customer_id=${customer.customer_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      // console.log(response.data.success, "dsads");
      if (response.status === 200) {
        setLoading(false);
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 1200);

        const filteredCustomers = customers.filter(
          (cus) => cus.customer_id !== customer.customer_id
        );
        setCustomers(filteredCustomers);
        // console.log(response);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteClick = (e, customer) => {
    e.stopPropagation();
    setCustomerToDelete(customer);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (customerToDelete) {
      await deleteCustomer(customerToDelete);
    }

    setShowDeleteConfirmation(false);
    setCustomerToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setCustomerToDelete(null);
  };
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Customers</h2>
        <div className="d-flex">
          <div className="clear-filter-btn me-2">
            <button onClick={clearFilters}>Clear Filters</button>
          </div>
          <div>
            <Link to="/addcustomer" className="btn btnhoverdark">
              Create new
            </Link>
          </div>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            <>
              {/* <div className="card mb-4 shadow-sm"> */}
              {/* <header
                  className="card-header d-flex justify-content-between align-items-center"
                  style={{ backgroundColor: "#4fa607" }}
                >
                  <div
                    className="ms-auto cursor-pointer"
                    style={{ width: "7rem" }}
                  >
                    <select
                      className="form-select cursor-pointer"
                      onChange={filterCustomerByLimit}
                      value={limit}
                    >
                      <option value={"10"}>Show 10</option>
                      <option value={"20"}>Show 20</option>
                      <option value={"30"}>Show 30</option>
                    </select>
                  </div>
                </header> */}
              {/* <div className="card-body"> */}
              <div className="table-responsive">
                <>
                  <table className="table tabletd">
                    <thead>
                      <tr className="cursorpointer">
                        <th
                          scope="col"
                          onClick={() => handleHeaderClick("ID")}
                          style={{ width: "3rem" }}
                        >
                          {editableHeader === "ID" ? (
                            <input
                              type="text"
                              value={customerId}
                              placeholder="ID"
                              onChange={(e) => setCustomerId(e.target.value)}
                              onKeyPress={(e) => handleCustomerId(e)}
                              style={{ width: "3rem" }}
                            />
                          ) : (
                            "ID"
                          )}
                        </th>
                        <th
                          scope="col"
                          onClick={() => handleHeaderClick("Company name")}
                          style={{ width: "14rem" }}
                        >
                          {editableHeader === "Company name" ? (
                            <input
                              type="text"
                              value={companyName}
                              placeholder="Company name"
                              onChange={(e) => setCompanyName(e.target.value)}
                              onKeyPress={(e) => handleCompanyName(e)}
                              style={{ width: "13rem" }}
                            />
                          ) : (
                            "Company name"
                          )}
                        </th>
                        <th
                          scope="col"
                          onClick={() => handleHeaderClick("Phone number")}
                          style={{ width: "10rem" }}
                        >
                          {editableHeader === "Phone number" ? (
                            <input
                              type="text"
                              value={phoneNumber}
                              placeholder="+961777666"
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              onKeyPress={(e) => handlePhoneNumber(e)}
                              style={{ width: "8rem" }}
                            />
                          ) : (
                            "Phone number"
                          )}
                        </th>
                        <th
                          scope="col"
                          onClick={() => handleHeaderClick("Email")}
                          style={{ width: "12rem" }}
                        >
                          {editableHeader === "Email" ? (
                            <input
                              type="text"
                              value={email}
                              placeholder="johndoe@gmail.com"
                              onChange={(e) => setEmail(e.target.value)}
                              onKeyPress={(e) => handleEmail(e)}
                              style={{ width: "9rem" }}
                            />
                          ) : (
                            "Email"
                          )}
                        </th>
                        <th className="cursordefault" style={{ width: "6rem" }}>
                          Delivery fees
                        </th>
                        <th style={{ width: "5rem" }}>Delete</th>
                      </tr>
                    </thead>
                    <tbody className="cursor-pointer">
                      {customers?.map((customer) => (
                        <tr
                          key={customer?.customer_id}
                          className="order-row cursorpointer"
                          onClick={() => handleEdit(customer)}
                        >
                          <td className="text-center">
                            <div className="flex ">
                              <div style={{ width: "3rem" }}>
                                {customer?.customer_id
                                  .toString()
                                  .padStart(4, "0")}
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <div
                              className="flex"
                              // style={{
                              //   maxWidth: "10rem",
                              //   overflow: "hidden",
                              // }}
                            >
                              <div
                                className="text_overflow"
                                style={{
                                  maxWidth: "14rem",
                                }}
                              >
                                {customer?.company_name}
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="flex ">
                              <div style={{ width: "10rem" }}>
                                {customer.telephone}
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="flex ">
                              <div
                                className="text_overflow"
                                style={{
                                  width: "12rem",
                                }}
                              >
                                {customer?.email}
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="flex ">
                              <div style={{ width: "6rem" }}>
                                {customer?.delivery_fees}
                              </div>
                            </div>
                          </td>
                          <td
                            className="text-center cursorhand"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div className="flex ">
                              {/* <button
                                className="action-btn edit-btn"
                                onClick={() => handleEdit(customer)}
                              >
                                <i className="far fa-pen"></i>
                              </button>
                              | */}
                              <button
                                className="action-btn delete-btn"
                                onClick={(e) => handleDeleteClick(e, customer)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
                <div className="flex-container">
                  <div className="pagination-container">
                    <ReactPaginate
                      pageCount={pageCount}
                      forcePage={initialPageNumber}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      breakClassName={`...`}
                      activeClassName="active-page"
                      previousLabel="< "
                      nextLabel=" >"
                      pageRangeDisplayed={3}
                      hrefAllControls={true}
                    />
                  </div>
                  <div className="">
                    {/* <div>Show</div> */}
                    <div className="limit-container">
                      <div
                        className={`box-limit ${limit == 10 ? "selected" : ""}`}
                        onClick={() => filterCustomerByLimit("10")}
                      >
                        10
                      </div>
                      <div
                        className={`box-limit ${limit == 20 ? "selected" : ""}`}
                        onClick={() => filterCustomerByLimit("20")}
                      >
                        20
                      </div>
                      <div
                        className={`box-limit ${limit == 30 ? "selected" : ""}`}
                        onClick={() => filterCustomerByLimit("30")}
                      >
                        30
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showSnackbar && (
                <Snackbar message={snackbarMessage} style={snackbarColor} />
              )}
              {showDeleteConfirmation && (
                <DeleteConfirmationDialog
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  name={"customer"}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default MainCustomers;
