import React from "react";

const DeleteConfirmationDialog = ({ onConfirm, onCancel, name }) => {
  return (
    <div className="overlay">
      <div className="delete-confirmation-dialog">
        <p>Are you sure you want to delete this {name}?</p>
        <button className="confirm" onClick={onConfirm}>
          Yes
        </button>
        <button className="cancel" onClick={onCancel}>
          No
        </button>
      </div>
    </div>
  );
};

export default DeleteConfirmationDialog;
