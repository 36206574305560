import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import buildLink from "../../urls";
import { useHistory } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import DeleteConfirmationDialog from "../dialogue";
import Cookies from "js-cookie";
import { handleError } from "../../services/functions";
const OrderStatusTable = () => {
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [loading, setLoading] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [orderStatusToDelete, setOrderStatusToDelete] = useState(null);
  const [mssg, setMssg] = useState(false);
  const history = useHistory();

  const fetchOrderStatuses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(buildLink("order_statuses"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        // console.log(response, "response");
        setOrderStatuses(response.data.data);
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log();
    fetchOrderStatuses();
  }, []);

  const deleteOrderStatus = async (orderStatus) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("order_status") +
          `?order_status_id=${orderStatus.order_status_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      // console.log(response.data.success, "dsads");

      if (response.status === 200) {
        setLoading(false);
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 1200);
        const filteredOrderStatuses = orderStatuses.filter(
          (os) => os.order_status_id !== orderStatus.order_status_id
        );
        setOrderStatuses(filteredOrderStatuses);
        // console.log(response);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (orderStatus) => {
    history.push(
      `/addorderstatus?order_status_id=${orderStatus.order_status_id}`
    );
  };

  const handleDeleteClick = (e, orderStatus) => {
    e.stopPropagation();
    setOrderStatusToDelete(orderStatus);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (orderStatusToDelete) {
      await deleteOrderStatus(orderStatusToDelete);
    }

    setShowDeleteConfirmation(false);
    setOrderStatusToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setOrderStatusToDelete(null);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            <div className="table-responsive">
              <table className="table tabletd">
                <thead>
                  <tr>
                    <th style={{ width: "3rem" }}>ID</th>
                    <th style={{ width: "19rem" }}>Name</th>
                    <th style={{ width: "19rem" }}>Description</th>
                    {/* <th style={{ width: "6.8rem" }}>Date Added</th>
                    <th style={{ width: "6.8rem" }}>Date Modified</th> */}
                    <th style={{ width: "9rem" }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {orderStatuses?.map((orderStatus) => (
                    <tr
                      key={`order_status_${orderStatus.order_status_id}`}
                      className="cursorpointer"
                      onClick={() => handleEdit(orderStatus)}
                    >
                      <td className="text-center">
                        <div className="flex ">
                          <div style={{ width: "3rem" }}>
                            {orderStatus.order_status_id
                              .toString()
                              .padStart(4, "0")}
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="flex ">
                          <div
                            className="text_overflow"
                            style={{
                              width: "19rem",
                            }}
                          >
                            {orderStatus.name}
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="flex ">
                          <div
                            className="text_overflow"
                            style={{
                              width: "19rem",
                            }}
                          >
                            {orderStatus.description || "-"}
                          </div>
                        </div>
                      </td>
                      {/* <td>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div style={{ width: "6.8rem" }}>
                            {orderStatus?.date_added.split(" ")[0]}
                            <br />
                            {orderStatus?.date_added.split(" ")[1]}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div style={{ width: "6.8rem" }}>
                            {orderStatus?.date_modified.split(" ")[0]} <br />
                            {orderStatus?.date_modified.split(" ")[1]}
                          </div>
                        </div>
                      </td> */}
                      <td
                        className="text-center cursorhand"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="flex ">
                          {/* <button
                            className="action-btn edit-btn"
                            onClick={() => handleEdit(orderStatus)}
                          >
                            <i className="far fa-pen"></i>
                          </button>
                          | */}
                          <button
                            className="action-btn delete-btn"
                            onClick={(e) => handleDeleteClick(e, orderStatus)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showSnackbar && (
                <Snackbar message={snackbarMessage} style={snackbarColor} />
              )}
              {showDeleteConfirmation && (
                <DeleteConfirmationDialog
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  name={"order status"}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderStatusTable;
