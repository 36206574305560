import React from "react";

const OrderLog = (props) => {
  const { order } = props;
  return (
    <table className="table border table-lg tabletd">
      <thead>
        <tr>
          <th>Product</th>
          <th>Type</th>
          <th>Old Value</th>
          <th>New Value</th>
          <th>Username</th>
          <th>Date added</th>
        </tr>
      </thead>
      <tbody>
        {order?.order_log?.map((item, index) => (
          <tr key={index}>
            <td className="text-center">
              <div className="flex ">
                <div className="info text-overflow" style={{ width: "9rem" }}>
                  {item?.name || "-"}
                </div>
              </div>
            </td>
            <td className="text-center">
              <div className="flex ">
                <div style={{ width: "8rem" }}>{item?.type || "-"} </div>
              </div>
            </td>
            <td className="text-center">
              <div className="flex ">
                <div style={{ width: "8rem" }}>{item?.old_value || "-"} </div>
              </div>
            </td>
            <td className="text-center">
              <div className="flex ">
                <div style={{ width: "8rem" }}>{item?.new_value || "-"} </div>
              </div>
            </td>
            <td className="text-center">
              <div className="flex ">
                <div style={{ width: "8rem" }}>{item?.username || "-"}</div>
              </div>
            </td>
            <td className="text-center">
              <div className="flex ">
                <div style={{ width: "6.8rem" }}>
                  {item?.date_added.split(" ")[0]}
                  <br />
                  {item?.date_added.split(" ")[1]}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OrderLog;
