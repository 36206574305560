import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { AccountContext } from "../contexts/accountContext";
import buildLink from "../urls";
import Snackbar from "../components/LoadingError/snackbar";
import ButtonLoader from "../components/LoadingError/buttonLoader";
import { useHistory } from "react-router-dom";
import { handleError } from "../services/functions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../components/home/Navbar";
import Footer2 from "../components/home/Footer2";

const Login = ({ isLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const { dispatch } = useContext(AccountContext);
  const history = useHistory();
  const login = async () => {
    dispatch({ type: "setLoading", payload: true });
    const requestData = {
      username: username,
      password: password,
    };

    try {
      setLoading(true);
      const response = await axios.post(buildLink("admin_login"), requestData);
      if (response.status === 200) {
        const data = response.data.data;
        dispatch({ type: "setLoged", payload: true });
        dispatch({ type: "setAdmin", payload: true });
        dispatch({ type: "setAdminToken", payload: data.token });
        Cookies.set("token", data.token, { expires: 1 });
        history.push("/orders");
      } else {
        dispatch({ type: "setLoged", payload: false });
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      dispatch({ type: "setLoged", payload: true });
      dispatch({ type: "setAdmin", payload: true });
      dispatch({ type: "setAdminToken", payload: token });
    } else {
      dispatch({ type: "setLoged", payload: false });
      dispatch({ type: "setAdmin", payload: false });
    }
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    login();
  };
  useEffect(() => {
    // Check if user is logged in and redirect to "/orders"
    if (isLoggedIn) {
      history.push("/orders");
    }
  }, [isLoggedIn, history]);

  return (
    <>
      <Navbar />
      {/* <Navbar />{" "} */}
      {/* <div className=""> */}
      <div className="login-container">
        {/* <div className="loginimage">
          <LazyLoadImage
            // height={100}
            effect="blur"
            src="/images/fulllogo.png"
            alt="Tripoli Fresh Market"
            width={300}
          /> */}
        {/* <img
            src="/images/fulllogo.png"
            alt="Tripoli Fresh Market"
            width={300}
          /> */}
        {/* </div> */}
        <div className="card shadow mx-auto logincard">
          <div className="card-body">
            <h4 className="card-title mb-3 text-center">Admin Login</h4>
            <form onSubmit={submitHandler}>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Email or Username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-login w-100"
                >
                  {loading ? <ButtonLoader /> : <>Login</>}
                </button>
              </div>
            </form>
          </div>
          {showSnackbar && (
            <Snackbar message={snackbarMessage} style={snackbarColor} />
          )}
        </div>
      </div>
      {/* <Footer2 /> */}
      {/* </div> */}
    </>
  );
};

export default Login;
