import React from "react";
import Navbar from "../components/home/Navbar";
import Footer2 from "../components/home/Footer2";
import PrivacyPolicy from "../components/home/PrivacyPolicy";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Navbar />
      <main>
        <PrivacyPolicy />
      </main>
    </>
  );
};

export default PrivacyPolicyScreen;
