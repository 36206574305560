import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const images = [
  {
    id: 1,
    name: "Item One",
    image_url: "/images/Banner1.png",
  },
  {
    id: 2,
    name: "Item two",
    image_url: "/images/Banner2.png",
  },
  {
    id: 3,
    name: "Item three",
    image_url: "/images/Banner3.png",
  },
  {
    id: 4,
    name: "Item four",
    image_url: "/images/Banner4.png",
  },
];

export const NextArrow = ({ onClick }) => {
  return (
    <div className="next-arrow" onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-8 h-8"
      >
        <path
          d="M8 4L16 12L8 20"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const PrevArrow = ({ onClick }) => {
  return (
    <div className="prev-arrow" onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-8 h-8"
      >
        <path
          d="M16 4L8 12L16 20"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

function AutoplayCarousel() {
  const sliderRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState({});

  useEffect(() => {
    if (images && images.length > 0) {
      const newImageLoadedState = {};
      images?.forEach((image) => {
        console.log("image.id", image.id);
        newImageLoadedState[image.id] = false;
      });
      setImageLoaded(newImageLoadedState);
    }
  }, [images]);

  const handleImageLoad = (itemId) => {
    console.log("itemId", itemId);
    setImageLoaded((prevState) => ({
      ...prevState,
      [itemId]: true,
    }));
    console.log("imageLoaded", imageLoaded);
  };

  const settings = {
    draggable: true,
    className: "slider variable-width ",
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    ref: sliderRef,
    rows: 1,
    speed: 700,
    autoplaySpeed: 3500,
    autoplay: true,
    fade: true,
  };

  return (
    <div className="mx-auto top-carousel">
      <Slider ref={sliderRef} {...settings} className="carousel-div-autoplay ">
        {images.map((image) => (
          // <div className="bg-cover bg-center w-full h-full relative">
            <div key={image.id}>
            {!imageLoaded[image.id] && <div className="bgimage" />}
            <img
              alt={image.name}
              width="100%"
              height="100%"
              // effect="blur"
              className="bradius"
              src={image.image_url}
              onLoad={() => handleImageLoad(image.id)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AutoplayCarousel;
