/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import buildLink from "../../urls";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import DeleteConfirmationDialog from "../dialogue";
import Cookies from "js-cookie";
import { handleError, handleFilterChange } from "../../services/functions";

const MainProducts = () => {
  const [products, setProducts] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCat, setLoadingCat] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [dateAdded, setDateAdded] = useState("");
  const [dateModified, setDateModified] = useState("");
  const [mssg, setMssg] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pricelist, setPricelist] = useState(false);
  const [editIndices, setEditIndices] = useState({});
  const [editSpecialIndices, setEditSpecialIndices] = useState({});
  const [status, setStatus] = useState(1);
  const [loadingPriceUpdate, setLoadingPriceUpdate] = useState({
    state: false,
    productIndex: null,
    priceIndex: null,
  });
  const [loadingSpecialPriceUpdate, setLoadingSpecialPriceUpdate] = useState({
    state: false,
    productIndex: null,
    priceIndex: null,
  });
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState({
    state: false,
    productIndex: null,
  });

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const initialPageNumber = queryParams?.get("page")
    ? Number(queryParams?.get("page")) - 1
    : 0;
  // console.log(selectedPage);
  const [limit, setLimit] = useState(queryParams.get("limit") || 10);
  useEffect(() => {
    if (location.pathname.includes("/pricelist")) {
      setPricelist(true);
      // console.log("pricelist");
    }
  }, [location.pathname]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const limitQueryParam = queryParams?.get("limit");
      const categoryQueryParam = queryParams?.get("category_id");
      const pageQueryParam = queryParams?.get("page");
      const dateAddedQueryParam = queryParams?.get("filter_date_added");
      const dateModifiedQueryParam = queryParams?.get("filter_date_modified");
      const searchQueryParam = queryParams?.get("filter_name");

      let apiUrl = buildLink("products");

      const queryParamsArray = [];
      if (limitQueryParam) queryParamsArray.push(`limit=${limitQueryParam}`);
      if (categoryQueryParam)
        queryParamsArray.push(`filter_category_id=${categoryQueryParam}`);
      if (dateAddedQueryParam)
        queryParamsArray.push(`filter_date_added=${dateAddedQueryParam}`);
      if (dateModifiedQueryParam)
        queryParamsArray.push(`filter_date_modified=${dateModifiedQueryParam}`);
      if (searchQueryParam)
        queryParamsArray.push(`filter_name=${searchQueryParam}`);
      if (pageQueryParam) queryParamsArray.push(`page=${pageQueryParam}`);

      // console.log(limitQueryParam);

      apiUrl += `?${
        limitQueryParam == null && pageQueryParam
          ? "limit=10&"
          : `${limitQueryParam == null ? "limit=10" : ""}`
      }${queryParamsArray.join("&")}`;

      // if (pageQueryParam) {
      const response = await axios.get(apiUrl, {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      if (response.status === 200) {
        setProducts(response.data.data.products);
        setPageCount(response.data.data.pages);
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
      // }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = () => {
    setSearchQuery("");
    setDateAdded("");
    setDateModified("");
    setSelectedCategory(null);
    setLimit(10);
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("filter_name");
    queryParams.delete("limit");
    queryParams.delete("filter_date_added");
    queryParams.delete("filter_date_modified");
    queryParams.delete("category_id");
    queryParams.set("page", 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  const fetchCategories = async () => {
    try {
      setLoadingCat(true);
      const response = await axios.get(buildLink("getCategoryDropdown"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        setLoadingCat(false);
        setCategories(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoadingCat
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoadingCat
      );
    } finally {
      setLoadingCat(false);
    }
  };

  const deleteProduct = async (product) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("product") + `?product_id=${product.product_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.status === 200) {
        // console.log(response.data);
        setLoading(false);
        setSnackbarMessage(response.data.data.message);

        setShowSnackbar(true);

        setTimeout(() => {
          setShowSnackbar(false);
        }, 1500);

        if (response.data.data.message !== "Product deleted successfully.") {
          setSnackbarColor({ backgroundColor: "#FF0000" });
        } else {
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          const filteredProducts = products?.filter(
            (pro) => pro.product_id !== product.product_id
          );
          setProducts(filteredProducts);
        }
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (e, product) => {
    e.stopPropagation();
    setProductToDelete(product);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (productToDelete) {
      await deleteProduct(productToDelete);
    }

    setShowDeleteConfirmation(false);
    setProductToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setProductToDelete(null);
  };

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", selected.selected + 1);

    if (selectedCategory) {
      queryParams.set("category_id", selectedCategory);
    } else {
      queryParams.delete("category_id");
    }

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;

    setSelectedCategory(categoryId);

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", 1);

    if (categoryId) {
      queryParams.delete("page");
      queryParams.set("category_id", categoryId);
      queryParams.set("page", 1);
    } else {
      queryParams.delete("category_id");
    }

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  useEffect(() => {
    fetchProducts();
  }, [
    queryParams?.get("page"),
    queryParams?.get("limit"),
    queryParams?.get("category_id"),
    queryParams?.get("filter_date_added"),
    queryParams?.get("filter_date_modified"),
    queryParams?.get("filter_name"),
  ]);

  useEffect(() => {
    console.log("searchQuery", searchQuery);
    if (queryParams.get("filter_name")) {
      setSearchQuery(queryParams.get("filter_name"));
    }

    // if (searchQuery === "") {
    //   if (queryParams.get("filter_name")) {
    //     queryParams.delete("filter_name");
    //     setSearchQuery("");
    //   }
    //   handleFilterChange(queryParams, location, history, "name", "");
    // }
  }, []);

  const filterName = async (e) => {
    if (e.key === "Enter") {
      const searchQuery = e.target.value;
      setSearchQuery(searchQuery);
      handleFilterChange(queryParams, location, history, "name", searchQuery);
    }
  };

  const filterDateAdded = async (e) => {
    const dateAdded = e;
    setDateAdded(dateAdded);
    handleFilterChange(queryParams, location, history, "date_added", dateAdded);
  };

  const filterDateModified = async (e) => {
    const dateModified = e;
    setDateModified(dateModified);
    handleFilterChange(
      queryParams,
      location,
      history,
      "date_modified",
      dateModified
    );
  };

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    // queryParams.set("page", 1);
    // const newUrl = `${location.pathname}?${queryParams.toString()}`;
    // history.replace(decodeURIComponent(newUrl));
  }, []);

  useEffect(() => {
    // if (categories.length === 0) {
    fetchCategories();
    // }
  }, []);

  const updateProduct = async (
    e,
    productData,
    statuschange = false,
    productIndex,
    priceIndex,
    isSpecial = false
  ) => {
    let requestData = {
      prices_per_unit: productData.prices_per_unit,
    };

    const special_prices_per_unit = productData?.prices_per_unit
      .filter((price) => price?.special)
      .map((price) => ({ ...price, price: price.special }));

    requestData.special_prices_per_unit = special_prices_per_unit;
    requestData.is_quick_edit = true;
    requestData.status = productData.status;
    console.log(special_prices_per_unit);
    if (e.key === "Enter" || (statuschange == true && e.type === "change")) {
      console.log(e.key, e.type, statuschange);
      setEditIndices([]);
      setEditSpecialIndices([]);
      const hasNaNPrice = requestData.prices_per_unit.some((price) =>
        isNaN(price.price)
      );
      console.log(
        "requestData.prices_per_unit",
        requestData.prices_per_unit
      );

      const hasNaNSpecialPrice = special_prices_per_unit.some((price) =>
        isNaN(price.price)
      );
      console.log("hasNaNPrice", hasNaNPrice);
      console.log("special_prices_per_unit", special_prices_per_unit);

      if (hasNaNPrice || hasNaNSpecialPrice) {
        setSnackbarMessage("Product price or special price cannot be NaN.");
        setSnackbarColor({ backgroundColor: "#FF0000" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
        return;
      }

      if (!hasNaNPrice && !hasNaNSpecialPrice) {
        try {
          if (isSpecial) {
            setLoadingSpecialPriceUpdate({
              state: true,
              productIndex,
              priceIndex,
            });
          } else if (statuschange) {
            setLoadingStatusUpdate({ state: true, productIndex });
          } else {
            setLoadingPriceUpdate({ state: true, productIndex, priceIndex });
          }

          const response = await axios({
            method: "put",
            url: buildLink("product") + `?product_id=${productData.product_id}`,
            data: requestData,
            headers: {
              source: "2",
              Authorization: "Bearer " + Cookies.get("token"),
            },
          });

          if (response.status === 200) {
            if (isSpecial) {
              setLoadingSpecialPriceUpdate({
                state: false,
                productIndex,
                priceIndex,
              });
            } else if (statuschange) {
              setLoadingStatusUpdate({ state: false, productIndex });
            } else {
              setLoadingPriceUpdate({ state: false, productIndex, priceIndex });
            }
            setSnackbarMessage("Product updated successfully!");
            setSnackbarColor({ backgroundColor: "#4CAF50" });
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
            }, 1200);
          } else {
            console.error("Error:", response);
            handleError(
              response,
              setSnackbarMessage,
              setSnackbarColor,
              setShowSnackbar,
              isSpecial
                ? setLoadingSpecialPriceUpdate.state
                : setLoadingPriceUpdate.state
            );
          }
        } catch (error) {
          handleError(
            error,
            setSnackbarMessage,
            setSnackbarColor,
            setShowSnackbar,
            isSpecial
              ? setLoadingSpecialPriceUpdate.state
              : setLoadingPriceUpdate.state
          );
        } finally {
          if (isSpecial) {
            setLoadingSpecialPriceUpdate({
              state: false,
              productIndex,
              priceIndex,
            });
          } else if (statuschange) {
            setLoadingStatusUpdate({ state: false, productIndex });
          } else {
            setLoadingPriceUpdate({ state: false, productIndex, priceIndex });
          }
        }
      }
    }
  };

  const handlePriceChange = (
    e,
    productIndex,
    priceIndex,
    value,
    productData
  ) => {
    const updatedProducts = [...products];

    const floatValue = parseFloat(value);
    updatedProducts[productIndex].prices_per_unit[priceIndex].price =
      floatValue;

    setProducts(updatedProducts);

    updateProduct(e, productData, false, productIndex);
  };

  const handleSpecialPriceChange = (
    e,
    productIndex,
    priceIndex,
    value,
    productData
  ) => {
    const updatedProducts = [...products];
    const floatValue = parseFloat(value);

    // if (isNaN(floatValue) && e.key === "Enter") {
    // console.log("heelo");
    // return;
    // } else {
    updatedProducts[productIndex].prices_per_unit[priceIndex].special =
      floatValue;

    setProducts(updatedProducts);

    updateProduct(e, productData, false, productIndex, priceIndex);
    // }
  };

  const handlePriceClick = (productIndex, priceIndex) => {
    // console.log("productIndex", productIndex, "priceIndex", priceIndex);
    setEditIndices({ ...editIndices, [productIndex]: priceIndex });
  };

  const handleSpecialPriceClick = (productIndex, priceIndex) => {
    // console.log("productIndex", productIndex, "priceIndex", priceIndex);
    setEditSpecialIndices({
      ...editSpecialIndices,
      [productIndex]: priceIndex,
    });
  };

  const handleBlur = () => {
    setEditIndices({});
    setEditSpecialIndices({});
  };
  const handleEdit = async (product) => {
    history.push(`/addproduct?product_id=${product.product_id}`);
  };

  const handleStatusChange = (e, product) => {
    e.stopPropagation();
    const newStatus = parseInt(e.target.value);
    // console.log("status", newStatus);
    const productIndex = products.findIndex(
      (p) => p.product_id === product.product_id
    );
    const updatedProducts = [...products];
    updatedProducts[productIndex].status = newStatus;
    // console.log("updatedProducts", updatedProducts[productIndex]);
    setProducts(updatedProducts);
    updateProduct(e, updatedProducts[productIndex], true, productIndex);
  };

  // const [selectedCategories, setSelectedCategories] = useState([]);
  // selectedCategories.forEach((category, index) => {
  //   // requestData[`categories[${index}]`] = category.value;
  // });
  const filterProductByLimit = (limit) => {
    // const limit = e.target.value;
    setLimit(limit);
    handleFilterChange(queryParams, location, history, "limit", limit, true);
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">
          {pricelist ? "Price List" : "Products"}
        </h2>
        {!pricelist && (
          <div className="d-flex">
            <div className="clear-filter-btn me-2">
              <button onClick={clearFilters}>Clear Filters</button>
            </div>
            <div>
              <Link to="/addproduct" className="btn btnhoverdark bg-color">
                Create new
              </Link>
            </div>
          </div>
        )}
      </div>

      {loading || loadingCat ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            <>
              <div className="card mb-4 shadow-sm">
                <header
                  className="card-header flexin justify-content-between p-"
                  // style={{ backgroundColor: "#4fa607" }}
                >
                  <div className="flexin align-items-center mt- ">
                    <input
                      placeholder="Search..."
                      className="form-control p-2 custom-input search "
                      style={{ width: "12rem" }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyPress={filterName}
                    />
                  </div>
                  <div className="text-white">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-auto mt-">
                          <select
                            className="form-select custom-select"
                            onChange={handleCategoryChange}
                            value={queryParams?.get("category_id") || "All"}
                          >
                            <option value={""}>All Categories</option>
                            {categories?.map((category) => (
                              <option
                                key={`cat_id${category.category_id}`}
                                value={category.category_id}
                              >
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>

                <div className="card-body">
                  <div className="table-responsive">
                    {pricelist ? (
                      //
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: "4rem" }}>ID</th>
                            <th style={{ width: "12rem" }}>Name</th>
                            <th style={{ width: "19rem" }}>Price / Unit</th>
                            <th style={{ width: "19rem" }}>Special Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products?.map((product, productIndex) => (
                            <tr key={productIndex}>
                              <td className="text-center">
                                <div className="flex ">
                                  <div style={{ width: "4rem" }}>
                                    {product.product_id
                                      .toString()
                                      .padStart(4, "0")}
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="flex ">
                                  <div
                                    className="text_overflow"
                                    style={{
                                      width: "15rem",
                                    }}
                                  >
                                    {product?.name || "-"}
                                  </div>
                                </div>
                              </td>
                              <td className="text-center cursorpointer">
                                <div className="flex cursorpointer">
                                  <div style={{ width: "19rem" }}>
                                    {product?.prices_per_unit.map(
                                      (priceInfo, priceIndex) => (
                                        <div
                                          key={priceIndex}
                                          // className="row mb-2 mt-0"
                                          // style={{ width: "19rem" }}
                                        >
                                          {loadingPriceUpdate.state &&
                                          loadingPriceUpdate.productIndex ===
                                            productIndex &&
                                          loadingPriceUpdate.priceIndex ===
                                            priceIndex ? (
                                            <div
                                              className="spinner-border spin"
                                              role="status"
                                            >
                                              {/* <span className="sr-only">
                                                Loading...
                                              </span> */}
                                            </div>
                                          ) : (
                                            <div className="row mb-2 mt-0 ">
                                              <div className="flex">
                                                <span
                                                  onClick={() =>
                                                    handlePriceClick(
                                                      productIndex,
                                                      priceIndex
                                                    )
                                                  }
                                                >
                                                  &nbsp;
                                                </span>
                                                {editIndices[productIndex] ===
                                                priceIndex ? (
                                                  <>
                                                    <input
                                                      type="number"
                                                      value={priceInfo.price}
                                                      className="form-control"
                                                      style={{
                                                        width: "4.5rem",
                                                        height: "2rem",
                                                        fontSize: "1rem",
                                                      }}
                                                      onChange={(e) =>
                                                        handlePriceChange(
                                                          e,
                                                          productIndex,
                                                          priceIndex,
                                                          e.target.value,
                                                          product
                                                        )
                                                      }
                                                      onKeyPress={(e) => {
                                                        updateProduct(
                                                          e,
                                                          product,
                                                          false,
                                                          productIndex,
                                                          priceIndex,
                                                          false
                                                        );
                                                      }}
                                                      onBlur={handleBlur}
                                                      autoFocus
                                                      onWheel={(event) =>
                                                        event.currentTarget.blur()
                                                      }
                                                    />
                                                    $ per&nbsp;
                                                    {priceInfo.name}
                                                  </>
                                                ) : (
                                                  <div
                                                    onClick={() =>
                                                      handlePriceClick(
                                                        productIndex,
                                                        priceIndex
                                                      )
                                                    }
                                                    className="flex"
                                                    style={{
                                                      fontSize: "1rem",
                                                      // width: "19rem",
                                                    }}
                                                  >
                                                    {priceInfo.price} $
                                                    per&nbsp;
                                                    {priceInfo.name}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="text-center cursorpointer">
                                <div className="flex cursorpointer">
                                  <div style={{ width: "19rem" }}>
                                    {product?.prices_per_unit.map(
                                      (priceInfo, priceIndex) => (
                                        <div
                                          key={`specialprice_${priceIndex}`}
                                          // style={{ width: "19rem" }}
                                        >
                                          {/* {priceInfo.special !== 0 ? ( */}
                                          {loadingSpecialPriceUpdate.state &&
                                          loadingSpecialPriceUpdate.productIndex ===
                                            productIndex &&
                                          loadingSpecialPriceUpdate.priceIndex ===
                                            priceIndex ? (
                                            <div
                                              className="spinner-border spin"
                                              role="status"
                                            >
                                              {/* <span className="sr-only">
                                                Loading...
                                              </span> */}
                                            </div>
                                          ) : (
                                            <div
                                              key={priceIndex}
                                              className="row mb-2 mt-0 "
                                            >
                                              <div className="flex">
                                                <span
                                                  onClick={() =>
                                                    handleSpecialPriceClick(
                                                      productIndex,
                                                      priceIndex
                                                    )
                                                  }
                                                >
                                                  &nbsp;
                                                </span>
                                                {editSpecialIndices[
                                                  productIndex
                                                ] === priceIndex ? (
                                                  <>
                                                    <input
                                                      type="number"
                                                      value={priceInfo.special}
                                                      className="form-control"
                                                      style={{
                                                        width: "4.5rem",
                                                        height: "2rem",
                                                        fontSize: "1rem",
                                                      }}
                                                      onChange={(e) =>
                                                        handleSpecialPriceChange(
                                                          e,
                                                          productIndex,
                                                          priceIndex,
                                                          e.target.value,
                                                          product
                                                        )
                                                      }
                                                      onKeyPress={(e) => {
                                                        updateProduct(
                                                          e,
                                                          product,
                                                          false,
                                                          productIndex,
                                                          priceIndex,
                                                          true
                                                        );
                                                      }}
                                                      onBlur={handleBlur}
                                                      autoFocus
                                                      onWheel={(event) =>
                                                        event.currentTarget.blur()
                                                      }
                                                    />
                                                    $ per&nbsp;
                                                    {priceInfo.name}
                                                  </>
                                                ) : (
                                                  <>
                                                    {priceInfo.special !== 0 ? (
                                                      <div
                                                        onClick={() =>
                                                          handleSpecialPriceClick(
                                                            productIndex,
                                                            priceIndex
                                                          )
                                                        }
                                                        className="flex"
                                                        style={{
                                                          fontSize: "1rem",
                                                          // width: "19rem",
                                                        }}
                                                      >
                                                        {priceInfo.special} $
                                                        per&nbsp;
                                                        {priceInfo.name}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        onClick={() =>
                                                          handleSpecialPriceClick(
                                                            productIndex,
                                                            priceIndex
                                                          )
                                                        }
                                                        className="flex"
                                                        style={{
                                                          fontSize: "1rem",
                                                          // width: "19rem",
                                                        }}
                                                      >
                                                        - &nbsp;per&nbsp;
                                                        {priceInfo.name}
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            {/* <th style={{ width: "4rem" }}>FJ</th> */}
                            <th style={{ width: "3rem" }}>ID</th>
                            <th style={{ width: "12rem" }}>Name</th>
                            <th style={{ width: "10rem" }}>Category</th>
                            <th style={{ width: "9rem" }}>Status</th>
                            <th style={{ width: "5rem" }}>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products?.map((product, index) => (
                            <tr
                              key={`product_${product.product_id}`}
                              className={`cursorpointer  ${
                                product?.status == 0 ? "disabled" : ""
                              }`}
                              onClick={() => handleEdit(product)}
                            >
                              {/* <td
                                className="text-center"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="custom-checkbox-input"
                                />
                              </td> */}
                              <td className="text-center">
                                <div className="flex ">
                                  <div style={{ width: "3rem" }}>
                                    {product.product_id
                                      .toString()
                                      .padStart(4, "0")}
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="flex ">
                                  <div
                                    className="text_overflow"
                                    style={{
                                      width: "12rem",
                                    }}
                                  >
                                    {product?.name || "-"}
                                  </div>
                                </div>
                              </td>

                              <td className="text-center">
                                <div className="flex ">
                                  <div
                                    style={{
                                      width: "10rem",
                                    }}
                                  >
                                    {product.categories?.map((categoryId) => {
                                      const foundCategory = categories?.find(
                                        (category) =>
                                          category.category_id === categoryId
                                      );
                                      return (
                                        <div
                                          className="text-center text_overflow"
                                          key={foundCategory?.category_id}
                                        >
                                          {foundCategory?.name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </td>
                              <td
                                className="text-center cursorhand"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="flex ">
                                  {loadingStatusUpdate.state &&
                                  loadingStatusUpdate.productIndex === index ? (
                                    <div
                                      className="spinner-border spin"
                                      role="status"
                                    ></div>
                                  ) : (
                                    <div style={{ width: "9rem" }}>
                                      <select
                                        className={`form-select custom-select`}
                                        id={`statusSelect_${product.product_id}`}
                                        style={{ width: "9rem" }}
                                        value={product?.status}
                                        onChange={(event) =>
                                          handleStatusChange(event, product)
                                        }
                                        disabled={
                                          loadingStatusUpdate.state &&
                                          loadingStatusUpdate.productIndex ===
                                            index
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value={0}>Disabled</option>
                                        <option value={1}>Enabled</option>
                                      </select>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td
                                className="text-center cursorhand"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="flex ">
                                  <div style={{ width: "5rem" }}>
                                    <div className="row ">
                                      <div className="flex ">
                                        {/* <button
                                          className="action-btn edit-btn"
                                          onClick={() => handleEdit(product)}
                                        >
                                          <i className="far fa-pen"></i>
                                        </button>
                                        | */}
                                        <button
                                          className="action-btn delete-btn"
                                          onClick={(e) =>
                                            handleDeleteClick(e, product)
                                          }
                                        >
                                          <i className="far fa-trash-alt"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      /* </div> */
                    )}
                    <div className="flex-container">
                      <div className="pagination-container">
                        <ReactPaginate
                          pageCount={pageCount}
                          forcePage={initialPageNumber}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          breakClassName={`...`}
                          activeClassName="active-page"
                          previousLabel="< "
                          nextLabel=" >"
                          pageRangeDisplayed={3}
                          hrefAllControls={true}
                        />
                      </div>
                      <div className="">
                        <div className="limit-container">
                          <div
                            className={`box-limit ${
                              limit == 10 ? "selected" : ""
                            }`}
                            onClick={() => filterProductByLimit("10")}
                          >
                            10
                          </div>
                          <div
                            className={`box-limit ${
                              limit == 20 ? "selected" : ""
                            }`}
                            onClick={() => filterProductByLimit("20")}
                          >
                            20
                          </div>
                          <div
                            className={`box-limit ${
                              limit == 30 ? "selected" : ""
                            }`}
                            onClick={() => filterProductByLimit("30")}
                          >
                            30
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showSnackbar && (
                  <Snackbar message={snackbarMessage} style={snackbarColor} />
                )}
              </div>
              {showDeleteConfirmation && (
                <DeleteConfirmationDialog
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  name={"product"}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default MainProducts;
