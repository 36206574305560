import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import MainSetting from "../components/settings/MainSetting";

const SettingsScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainSetting />
      </main>
    </>
  );
};

export default SettingsScreen;
