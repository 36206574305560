import React from "react";
import CreateCategory from "../components/Categories/CreateCategory";
import Sidebar from "../components/sidebar";
import Header from "./../components/Header";

const AddCategory = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <CreateCategory />
      </main>
    </>
  );
};

export default AddCategory;
