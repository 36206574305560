import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Footer2 = () => {
  return (
    <div className="footer-div container-width" id="footer-id">
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-items">
            {/* brand */}
            <a className="mini-logo-footer" href="/">
              <LazyLoadImage
                effect="blur"
                src="/images/icon.png"
                alt="logo"
                className="logo-footer"
              />
            </a>
            <div>
              © 2024 Tripoli Fresh Market. All Rights Reserved. Developed by
              Arco Energies LLC.
            </div>
            {/* <nav>
            {["Home", "About Us", "Privacy Policy", "Ordering", "Login"].map(
              (page) => {
                const formattedPage =
                  page === "About Us"
                    ? "aboutus"
                    : page === "Privacy Policy"
                    ? "privacypolicy"
                    : page.toLowerCase();
                return (
                  <a
                    key={formattedPage}
                    href={
                      formattedPage === "login"
                        ? `/${formattedPage}`
                        : `${formattedPage}`
                    }
                    onClick={(e) => {
                      if (formattedPage !== "login") {
                        e.preventDefault();
                        handlePageSelect(formattedPage);
                      } else {
                        return null;
                      }
                    }}
                  >
                    {page}
                  </a>
                );
              }
            )}
          </nav> */}
          </div>
          {/*  */}
          <div className="footer-content">
            <div className="download-logos">
              {/* <li> */}
              <a href="/">
                <LazyLoadImage
                  effect="blur"
                  src="/images/gglplay.png"
                  alt="download google play app"
                  className="download-footer"
                />
              </a>
              {/* </li> */}
              {/* <li> */}
              <a href="/">
                <LazyLoadImage
                  effect="blur"
                  src="/images/appstore.png"
                  alt="download apple store app"
                  className="download-footer"
                />
              </a>
              {/* </li> */}
              {/* <span>Download app</span>
              <a href="/" className="downloadbutton">
                <svg
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  height="1.8em"
                  width="1.8em"
                >
                  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 00-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
                  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 00-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
                </svg>
              </a>
              <a href="/" className="downloadbutton">
                <svg
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  height="1.8em"
                  width="1.8em"
                  className="playstoremargin"
                >
                  <path d="M48 59.49v393a4.33 4.33 0 007.37 3.07L260 256 55.37 56.42A4.33 4.33 0 0048 59.49zM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32zM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95zM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50z" />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer2;
